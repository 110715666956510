import React, { FC } from 'react'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { ContactsAddressItemProps } from 'utils/types'
import './ContactsAddressItem.scss'

const ContactsAddressItem: FC<ContactsAddressItemProps> = ({
    icon,
    title,
    address,
}): JSX.Element => {
    return (
        <div className="contacts-address-item">
            <div className="contacts-address-item-icon">
                <FontAwesomeIcon icon={icon} size="2x" />
            </div>
            <div className="contacts-address-item-info">
                <h4 className="contacts-address-item-title fw-bold">{title}</h4>
                <address className="contacts-address-item-address">
                    {address}
                </address>
            </div>
        </div>
    )
}

export default ContactsAddressItem
