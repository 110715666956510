import React, { FC } from 'react'
import ContactsMap from 'Components/ContactsMap/ContactsMap'
import ContactsSection from 'Components/ContactsSection/ContactsSection'
import './ContactsPage.scss'

const ContactsPage: FC = (): JSX.Element => {
    return (
        <div className="contacts">
            <div className="contacts-banner"></div>
            <div className="container">
                <ContactsSection />
                <ContactsMap />
            </div>
        </div>
    )
}

export default ContactsPage
