import React, { FC, useContext, useEffect, useState } from 'react'
import { useDispatch } from 'react-redux'
import {
    Dropdown,
    DropdownToggle,
    DropdownMenu,
    DropdownItem,
} from 'reactstrap'

import { useTypedSelector } from 'utils/reduxTypes'
import { ResizeContext } from 'context/ResizeContext'
import { DoneEvents } from './MyFetchedEvents/doneEvents'
import { CreatedEvents } from './MyFetchedEvents/createdEvents'
import { ApprovedEvents } from './MyFetchedEvents/approvedEvents'
import { InProgressEvents } from './MyFetchedEvents/inProgressEvents'
import { PendingToFinishEvents } from './MyFetchedEvents/pendingToFinishEvents'
import { PendingToStartEvents } from './MyFetchedEvents/pendingToStartEvents'
import './myEvents.scss'

const MyEvents: FC = (): JSX.Element => {
    const [activeTab, setActiveTab] = useState<JSX.Element>()
    const { screenSize } = useContext(ResizeContext)
    const activeState = useTypedSelector(state => state.activeTab)
    const [activeClass, setActiveClass] = useState('')
    const [dropdownOpen, setDropdownOpen] = useState(false)
    const dispatch = useDispatch()

    const tabsArray = [
        'CREATED EVENTS',
        'APPROVED EVENTS',
        'EVENTS PENDING TO START',
        'IN PROGRESS EVENTS',
        'EVENTS PENDING TO FINISH',
        'FINISHED EVENTS',
    ]
    useEffect(() => {
        switch (activeState.activeTabState) {
            case 'FINISHED EVENTS':
                setActiveTab(<DoneEvents />)
                break
            case 'APPROVED EVENTS':
                setActiveTab(<ApprovedEvents />)
                break
            case 'EVENTS PENDING TO START':
                setActiveTab(<PendingToStartEvents />)
                break
            case 'IN PROGRESS EVENTS':
                setActiveTab(<InProgressEvents />)
                break
            case 'EVENTS PENDING TO FINISH':
                setActiveTab(<PendingToFinishEvents />)
                break
            default:
                setActiveTab(<CreatedEvents />)
                setActiveClass('CREATED EVENTS')
                break
        }
        setActiveClass(
            activeState.activeTabState
                ? activeState.activeTabState
                : 'CREATED EVENTS'
        )
    }, [])

    const toggle = () => setDropdownOpen(prevState => !prevState)

    const checkActiveTab = (e: React.MouseEvent<HTMLElement>): void => {
        const targetValue = e.target as HTMLElement

        switch (targetValue.innerText) {
            case 'FINISHED EVENTS':
                setActiveTab(<DoneEvents />)
                break
            case 'APPROVED EVENTS':
                setActiveTab(<ApprovedEvents />)
                break
            case 'EVENTS PENDING TO START':
                setActiveTab(<PendingToStartEvents />)
                break
            case 'IN PROGRESS EVENTS':
                setActiveTab(<InProgressEvents />)
                break
            case 'EVENTS PENDING TO FINISH':
                setActiveTab(<PendingToFinishEvents />)
                break
            default:
                setActiveTab(<CreatedEvents />)
                break
        }
        dispatch({
            type: `user/${targetValue.innerText}`,
            payload: targetValue.innerText,
        })
        setActiveClass(targetValue.innerText)
    }

    return (
        <>
            {screenSize <= 1198 ? (
                <Dropdown
                    isOpen={dropdownOpen}
                    toggle={toggle}
                    className="flex-button-column"
                >
                    <DropdownToggle caret> {activeClass}</DropdownToggle>
                    <DropdownMenu className="button-dropdown-menu">
                        {tabsArray.map((item, index) => (
                            <DropdownItem
                                key={index}
                                active={activeClass === item}
                                onClick={checkActiveTab}
                                className="button-container"
                            >
                                {item}
                            </DropdownItem>
                        ))}
                    </DropdownMenu>
                </Dropdown>
            ) : (
                <div className="flex-button-column">
                    {tabsArray.map((item, index) => (
                        <div className="button-container" key={index}>
                            <button
                                className={
                                    'event-button ' +
                                    (activeClass === item ? 'active' : '')
                                }
                                onClick={checkActiveTab}
                            >
                                {item}
                            </button>
                        </div>
                    ))}
                </div>
            )}
            {activeTab}
        </>
    )
}

export default MyEvents
