import React, { useContext } from 'react'
import { Navigate, Outlet } from 'react-router-dom'
import { Roles } from './roles'
import { AuthContext } from 'context/AuthContext'
import NotFoundPage from 'Pages/NotFoundPage/NotFoundPage'

const PrivateRoute = ({
    children,
    roles,
}: {
    children: JSX.Element
    roles: Array<Roles>
}): JSX.Element => {
    const { currentUser, token } = useContext(AuthContext)

    const isAllowed =
        currentUser.id && roles.includes(currentUser.role) ? true : false

    if (!token) {
        return <Navigate to="/home" state={{ from: location }} />
    }

    if (token && !isAllowed) {
        return <NotFoundPage />
    }
    return children ? children : <Outlet />
}

export default PrivateRoute
