import React, { FC } from 'react'
import { LazyLoadImage } from 'react-lazy-load-image-component'
import type { AboutMemberProps } from 'utils/types'

import './AboutMember.scss'

const AboutMember: FC<AboutMemberProps> = ({
    src,
    alt,
    name,
    job,
    desc,
}): JSX.Element => {
    return (
        <div className="about-members-item">
            <div className="about-members-item-photo">
                <LazyLoadImage src={src} alt={alt} />
            </div>
            <div className="about-members-item-info text-center mt-3">
                <h4 className="about-members-item-name fw-bold">{name}</h4>
                <span className="about-members-item-job fw-bold">{job}</span>
                <p className="about-members-item-desc">{desc}</p>
            </div>
        </div>
    )
}

export default AboutMember
