import React, { FC } from 'react'
import ContactsForm from 'Components/ContactsForm/ContactsForm'
import ContactsAddressList from 'Components/ContactsAddressList/ContactsAddressList'
import './ContactsSection.scss'

const ContactsSection: FC = (): JSX.Element => {
    return (
        <div className="contacts-section">
            <div className="contacts-section-header text-center w-50 mx-auto">
                <h2 className="contacts-section-title text-uppercase">
                    contact us
                </h2>
                <h3 className="contacts-section-subtitle text-capitalize fw-bold">
                    if you have any query, please feel free contact us
                </h3>
            </div>
            <div className="contacts-section-content d-flex justify-content-between">
                <div className="contacts-section-address col-lg-6">
                    <ContactsAddressList />
                </div>
                <div className="contacts-section-form col-lg-6">
                    <ContactsForm />
                </div>
            </div>
        </div>
    )
}

export default ContactsSection
