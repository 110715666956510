import React, { FC, useContext } from 'react'
import { Link, NavLink, useLocation } from 'react-router-dom'
import { Nav, NavItem, Button } from 'reactstrap'
import { AuthContext } from 'context/AuthContext'
import AuthModal from 'Components/Modals/AuthModal'
import './Header.scss'
import BurgerMenu from 'Components/burgerMenu/burgerMenu'
import { ResizeContext } from 'context/ResizeContext'

const Header: FC = (): JSX.Element => {
    const { screenSize } = useContext(ResizeContext)
    const { toggle, isAuth, currentUser } = useContext(AuthContext)
    const location = useLocation()
    const links = [
        {
            className: 'link-light nav-link',
            path: 'home',
            name: 'Home',
        },
        {
            className: 'link-light nav-link',
            path: 'about',
            name: 'About',
        },
        {
            className: 'link-light nav-link',
            path: 'events',
            name: 'Events',
        },
        {
            className: 'link-light nav-link',
            path: 'our-partners',
            name: 'Our Partners',
        },
        {
            className: 'link-light nav-link',
            path: 'articles',
            name: 'Articles',
        },
        {
            className: 'link-light nav-link',
            path: 'contacts',
            name: 'Contacts',
        },
    ]

    const headerStyleBundler = (): string => {
        switch (location.pathname) {
            case '/':
                return 'main-header'
            default:
                return 'header'
        }
    }

    return screenSize >= 992 ? (
        <div className={headerStyleBundler()}>
            <Nav className="navbar navbar-expand-lg">
                <div className="container">
                    <Link className="navbar-brand link-secondary" to="/">
                        <img
                            src={process.env.PUBLIC_URL + '/assets/logo.png'}
                            alt="be green logo"
                        />
                    </Link>
                    <Nav className="header-navbar navbar-nav align-items-center">
                        {links.map((link, index) => (
                            <NavItem key={index}>
                                <NavLink
                                    to={link.path}
                                    className={link.className}
                                >
                                    {link.name}
                                </NavLink>
                            </NavItem>
                        ))}
                        {isAuth ? (
                            <div className="header-profile">
                                <Link
                                    className="header-profile-link"
                                    to="profile"
                                >
                                    <img
                                        src={
                                            currentUser.image
                                                ? currentUser.image
                                                : process.env.PUBLIC_URL +
                                                  '/assets/image/user-profile-page/unnamed.jpg'
                                        }
                                        alt="user profile icon"
                                    />
                                </Link>
                            </div>
                        ) : (
                            <>
                                {' '}
                                <Button
                                    onClick={toggle}
                                    className="custom-btn auth-btn btn"
                                >
                                    Sign in
                                </Button>
                                <AuthModal />
                            </>
                        )}
                    </Nav>
                </div>
            </Nav>
        </div>
    ) : (
        <BurgerMenu />
    )
}

export default Header
