import React, { FC } from 'react'
import { partnersData } from 'utils/helpers'
import './partners.scss'

export const Partners: FC = (): JSX.Element => {
    return (
        <div className="partners-container">
            {partnersData?.map(partner => (
                <a
                    href={partner.partnerLink}
                    className="partner-card"
                    key={partner.id}
                    target="_blank"
                    rel="noopener noreferrer"
                >
                    <div>
                        {' '}
                        <img src={partner.image} />
                    </div>
                </a>
            ))}
        </div>
    )
}
