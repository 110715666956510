import React, { FC } from 'react'
import { Partners } from 'Components/Partners/partners'
import './ourPartnersPage.scss'

const OurPartners: FC = (): JSX.Element => {
    return (
        <div className="partners">
            <div className="container">
                <h2 className="text-center mb-5">Our Partners</h2>
            </div>
            <Partners />
        </div>
    )
}

export default OurPartners
