import React, { FC } from 'react'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import type { StepsItemProps } from 'utils/types'

const StepsItem: FC<StepsItemProps> = ({ icon, title, text }): JSX.Element => {
    return (
        <div className="steps-item d-flex flex-column justify-content-center align-items-center text-center col">
            <div className="steps-item-icon mb-4">
                <FontAwesomeIcon icon={icon} size="2x" />
            </div>
            <h4 className="steps-item-title mb-4">{title}</h4>
            <div className="steps-item-text">
                <p>{text}</p>
            </div>
        </div>
    )
}

export default StepsItem
