import React, { FC, useContext, useEffect, useState } from 'react'
import { useDispatch } from 'react-redux'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faCamera, faRightToBracket } from '@fortawesome/free-solid-svg-icons'
import LogoutModal from './LogoutModal'
import { UserBadge } from './userBadge'
import MyEvents from './MyEvents/myEvents'
import { AuthContext } from 'context/AuthContext'
import {
    ActionTypes,
    ProfileActionTypes,
    useTypedSelector,
} from 'utils/reduxTypes'
import { ChangeImage } from './UserCardEdit/changeImage'
import UserProfileEdit from './UserCardEdit/userProfileEdit'
import './userProfileCard.scss'

const UserProfileCard: FC = (): JSX.Element => {
    const [show, setShow] = useState(false)
    const [activeClass, setActiveClass] = useState('')
    const [logoutModal, setLogoutModal] = useState(false)
    const [activeSection, setActiveSection] = useState<JSX.Element>()

    const { currentUser } = useContext(AuthContext)
    const profileSection = useTypedSelector(state => state.profileActiveSection)
    const dispatch = useDispatch()

    const onHide = () => setShow(!show)

    useEffect(() => {
        if (profileSection.activeSection === 'Edit profile') {
            setActiveSection(<UserProfileEdit />)
            setActiveClass(profileSection.activeSection)
        } else {
            setActiveSection(<MyEvents />)
            setActiveClass('My events')
        }

        return () => {
            dispatch({
                type: ActionTypes.CLEAR_TAB,
                payload: '',
            })
            dispatch({
                type: ProfileActionTypes.CLEAR_SECTION,
                payload: '',
            })
        }
    }, [])

    const logoutModalToggle = () => {
        setLogoutModal(!logoutModal)
        setActiveClass(logoutModal ? profileSection.activeSection : '')
    }

    const listItems = ['Edit profile', 'My events']

    const checkActiveSection = (e: React.MouseEvent<HTMLElement>): void => {
        const value = e.target as HTMLElement
        switch (value.innerText) {
            case 'Edit profile':
                setActiveSection(<UserProfileEdit />)
                break
            case 'My events':
                setActiveSection(<MyEvents />)
                break
            default:
                setActiveSection(<MyEvents />)
                break
        }
        setActiveClass(value.innerText)
        dispatch({
            type: value.innerText,
            payload: value.innerText,
        })
    }

    return (
        <div className="profile-page-block">
            <div className="main-profile-info">
                <div className="user-profile-card">
                    <div className="user-profile-card-img">
                        <UserBadge />
                        <img
                            src={
                                currentUser.image
                                    ? currentUser.image
                                    : process.env.PUBLIC_URL +
                                      '/assets/image/user-profile-page/unnamed.jpg'
                            }
                            alt="user-profile"
                        />
                        <div className="icon-container">
                            <FontAwesomeIcon
                                icon={faCamera}
                                className="fa-camera"
                                onClick={onHide}
                            />
                        </div>
                        <ChangeImage show={show} onHide={onHide} />
                        <h4>{currentUser.name}</h4>
                    </div>
                    <div className="user-profile-card-ul-block">
                        <div className="list-group">
                            {listItems.map(item => (
                                <button
                                    type="button"
                                    className={
                                        'list-group-item ' +
                                        (activeClass === item
                                            ? 'active-tab'
                                            : '')
                                    }
                                    key={item}
                                    onClick={checkActiveSection}
                                >
                                    {item}
                                </button>
                            ))}
                            <button
                                type="button"
                                className="list-group-item"
                                onClick={logoutModalToggle}
                            >
                                <FontAwesomeIcon icon={faRightToBracket} />
                                Logout
                            </button>
                        </div>
                        <LogoutModal
                            show={logoutModal}
                            onHide={logoutModalToggle}
                            toggle={logoutModalToggle}
                        />
                    </div>
                </div>
            </div>
            <div className="optional-profile-info">{activeSection}</div>
        </div>
    )
}

export default UserProfileCard
