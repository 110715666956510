import React, { FC, useState, ChangeEvent } from 'react'
import { toast } from 'react-toastify'
import { api } from 'utils/axiosService'
import { Button, FormGroup, Input } from 'reactstrap'
import { Formik, Form, Field, ErrorMessage } from 'formik'
import { ContactUsValidationSchema } from 'utils/validation'
import './ContactsForm.scss'

const ContactsForm: FC = (): JSX.Element => {
    const [isDisable, setIsDisable] = useState(false)
    const [data, setData] = useState({
        name: '',
        email: '',
        subject: '',
        message: '',
    })

    const handleChange = (
        e: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
    ) => {
        setData({ ...data, [e.target.name]: e.target.value })
    }

    const sendMessage = async () => {
        setIsDisable(true)
        try {
            await api.post(`user/contact-us`, {
                ...data,
            })
            toast.success(
                'We will look over your message and get back to you tomorrow.'
            )
            setIsDisable(false)
            setData({
                name: '',
                email: '',
                subject: '',
                message: '',
            })
        } catch (error: any) {
            setIsDisable(false)
            const errors = error.response.data.message.errors
            errors.forEach((err: any) => toast.error(err.msg))
        }
    }
    return (
        <Formik
            enableReinitialize
            initialValues={data}
            validationSchema={ContactUsValidationSchema}
            onSubmit={sendMessage}
        >
            {formik => (
                <Form className="contacts-form">
                    <FormGroup className="d-flex contacts-form-group">
                        <FormGroup className="w-50">
                            <Input
                                tag={Field}
                                className={`contacts-form-input form-control ${
                                    formik.errors.name && formik.touched.name
                                        ? 'is-invalid'
                                        : ''
                                }`}
                                component="input"
                                type="text"
                                name="name"
                                placeholder="Name"
                                value={data.name}
                                onChange={handleChange}
                            />
                            <ErrorMessage
                                name="name"
                                component="div"
                                className="invalid-feedback"
                            />
                        </FormGroup>
                        <FormGroup className="w-50">
                            <Input
                                tag={Field}
                                className={`contacts-form-input form-control ${
                                    formik.errors.email && formik.touched.email
                                        ? 'is-invalid'
                                        : ''
                                }`}
                                component="input"
                                type="text"
                                name="email"
                                placeholder="E-mail"
                                value={data.email}
                                onChange={handleChange}
                            />
                            <ErrorMessage
                                name="email"
                                component="div"
                                className="invalid-feedback"
                            />
                        </FormGroup>
                    </FormGroup>
                    <FormGroup>
                        <Input
                            tag={Field}
                            className={`contacts-form-input form-control ${
                                formik.errors.subject && formik.touched.subject
                                    ? 'is-invalid'
                                    : ''
                            }`}
                            component="input"
                            type="text"
                            name="subject"
                            placeholder="Subject"
                            value={data.subject}
                            onChange={handleChange}
                        />
                        <ErrorMessage
                            name="subject"
                            component="div"
                            className="invalid-feedback"
                        />
                    </FormGroup>
                    <FormGroup>
                        <Input
                            tag={Field}
                            className={`contacts-form-input form-control ${
                                formik.errors.message && formik.touched.message
                                    ? 'is-invalid'
                                    : ''
                            }`}
                            component="input"
                            placeholder="Message"
                            name="message"
                            value={data.message}
                            onChange={handleChange}
                            type="textarea"
                        />
                        <ErrorMessage
                            name="message"
                            component="div"
                            className="invalid-feedback"
                        />
                    </FormGroup>
                    <Button
                        disabled={isDisable}
                        size="lg"
                        type="submit"
                        onSubmit={sendMessage}
                        className="contacts-form-btn custom-btn text-uppercase btn"
                    >
                        Send Message
                    </Button>
                </Form>
            )}
        </Formik>
    )
}

export default ContactsForm
