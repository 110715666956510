import React, { FC, useContext, ChangeEvent, useState } from 'react'
import { Link, useLocation, useNavigate } from 'react-router-dom'
import jwtDecode from 'jwt-decode'
import { toast } from 'react-toastify'
import { Formik, Form, Field, ErrorMessage } from 'formik'
import { Button, FormGroup, Label, Input } from 'reactstrap'
import { LazyLoadImage } from 'react-lazy-load-image-component'
import { ResizeContext } from 'context/ResizeContext'
import { api } from 'utils/axiosService'
import { LoginFieldType } from 'utils/types'
import { AuthContext } from 'context/AuthContext'
import { SignInValidationSchema } from 'utils/validation'

const SignInModal: FC = (): JSX.Element => {
    const [isDisable, setIsDisable] = useState(false)
    const { screenSize } = useContext(ResizeContext)
    const [user, setUser] = useState<LoginFieldType>({
        email: '',
        password: '',
    })

    const {
        modal,
        setModal,
        setIsAuth,
        isLoginActive,
        setCurrentUser,
        setIsLoginActive,
    } = useContext(AuthContext)

    const handleChange = (e: ChangeEvent<HTMLInputElement>) => {
        setUser({ ...user, [e.target.name]: e.target.value })
    }

    const navigate = useNavigate()

    const pathname = useLocation().pathname

    const handleLogin = async () => {
        setIsDisable(true)
        try {
            const res = await api.post(`user/login`, {
                ...user,
            })
            if (res.data.token) {
                localStorage.setItem('user', JSON.stringify(res.data.token))
                const userData: {
                    id: string
                    age: string
                    role: number
                    name: string
                    points: string
                    email: string
                    image: string
                    password: string
                } = jwtDecode(res.data.token)

                if (
                    userData.role !== 999 &&
                    !localStorage.getItem('eventsCount') &&
                    !localStorage.getItem('joinCount')
                ) {
                    api.get(`user/get-users-events-count`)
                        .then(response =>
                            localStorage.setItem(
                                'eventsCount',
                                JSON.stringify(response.data)
                            )
                        )
                        .catch(err => console.log(err))

                    api.get(`user/get-users-joins-count`)
                        .then(response =>
                            localStorage.setItem(
                                'joinsCount',
                                JSON.stringify(response.data)
                            )
                        )
                        .catch(err => console.log(err))
                }

                setCurrentUser(prevState => ({
                    ...prevState,
                    ...userData,
                }))
                setIsAuth(true)

                navigate(`${userData.role === 999 ? '/admin' : pathname}`, {
                    state: { from: location.pathname },
                })
            }

            setIsDisable(false)
            toast.success('You have successfully logged in')
            setModal(!modal)
        } catch (err: any) {
            setIsDisable(false)
            toast.error(err.response.data)
        }
    }

    return (
        <>
            <div className="auth-section col-lg-6">
                <div className="auth-section-inner d-flex flex-column">
                    <div className="auth-text text-center">
                        <p>Don&apos;t have an account yet? </p>
                        <button
                            onClick={(e): void => {
                                e.preventDefault()
                                setIsLoginActive(!isLoginActive)
                            }}
                            className="auth-page-change-link"
                        >
                            Sign up
                        </button>
                    </div>
                    <div className="auth-section-content">
                        <div className="auth-section-header text-center">
                            <h2 className="auth-section-title text-uppercase">
                                Welcome to be green
                            </h2>
                        </div>
                    </div>
                    <div className="auth-section-form">
                        <Formik
                            initialValues={user}
                            onSubmit={handleLogin}
                            validationSchema={SignInValidationSchema}
                            enableReinitialize
                        >
                            {formik => (
                                <Form>
                                    <FormGroup>
                                        <Label>E-mail</Label>
                                        <Input
                                            className={`form-control ${
                                                formik.errors.email &&
                                                formik.touched.email
                                                    ? 'is-invalid'
                                                    : ''
                                            }`}
                                            component="input"
                                            tag={Field}
                                            type="email"
                                            name="email"
                                            onChange={handleChange}
                                        />

                                        <ErrorMessage
                                            name="email"
                                            component="div"
                                            className="invalid-feedback"
                                        />
                                    </FormGroup>
                                    <FormGroup>
                                        <Label>Password</Label>
                                        <Input
                                            className={`form-control ${
                                                formik.errors.password &&
                                                formik.touched.password
                                                    ? 'is-invalid'
                                                    : ''
                                            }`}
                                            component="input"
                                            tag={Field}
                                            type="password"
                                            name="password"
                                            onChange={handleChange}
                                        />
                                        <ErrorMessage
                                            name="password"
                                            component="div"
                                            className="invalid-feedback"
                                        />
                                    </FormGroup>
                                    <FormGroup>
                                        <Link
                                            className="text-decoration-none"
                                            to="/forgot-password"
                                            onClick={() => setModal(!modal)}
                                        >
                                            Forgot password?
                                        </Link>
                                    </FormGroup>
                                    <Button
                                        disabled={isDisable}
                                        type="submit"
                                        className="custom-btn auth-form-btn btn btn-lg align-self-stretch"
                                    >
                                        Sign in
                                    </Button>
                                </Form>
                            )}
                        </Formik>
                    </div>
                    <div className="auth-section-logo align-self-center">
                        <LazyLoadImage
                            src={process.env.PUBLIC_URL + '/assets/logo.png'}
                            alt="be green logo"
                        />
                    </div>
                </div>
            </div>
            {screenSize >= 998 && (
                <div className="auth-section-bg col-lg-6"></div>
            )}
        </>
    )
}

export default SignInModal
