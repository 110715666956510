import React, { FC, useContext, useEffect, useState } from 'react'
import { api } from 'utils/axiosService'
import { LoadingContext } from 'context/LoadingContext'
import { EventList } from 'Components/EventList/eventList'

export const FetchedDataFinished: FC = (): JSX.Element => {
    const [finishedEventData, setfinishedEventData] = useState()
    const { setIsLoading } = useContext(LoadingContext)

    useEffect(() => {
        setIsLoading(true)
        api.get(`public/public-done-events`)
            .then(result => {
                setfinishedEventData(result.data)
                setIsLoading(false)
            })
            .catch(e => {
                const error = e.response.data.message.errors[0]
                console.log(error.msg)
                setIsLoading(false)
            })
    }, [])

    return <EventList data={finishedEventData} subject="Finished Events" />
}
