import React, { FC } from 'react'
import type { FeedbackData } from 'utils/types'
import FeedbacksItem from './FeedbacksItem'

export const FeedbacksItemAdmin: FC<FeedbackData> = (props): JSX.Element => {
    return (
        <div className="admin-feedback">
            <h2>Feedbacks from our clients</h2>
            <div className="admin-page-feedback-container">
                {props.data?.map((item, ind) => (
                    <FeedbacksItem key={ind} commentary={item} />
                ))}
            </div>
        </div>
    )
}
