import React from 'react'
import { Card } from 'react-bootstrap'
import { ListGroup, ListGroupItem } from 'reactstrap'
import { CreatedByType } from 'utils/types'

export const CreatedBy: React.FC<CreatedByType> = ({
    name,
    email,
    authorStatus,
    authorImage,
}): JSX.Element => {
    return (
        <div className="event-modal-author">
            <h4 className="mb-4">Created by</h4>
            <div className="d-flex justify-content-center">
                <Card className="col-lg-2 author-image-container">
                    <Card.Img
                        variant="top"
                        src={
                            authorImage
                                ? authorImage
                                : process.env.PUBLIC_URL +
                                  '/assets/image/user-profile-page/unnamed.jpg'
                        }
                        style={{
                            objectFit: 'cover',
                        }}
                    />
                </Card>
                <ListGroup>
                    <ListGroupItem>
                        <strong>Name:</strong> {name}
                    </ListGroupItem>
                    <ListGroupItem>
                        <strong>E-mail:</strong> {email}
                    </ListGroupItem>
                    <ListGroupItem>
                        <strong>Status:</strong> {authorStatus}
                    </ListGroupItem>
                </ListGroup>
            </div>
        </div>
    )
}
