import React, { ChangeEvent, FC, useContext, useEffect, useState } from 'react'
import { Formik, Form, Field, ErrorMessage } from 'formik'
import { Button, FormGroup, Input } from 'reactstrap'
import { toast } from 'react-toastify'
import { Link } from 'react-router-dom'
import { api } from 'utils/axiosService'
import { AuthContext } from 'context/AuthContext'
import { RestorePasswordValidationSchema } from 'utils/validation'
import './forgotPassword.scss'

export const StepThree: FC = (): JSX.Element => {
    const [passwords, setPasswords] = useState({
        newPassword: '',
        confirmPassword: '',
    })
    const [isDisable, setIsDisable] = useState(true)
    const { setModal, modal } = useContext(AuthContext)

    useEffect(() => {
        if (
            passwords.newPassword &&
            passwords.newPassword === passwords.confirmPassword
        ) {
            setIsDisable(false)
        } else {
            setIsDisable(true)
        }
    }, [passwords])

    const handleChange = (
        e: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
    ) => {
        setPasswords({ ...passwords, [e.target.name]: e.target.value })
    }

    const handleSendPassword = () => {
        api.post('user/edit-password', {
            email: localStorage.getItem('email'),
            password: passwords.newPassword,
        })
            .then(response => {
                toast.success(response.data)
                setIsDisable(true)
                setModal(!modal)
            })
            .catch(e => {
                setIsDisable(false)
                toast.error(e.response.data)
            })
    }

    return (
        <Formik
            initialValues={passwords}
            onSubmit={handleSendPassword}
            validationSchema={RestorePasswordValidationSchema}
            enableReinitialize
        >
            {formik => (
                <Form>
                    <FormGroup>
                        <Input
                            component="input"
                            tag={Field}
                            type="password"
                            className={`form-control ${
                                formik.errors.newPassword &&
                                formik.touched.newPassword
                                    ? 'is-invalid'
                                    : ''
                            }`}
                            onChange={handleChange}
                            value={passwords.newPassword}
                            name="newPassword"
                            placeholder="Enter new password"
                        />
                        <ErrorMessage
                            name="newPassword"
                            component="div"
                            className="invalid-feedback"
                        />
                    </FormGroup>
                    <FormGroup>
                        <Input
                            component="input"
                            tag={Field}
                            type="password"
                            className={`form-control ${
                                formik.errors.confirmPassword &&
                                formik.touched.confirmPassword
                                    ? 'is-invalid'
                                    : ''
                            }`}
                            onChange={handleChange}
                            value={passwords.confirmPassword}
                            name="confirmPassword"
                            placeholder="Confirm password"
                        />
                        <ErrorMessage
                            name="confirmPassword"
                            component="div"
                            className="invalid-feedback"
                        />
                    </FormGroup>
                    <Link className="header-profile-link" to="/home">
                        <Button
                            type="submit"
                            className="custom-btn auth-form-btn btn btn-lg align-self-stretch btn btn-secondary"
                            onClick={handleSendPassword}
                            disabled={isDisable}
                        >
                            Send
                        </Button>
                    </Link>
                </Form>
            )}
        </Formik>
    )
}
