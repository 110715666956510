import React, { FC, useContext } from 'react'
import { Modal, ModalBody } from 'reactstrap'
import SignInModal from './SignInModal'
import SignUpModal from './SignUpModal'
import { AuthContext } from 'context/AuthContext'
import './Auth.scss'

const AuthModal: FC = (): JSX.Element => {
    const { modal, toggle, isLoginActive } = useContext(AuthContext)
    return (
        <Modal
            className="auth"
            size="xl"
            isOpen={modal}
            toggle={toggle}
            centered
        >
            <button
                className={`btn-close ${
                    isLoginActive ? 'btn-close-white' : ''
                }`}
                onClick={toggle}
                aria-label="Close"
            ></button>
            <ModalBody className="auth-body d-flex justify-content-between">
                {isLoginActive ? <SignInModal /> : <SignUpModal />}
            </ModalBody>
        </Modal>
    )
}

export default AuthModal
