import React, { FC, useContext, useEffect } from 'react'
import { useDispatch } from 'react-redux'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faRightToBracket } from '@fortawesome/free-solid-svg-icons'
import { ActionTypes } from 'utils/reduxTypes'
import { AuthContext } from 'context/AuthContext'
import { AdminTab } from 'Components/AdminTabs/AdminTabs'
import './AdminPage.scss'
import { Link } from 'react-router-dom'

const AdminPage: FC = (): JSX.Element => {
    const { logout } = useContext(AuthContext)
    const dispatch = useDispatch()

    useEffect(() => {
        return () => {
            dispatch({
                type: ActionTypes.CLEAR_TAB,
                payload: '',
            })
        }
    }, [])

    return (
        <div className="admin-page-container">
            <div className="admin-logout-button-div">
                <Link className="header-profile-link" to="/admin/articles">
                    <button className="admin-logout-button">Articles</button>
                </Link>
                <Link className="header-profile-link" to="/admin/feedback">
                    <button className="admin-logout-button">Feedbacks</button>
                </Link>
                <button className="admin-logout-button" onClick={logout}>
                    <FontAwesomeIcon icon={faRightToBracket} />
                    Logout
                </button>
            </div>
            <AdminTab />
        </div>
    )
}

export default AdminPage
