import React, { FC, useContext, useState } from 'react'
import { ErrorMessage, Form, Formik, Field } from 'formik'
import { FormGroup, Input, Label, Button } from 'reactstrap'
import { toast } from 'react-toastify'
import { JoinProp } from 'utils/types'
import { api } from 'utils/axiosService'
import { countIncrement } from 'utils/helpers'
import { EventContext } from 'context/EventContext'
import { JoinFormValidationSchema } from 'utils/validation'

export const JoinFrom: FC<JoinProp> = ({ id, onHide }): JSX.Element => {
    const [joinMessage, setJoinMessage] = useState({
        description: ' ',
    })
    const [isDisable, setIsDisable] = useState(false)

    const { stateIsChange, setStateIsChange } = useContext(EventContext)

    const handleMessage = (e: React.ChangeEvent<HTMLInputElement>): void => {
        setJoinMessage({ description: e.target.value })
    }

    const sendMessage = async () => {
        const dataToSend = {
            description: joinMessage.description.trim(),
            eventId: id,
        }

        try {
            setIsDisable(true)
            await api.post(`user/subscribe-event`, dataToSend)
            setJoinMessage({
                description: ' ',
            })
            toast.success('Subscribed successfully')
            countIncrement('join')
            onHide()
            setStateIsChange(!stateIsChange)
            setIsDisable(false)
        } catch (err: any) {
            setIsDisable(false)
            toast.error(err.response.data)
        }
    }

    return (
        <Formik
            enableReinitialize
            initialValues={joinMessage}
            onSubmit={sendMessage}
            validationSchema={JoinFormValidationSchema}
        >
            {formik => (
                <Form className="form-group">
                    <FormGroup className="mb-3">
                        <Label>Description</Label>
                        <Input
                            tag={Field}
                            className={`form-control ${
                                formik.errors.description &&
                                formik.touched.description
                                    ? 'is-invalid'
                                    : ''
                            }`}
                            name="description"
                            onChange={handleMessage}
                            value={joinMessage.description}
                            as="textarea"
                            rows={3}
                        />
                        <ErrorMessage
                            name="description"
                            component="div"
                            className="invalid-feedback"
                        />
                    </FormGroup>
                    <div className="d-flex align-items-center justify-content-between">
                        <span className="safe-info">
                            Your information is safe with us.
                        </span>
                        <div className="btn-submit">
                            <Button
                                type="submit"
                                onSubmit={sendMessage}
                                disabled={isDisable}
                                className="btn-primary custom-btn"
                            >
                                Submit
                            </Button>
                        </div>
                    </div>
                </Form>
            )}
        </Formik>
    )
}
