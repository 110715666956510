import React, { FC } from 'react'
import { Link } from 'react-router-dom'
import { Button } from 'reactstrap'
import './Intro.scss'

const Intro: FC = (): JSX.Element => {
    return (
        <div className="main-intro d-flex flex-column align-items-center">
            <h1 className="main-intro-title">Be green</h1>
            <p className="main-intro-desc">
                Join us in saving and clearing our environement that is
                essential for us all. Since together, there is a greener world.
            </p>
            <Button color="primary" className="main-intro-btn custom-btn">
                <Link to="home" className="main-intro-link link-light">
                    Get Started
                </Link>
            </Button>
        </div>
    )
}

export default Intro
