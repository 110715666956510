import React, { createContext, useState } from 'react'
import type {
    LoadingContextProps,
    LoadingContextProviderProps,
} from 'utils/types'

export const LoadingContext = createContext<LoadingContextProps>(
    {} as LoadingContextProps
)

export const LoadingContextProvider = ({
    children,
}: LoadingContextProviderProps): JSX.Element => {
    const [isLoading, setIsLoading] = useState(false)

    return (
        <LoadingContext.Provider
            value={{
                isLoading,
                setIsLoading,
            }}
        >
            <> {children}</>
        </LoadingContext.Provider>
    )
}
