import React, { FC } from 'react'
import { Nav, NavItem } from 'reactstrap'
import { NavLink } from 'react-router-dom'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import './Footer.scss'
import {
    faEnvelope,
    faLocationDot,
    faPhone,
} from '@fortawesome/free-solid-svg-icons'

const Footer: FC = (): JSX.Element => {
    const links = [
        {
            className: 'link-secondary text-decoration-none',
            path: 'home',
            name: 'Home',
        },
        {
            className: 'link-secondary text-decoration-none',
            path: 'about',
            name: 'About',
        },
        {
            className: 'link-secondary text-decoration-none',
            path: 'events',
            name: 'Events',
        },
        {
            className: 'link-secondary text-decoration-none',
            path: 'our-partners',
            name: 'Our Partners',
        },
        {
            className: 'link-secondary text-decoration-none',
            path: 'articles',
            name: 'Articles',
        },
        {
            className: 'link-secondary text-decoration-none',
            path: 'contacts',
            name: 'Contacts',
        },
    ]
    return (
        <footer className="footer mt-auto">
            <div className="container text-center text-md-start mt-5">
                <div className="footer-container">
                    <div className="footer-item">
                        <h5 className="mb-4 fw-bold">Menu</h5>
                        <Nav
                            className="footer-link nav"
                            style={{ height: '220px' }}
                        >
                            {links.map((link, index) => (
                                <NavItem key={index}>
                                    <NavLink
                                        to={link.path}
                                        className={link.className}
                                    >
                                        {link.name}
                                    </NavLink>
                                </NavItem>
                            ))}
                        </Nav>
                    </div>

                    <div className="footer-item">
                        <h5 className="mb-4 fw-bold">Have a questions?</h5>
                        <Nav
                            className="nav d-flex flex-column flex-wrap"
                            style={{ height: '150px' }}
                        >
                            <NavItem>
                                <FontAwesomeIcon icon={faLocationDot} /> 11/12
                                Argishti St, Yerevan
                            </NavItem>
                            <NavItem>
                                <FontAwesomeIcon icon={faPhone} /> +374 55 64 54
                                37
                            </NavItem>
                            <NavItem>
                                <FontAwesomeIcon icon={faEnvelope} />{' '}
                                info@milies.net
                            </NavItem>
                        </Nav>
                    </div>

                    <div className="footer-item">
                        <div className="footer-logo">
                            <img
                                src={
                                    process.env.PUBLIC_URL + '/assets/logo.png'
                                }
                                alt="be green logo"
                            />
                        </div>
                    </div>
                </div>
                <div className="text-center p-4">
                    Copyright: &copy; {new Date().getFullYear()} All Rights
                    Reserved.
                </div>
            </div>
        </footer>
    )
}

export default Footer
