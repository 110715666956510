import React, { FC } from 'react'
import { ImageSliderType } from 'utils/types'
import './imageSlider.scss'

export const ImageSlider: FC<ImageSliderType> = (props): JSX.Element => {
    const { imgArray, currentImageSrc, changeCurrentImage } = props
    return (
        <div className="event-image-container">
            <img className="selected-image" src={currentImageSrc}></img>
            <div className="small-image-container">
                {imgArray.length > 1 &&
                    imgArray?.map(item => {
                        const imageClassname =
                            item === currentImageSrc
                                ? 'small-image selected-small-image'
                                : 'small-image'
                        return (
                            <img
                                className={imageClassname}
                                key={item}
                                src={item}
                                onClick={changeCurrentImage}
                            ></img>
                        )
                    })}
            </div>
        </div>
    )
}
