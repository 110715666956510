import React, { FC, useContext } from 'react'
import { toast } from 'react-toastify'
import { faTrash } from '@fortawesome/free-solid-svg-icons'
import { LazyLoadImage } from 'react-lazy-load-image-component'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { api } from 'utils/axiosService'
import { AuthContext } from 'context/AuthContext'
import { EventContext } from 'context/EventContext'
import type { FeedbacksItemProps } from 'utils/types'

const FeedbacksItem: FC<FeedbacksItemProps> = (props): JSX.Element => {
    const { image, name, position, feedback, _id } = props.commentary
    const { currentUser } = useContext(AuthContext)
    const { stateIsChange, setStateIsChange } = useContext(EventContext)

    const handleTrashFeedback = (
        e: React.MouseEvent<HTMLButtonElement>
    ): void => {
        e.preventDefault()
        const feedbackId = _id

        api.post(`admin/delete-feedback`, { feedbackId })
            .then(res => {
                toast.success(res.data.message)
                setStateIsChange(!stateIsChange)
            })
            .catch(err => {
                toast.error(err.response.data.message)
            })
    }

    return (
        <div className="feedbacks-item">
            <div className="feedbacks-item-header">
                <div className="feedbacks-item-icon">
                    <LazyLoadImage src={image} alt="feedback user icon" />
                </div>
                <div className="feedbacks-item-info">
                    <h4 className="feedbacks-item-name text-break">{name}</h4>
                    <p className="feedbacks-item-job text-break">{position}</p>
                </div>
            </div>
            <div className="feedbacks-item-text">
                <p className="text-break">{feedback}</p>
            </div>
            {currentUser.role === 999 && (
                <div className="trash-feedback">
                    <button
                        className="admin-btn-reject trash-icon"
                        onClick={handleTrashFeedback}
                    >
                        Delete
                        <FontAwesomeIcon icon={faTrash} />
                    </button>
                </div>
            )}
        </div>
    )
}

export default FeedbacksItem
