import React, {
    ChangeEvent,
    FC,
    useContext,
    useEffect,
    useRef,
    useState,
} from 'react'
import { toast } from 'react-toastify'
import { Button, FormGroup, Input } from 'reactstrap'
import { ErrorMessage, Field, Form, Formik } from 'formik'
import { LazyLoadImage } from 'react-lazy-load-image-component'
import { ArticleValidation } from 'utils/validation'
import { AuthContext } from 'context/AuthContext'
import { api } from 'utils/axiosService'
import './addArticle.scss'

export const AddArticle: FC = (): JSX.Element => {
    const { currentUser } = useContext(AuthContext)
    const [isDisable, setIsDisable] = useState(false)
    const [attachedFile, setAttachedFile] = useState<File[]>([])
    const [imageUrl, setImageUrl] = useState({
        url: '',
        name: '',
    })

    const inputRef = useRef(null)
    const input = inputRef.current as unknown as HTMLInputElement

    const [article, setArticle] = useState({
        position: '',
        articleTitle: '',
        articleDescription: '',
    })

    const combinedData = {
        ...article,
        attachedFile,
    }

    useEffect(() => {
        setAttachedFile([])
        setArticle({
            position: '',
            articleTitle: '',
            articleDescription: '',
        })
        setImageUrl({
            url: '',
            name: '',
        })
    }, [])

    const handleChange = (e: React.ChangeEvent<HTMLInputElement>): void => {
        setArticle(prevState => {
            const key = e.target.name
            return { ...prevState, [key]: e.target.value }
        })
    }

    const clearImage = () => {
        setAttachedFile([])
        setImageUrl({
            url: '',
            name: '',
        })

        input.value = ''
    }

    const formData = new FormData()
    const keys = Object.keys(article) as Array<keyof typeof article>
    keys.forEach(key => {
        const value = article[key].trim()
        formData.append(key, value)
    })

    attachedFile.forEach(file => {
        formData.append('file', file)
    })

    const handleAttachedFile = (e: ChangeEvent<HTMLInputElement>) => {
        if (e.target.files !== null && e.target.files.length === 1) {
            const currentFile = e.target.files[0]
            const newImageUrls = URL.createObjectURL(currentFile)
            setImageUrl({ name: currentFile.name, url: newImageUrls })
            setAttachedFile(prevState => [...prevState, currentFile])
        }
    }

    const addarticle = (): void => {
        setIsDisable(true)
        toast.dismiss()
        api.post(`user/create-article`, formData)
            .then(res => {
                setArticle({
                    position: '',
                    articleTitle: '',
                    articleDescription: '',
                })
                setAttachedFile([])
                setImageUrl({
                    url: '',
                    name: '',
                })
                toast.success(res.data.message)
                setIsDisable(false)
            })
            .catch(e => {
                setIsDisable(false)
                const errorsArray = e.response.data.message.errors
                if (errorsArray) {
                    if (!formData.get('file')) {
                        toast.error('Image is required')
                    }
                } else {
                    toast.error(e.response.data.message)
                }
            })
    }

    return (
        <div className="container">
            <div className="single-card-container">
                <div className="article-author">
                    <img
                        className="author-image"
                        src={
                            currentUser.image
                                ? currentUser.image
                                : process.env.PUBLIC_URL +
                                  '/assets/image/user-profile-page/unnamed.jpg'
                        }
                        alt="article author"
                    />
                    <div className="author-info">
                        <h5>{currentUser.name}</h5>
                    </div>
                </div>
                <Formik
                    enableReinitialize
                    initialValues={combinedData}
                    validationSchema={ArticleValidation}
                    onSubmit={addarticle}
                >
                    {formik => (
                        <Form method="post" encType="multipart/form-data">
                            <FormGroup>
                                <Input
                                    type="text"
                                    name="position"
                                    placeholder="Position"
                                    tag={Field}
                                    className={`form-control ${
                                        formik.errors.position &&
                                        formik.touched.position
                                            ? 'is-invalid'
                                            : ''
                                    }`}
                                    value={article.position}
                                    component="input"
                                    onChange={handleChange}
                                />
                                <ErrorMessage
                                    name="position"
                                    component="div"
                                    className="invalid-feedback"
                                />
                            </FormGroup>
                            <FormGroup>
                                <Input
                                    type="text"
                                    name="articleTitle"
                                    placeholder="Article Title"
                                    tag={Field}
                                    className={`form-control ${
                                        formik.errors.articleTitle &&
                                        formik.touched.articleTitle
                                            ? 'is-invalid'
                                            : ''
                                    }`}
                                    component="input"
                                    value={article.articleTitle}
                                    onChange={handleChange}
                                />
                                <ErrorMessage
                                    name="articleTitle"
                                    component="div"
                                    className="invalid-feedback"
                                />
                            </FormGroup>
                            <FormGroup>
                                <Input
                                    type="textarea"
                                    tag={Field}
                                    className={`form-control article-description ${
                                        formik.errors.articleDescription &&
                                        formik.touched.articleDescription
                                            ? 'is-invalid'
                                            : ''
                                    }`}
                                    onChange={handleChange}
                                    value={article.articleDescription}
                                    component="textarea"
                                    rows={5}
                                    name="articleDescription"
                                />
                                <ErrorMessage
                                    name="articleDescription"
                                    component="div"
                                    className="invalid-feedback"
                                />
                            </FormGroup>
                            <FormGroup className="mb-3">
                                <Input
                                    multiple
                                    type="file"
                                    name="file"
                                    className={`choose-file ${
                                        formik.errors.attachedFile &&
                                        formik.touched.attachedFile
                                            ? 'is-invalid'
                                            : 'choose-file'
                                    }`}
                                    innerRef={inputRef}
                                    accept="image/*"
                                    onChange={handleAttachedFile}
                                />
                                {imageUrl.name && (
                                    <LazyLoadImage
                                        src={imageUrl.url}
                                        key={imageUrl.url}
                                        data-name={imageUrl.name}
                                        onClick={clearImage}
                                        className="attached-file"
                                    />
                                )}
                                <ErrorMessage
                                    name="attachedFile"
                                    component="div"
                                    className="invalid-feedback"
                                />
                            </FormGroup>
                            <FormGroup>
                                <Button
                                    disabled={isDisable}
                                    type="submit"
                                    className="custom-btn create-article-btn"
                                >
                                    Add Article
                                </Button>
                            </FormGroup>
                        </Form>
                    )}
                </Formik>
            </div>
        </div>
    )
}
