import React, { FC } from 'react'
import type { AboutSectionItemProps } from 'utils/types'

const AboutSectionItem: FC<AboutSectionItemProps> = ({
    src,
    alt,
    text,
    order,
    title,
    subtitle,
}): JSX.Element => {
    return (
        <div className="about-section-item d-flex justify-content-between">
            <div className={`about-section-header col-lg-5 ${order}`}>
                <h2 className="about-section-title text-uppercase">{title}</h2>
                <h3 className="about-section-subtitle text-capitalize fw-bold">
                    {subtitle}
                </h3>
                <p className="about-section-desc">{text}</p>
            </div>
            <div className="about-section-photo col-lg-6">
                <img src={src} alt={alt} />
            </div>
        </div>
    )
}

export default AboutSectionItem
