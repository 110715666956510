import React, { createContext, useState, useEffect, MouseEvent } from 'react'
import jwtDecode from 'jwt-decode'
import { useNavigate } from 'react-router-dom'
import { User } from 'utils/Interfaces'
import type { AuthContextProviderProps, AuthProps } from 'utils/types'

export const AuthContext = createContext<AuthProps>({} as AuthProps)

export const AuthContextProvider = ({
    children,
}: AuthContextProviderProps): JSX.Element => {
    const [currentUser, setCurrentUser] = useState({} as User)
    const [modal, setModal] = useState<boolean>(false)
    const [isAuth, setIsAuth] = useState<boolean>(false)
    const [isLoginActive, setIsLoginActive] = useState<boolean>(true)
    const token = localStorage.getItem('user')
    const navigate = useNavigate()

    useEffect(() => {
        if (token) {
            setIsAuth(true)
            const user: User = jwtDecode(token)
            setCurrentUser(prevState => ({
                ...prevState,
                ...user,
            }))
        } else {
            setIsAuth(false)
        }
    }, [currentUser.id])

    const toggle = (): void => {
        setModal(!modal)
    }

    const logout = (e: MouseEvent<HTMLButtonElement>) => {
        e.preventDefault()
        localStorage.clear()
        setIsAuth(false)
        setModal(false)
        navigate('home')
        setCurrentUser({} as User)
    }

    return (
        <AuthContext.Provider
            value={{
                modal,
                setModal,
                toggle,
                isLoginActive,
                setIsLoginActive,
                isAuth,
                setIsAuth,
                currentUser,
                setCurrentUser,
                token,
                logout,
            }}
        >
            <> {children}</>
        </AuthContext.Provider>
    )
}
