import * as Yup from 'yup'
import { EmailChecker, EmptySpaceChecker } from './helpers'

const FILE_SIZE = 5000 * 1024
const SUPPORTED_FORMATS = ['image/jpg', 'image/jpeg', 'image/tiff', 'image/png']

export const SignInValidationSchema = Yup.object({
    email: Yup.string()
        .required('Email is required')
        .trim()
        .email('Invalid email')
        .test('Email', 'Invalid email', value => EmailChecker(value)),
    password: Yup.string()
        .required('Password is required')
        .matches(/^\S+$/, 'Password can not include spaces')
        .matches(/[a-zA-Z]/, 'Password must contain at least one latin letter')
        .min(4, 'Password must contain 4 or more characters')
        .max(12, 'Password must contain 12 or less character'),
})

export const SignUpValidationSchema = Yup.object({
    email: Yup.string()
        .required('Email is required')
        .trim()
        .email('Invalid email')
        .test('Email', 'Invalid email', value => EmailChecker(value)),
    password: Yup.string()
        .required('Password is required')
        .matches(/^\S+$/, 'Password can not include spaces')
        .matches(/[a-zA-Z]/, 'Password must contain at least one latin letter')
        .min(4, 'Password must contain 4 or more characters')
        .max(12, 'Password must contain 12 or less characters'),
    name: Yup.string()
        .required('Name is required')
        .trim()
        .min(2, 'Name is too short')
        .max(30, 'Name cannot be longer than 30 characters'),
    age: Yup.number()
        .integer()
        .required('Age is required')
        .min(18, 'You must be at least 18 years of age')
        .max(99, 'Max age 99'),
    file: Yup.mixed()
        .test('fileSize', 'File is too large', value => {
            const result = value.length
                ? value[value.length - 1].size <= FILE_SIZE
                : true
            return result
        })
        .test('fileFormat', 'Unsupported Format', value => {
            const result = value.length
                ? SUPPORTED_FORMATS.includes(value[value.length - 1].type)
                : true
            return result
        }),
})

export const EditValidationSchema = Yup.object({
    name: Yup.string()
        .required('Name is required')
        .trim()
        .min(2, 'Name is too short')
        .max(30, 'Name cannot be longer than 30 characters'),
    age: Yup.number()
        .integer()
        .required('Age is required')
        .min(18, 'You must be at least 18 years of age')
        .max(99, 'Max age 99'),
})

export const ChangePasswordValidationSchema = Yup.object({
    currentPassword: Yup.string()
        .required('Password is required')
        .matches(/^\S+$/, 'Password can not include spaces')
        .matches(/[a-zA-Z]/, 'Password must contain at least one latin letter')
        .min(4, 'Password must contain 4 or more characters')
        .max(12, 'Password must contain 12 or less characters'),

    newPassword: Yup.string()
        .required('Password is required')
        .matches(/^\S+$/, 'Password can not include spaces')
        .matches(/[a-zA-Z]/, 'Password must contain at least one latin letter')
        .min(4, 'Password must contain 4 or more characters')
        .max(12, 'Password must contain 12 or less characters'),

    confirmPassword: Yup.string()
        .required('Password is required')
        .matches(/^\S+$/, 'Password can not include spaces')
        .matches(/[a-zA-Z]/, 'Password must contain at least one latin letter')
        .min(4, 'Password must contain 4 or more characters')
        .max(12, 'Password must contain 12 or less characters'),
})

export const ChangeImageValidationSchema = Yup.object({
    file: Yup.mixed()
        .test('fileSize', 'File is too large', value => {
            const result = value.length
                ? value[value.length - 1].size <= FILE_SIZE
                : true
            return result
        })
        .test('fileFormat', 'Unsupported Format', value => {
            const result = value.length
                ? SUPPORTED_FORMATS.includes(value[value.length - 1].type)
                : true
            return result
        }),
})

export const EventCreateValidationSchema = Yup.object({
    title: Yup.string()
        .trim()
        .required('Title is required')
        .test(
            'len',
            'Title must be longer than 5 and shorter than 45 characters',
            value => EmptySpaceChecker(value, 5, 45)
        )
        .max(60, 'Title length can not be more than 60 characters'),
    description: Yup.string()
        .trim()
        .required('Description is required')
        .test(
            'len',
            'Description must be longer than 15 and shorter than 1000 characters',
            value => EmptySpaceChecker(value, 15, 1000)
        ),
    deadline: Yup.date().required('Deadline is required'),
    attachedFile: Yup.mixed()
        .test('required', 'Image is required', value => {
            return Boolean(value.length)
        })
        .test('Max files', 'Maximum 5 files allowed ', value => {
            return value.length <= 5
        })
        .test('fileSize', 'Image is too large', value => {
            return value?.every((item: File) => item?.size <= FILE_SIZE)
        })
        .test('fileFormat', 'Unsupported Format', value => {
            return value?.every((item: File) =>
                SUPPORTED_FORMATS.includes(item.type)
            )
        }),
})

export const ContactUsValidationSchema = Yup.object({
    name: Yup.string()
        .trim()
        .required('Name is required')
        .matches(/^\S+$/, 'Name cannot include spaces')
        .matches(/^[aA-zZ\s]+$/, 'Only latin letters allowed for this field')
        .test(
            'len',
            'Name must include more than 2 and less than 45 characters',
            value => EmptySpaceChecker(value, 2, 45)
        )
        .max(60, 'Name longer than 60 characters'),
    email: Yup.string()
        .required('Email is required')
        .trim()
        .email('Invalid email')
        .test('Email', 'Invalid email', value => EmailChecker(value)),
    subject: Yup.string()
        .trim()
        .required('Subject is required')
        .test(
            'len',
            'Subject must include more than 10 and less than 70 characters',
            value => EmptySpaceChecker(value, 10, 70)
        ),
    message: Yup.string()
        .trim()
        .required('Message is required')
        .test('len', 'Message must include more than 20 characters', value =>
            EmptySpaceChecker(value, 20, Infinity)
        ),
})

export const JoinFormValidationSchema = Yup.object({
    description: Yup.string()
        .trim()
        .required('Description is required')
        .test(
            'len',
            'Description must include more than 30 and less than 350 characters',
            value => EmptySpaceChecker(value, 30, 350)
        ),
})

export const RestorePasswordValidationSchema = Yup.object({
    newPassword: Yup.string()
        .required('Password is required')
        .matches(/^\S+$/, 'Password can not include spaces')
        .matches(/[a-zA-Z]/, 'Password must contain at least one latin letter')
        .min(4, 'Password must contain 4 or more characters')
        .max(12, 'Password must contain 12 or less characters'),

    confirmPassword: Yup.string()
        .required('Password is required')
        .matches(/^\S+$/, 'Password can not include spaces')
        .matches(/[a-zA-Z]/, 'Password must contain at least one latin letter')
        .min(4, 'Password must contain 4 or more characters')
        .max(12, 'Password must contain 12 or less characters'),
})

export const VerifyCodeValidationSchema = Yup.object({
    verifyCode: Yup.string()
        .required('Verify code is required')
        .matches(/^\S+$/, 'Verify code can not include spaces')
        .min(6, 'Verify code must contain 6 numbers')
        .max(6, 'Verify code must contain 6 numbers'),
})

export const EmailValidation = Yup.object({
    address: Yup.string()
        .required('Email is required')
        .trim()
        .email('Invalid email')
        .test('Email', 'Invalid email', value => EmailChecker(value)),
})

export const ArticleValidation = Yup.object({
    position: Yup.string().trim(),
    articleDescription: Yup.string()
        .trim()
        .required('Description is required')
        .test(
            'len',
            'Description must include more than 30 and less than 1000 characters',
            value => EmptySpaceChecker(value, 30, 4000)
        ),
    articleTitle: Yup.string()
        .trim()
        .required('Title is required')
        .test(
            'len',
            'Title must be longer than 5 and shorter than 45 characters',
            value => EmptySpaceChecker(value, 5, 45)
        )
        .max(60, 'Title length can not be more than 60 characters'),
    attachedFile: Yup.mixed()
        .test('required', 'Image is required', value => {
            return Boolean(value.length)
        })
        .test('Max files', 'Maximum 5 files allowed ', value => {
            return value.length <= 5
        })
        .test('fileSize', 'Image is too large', value => {
            return value?.every((item: File) => item?.size <= FILE_SIZE)
        })
        .test('fileFormat', 'Unsupported Format', value => {
            return value?.every((item: File) =>
                SUPPORTED_FORMATS.includes(item.type)
            )
        }),
})
