import React, { FC, useContext } from 'react'
import { useLocation } from 'react-router-dom'
import Footer from '../Components/Footer/Footer'
import Header from '../Components/Header/Header'
import MainRoutes from '../Components/Routes/MainRoutes'
import { AuthContext } from 'context/AuthContext'
import AdminRoutes from 'Components/Routes/AdminRoutes'
import { EmailVerify } from 'Components/EmailVerify/emailVerify'
import './Layout.scss'

const Layout: FC = (): JSX.Element => {
    const { currentUser } = useContext(AuthContext)
    const isAdmin = currentUser.role === 999
    const location = useLocation()

    return (
        <div className="layout">
            {location.pathname === '/user/mail-verification/' ? (
                <EmailVerify />
            ) : isAdmin ? (
                <AdminRoutes />
            ) : (
                <>
                    <Header />
                    <main className="main">
                        <MainRoutes />
                    </main>
                    {location.pathname === '/' ? <div></div> : <Footer />}
                </>
            )}
        </div>
    )
}

export default Layout
