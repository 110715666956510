import React, { FC, useContext, useEffect, useState } from 'react'
import { api } from 'utils/axiosService'
import { FeedbackSlider } from './feedBackSlider'
import { AuthContext } from 'context/AuthContext'
import { EventContext } from 'context/EventContext'
import { LoadingContext } from 'context/LoadingContext'
import { FeedbacksItemAdmin } from './feedbacksItemAdmin'
import './Feedbacks.scss'
import 'slick-carousel/slick/slick.css'
import 'slick-carousel/slick/slick-theme.css'

const Feedbacks: FC = (): JSX.Element => {
    const [addedFeedbacks, setAddedFeedbacks] = useState([])
    const { setIsLoading } = useContext(LoadingContext)
    const { currentUser } = useContext(AuthContext)
    const { stateIsChange } = useContext(EventContext)

    useEffect(() => {
        setIsLoading(true)
        api.get(`public/feedbacks`)
            .then(result => {
                setAddedFeedbacks(result.data)
                setIsLoading(false)
            })
            .catch(e => {
                const error = e.response.data.message.errors[0]
                console.log(error.msg)
                setIsLoading(false)
            })
    }, [stateIsChange])

    return (
        <div>
            {currentUser.role === 999 ? (
                <FeedbacksItemAdmin data={addedFeedbacks} />
            ) : (
                <div className="feedbacks text-light">
                    <div className="container">
                        <div className="feedbacks-header text-center text-capitalize">
                            <h2 className="feedbacks-header-title">
                                Feedbacks
                            </h2>

                            <h3 className="feedbacks-header-subtitle">
                                What our clients say about us
                            </h3>
                        </div>
                        <FeedbackSlider data={addedFeedbacks} />
                    </div>
                </div>
            )}
        </div>
    )
}

export default Feedbacks
