import React, { FC, useContext, useEffect, useState } from 'react'
import { toast } from 'react-toastify'
import { useDispatch } from 'react-redux'
import { AuthContext } from 'context/AuthContext'
import { ActionEventTypes } from 'utils/reduxTypes'
import { EventTab } from 'Components/EventTabs/eventTab'
import EventModal from 'Components/CreateEventModal/createEventModal'
import ProductMap from 'Components/ProductMap/productMap'
import { EventPolygonType } from 'utils/types'
import { api } from 'utils/axiosService'
import './ProductsPage.scss'

const ProductsPage: FC = (): JSX.Element => {
    const [isDisable, setDisable] = useState(false)
    const [createEvent, setCreateEvent] = useState(false)
    const { isAuth, setModal, currentUser } = useContext(AuthContext)
    const dispatch = useDispatch()

    const eventsCount = Number(localStorage.getItem('eventsCount'))
    const point = Number(currentUser.points)

    const userStatus = [
        Boolean(point < 10 && eventsCount > 4),
        Boolean(point < 20 && eventsCount > 6),
        Boolean(point < 30 && eventsCount > 9),
        Boolean(point > 29 && eventsCount > 14),
    ]

    const [eventPolygons, setEventPolygons] = useState<EventPolygonType[]>([])

    useEffect(() => {
        api.get(`map/get-approved-locations`)
            .then(result => {
                setEventPolygons(result.data)
            })
            .catch(e => {
                console.log(e.response.data.message)
            })
    }, [localStorage.getItem('eventsCount')])

    useEffect(() => {
        if (userStatus.some(item => item)) {
            setDisable(true)
        }
    }, [eventsCount])

    useEffect(() => {
        return () => {
            dispatch({
                type: ActionEventTypes.CLEAR,
                payload: '',
            })
        }
    }, [])

    const isAuthChecking = () =>
        isAuth ? setCreateEvent(true) : setModal(true)

    const showToastify = () => {
        toast.error('You have too many open events, please finish them first')
    }

    return (
        <div className="product-page-container">
            <ProductMap eventPolygons={eventPolygons} />
            <div className="create">
                <button
                    onClick={() =>
                        isDisable ? showToastify() : isAuthChecking()
                    }
                    className="custom-btn btn btn-secondary"
                >
                    CREATE
                </button>
            </div>
            <EventModal
                show={createEvent}
                onHide={(): void => setCreateEvent(false)}
                eventPolygons={eventPolygons}
            />
            <EventTab />
        </div>
    )
}

export default ProductsPage
