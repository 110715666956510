import React, { useState, FC, useEffect } from 'react'
import { TileLayer, MapContainer, Polygon, Marker } from 'react-leaflet'
import 'leaflet-draw/dist/leaflet.draw.css'
import 'leaflet/dist/leaflet.css'
import { finishedIcon, mapSettings, processingIcon } from '../../utils/helpers'
import { EventType, ProductsMapProp } from '../../utils/types'
import ProductModal from 'Components/ProductModal/productModal'
import { api } from '../../utils/axiosService'
import './productMap.scss'

const ProductMap: FC<ProductsMapProp> = (
    props: ProductsMapProp
): JSX.Element => {
    const { eventPolygons } = props
    const [event, setEvent] = useState<EventType>()
    const [modalShow, setModalShow] = useState(false)
    const [palette, setPallete] = useState('')
    const [connect, setConnect] = useState(0)
    const hideMap = true

    const getEvent = (eventId: string) => {
        api.get(`public/public-map-event/${eventId}`)
            .then(result => {
                setEvent(result.data.event)
                switch (result.data.event?.status) {
                    case 'done':
                        setPallete('success')
                        break
                    case 'rejected':
                        setPallete('danger')
                        break
                    case 'finished':
                        setPallete('success')
                        break
                    default:
                        setPallete('warning')
                }
                setConnect(result.data.connect)
            })
            .catch(e => {
                console.log(e.response.data.message)
            })
    }

    const setModal = () => {
        if (event) {
            return (
                <ProductModal
                    hideMap={hideMap}
                    show={modalShow}
                    palette={palette}
                    connect={connect}
                    event={event}
                    toggle={modalShow ? 1 : 0}
                    onHide={(): void => {
                        setModalShow(false)
                    }}
                />
            )
        }
    }

    useEffect(() => {
        setModalShow(true)
    }, [event])

    return (
        <div className="map-container">
            <MapContainer
                center={mapSettings.defaultCoords}
                zoom={mapSettings.zoomLevel}
                scrollWheelZoom={false}
            >
                <TileLayer
                    attribution={mapSettings.attribution}
                    url={mapSettings.mapUrl}
                />

                {eventPolygons.map((polygon, key) => (
                    <div key={key}>
                        <Polygon
                            positions={polygon.coordinates}
                            className={
                                polygon.status === 'finished'
                                    ? 'in-progress'
                                    : 'created'
                            }
                            eventHandlers={{
                                click: () => {
                                    getEvent(polygon.eventId)
                                },
                            }}
                        />

                        <Marker
                            icon={
                                polygon.status === 'finished'
                                    ? finishedIcon[key % 9]
                                    : processingIcon
                            }
                            position={[polygon.lat, polygon.lng]}
                            eventHandlers={{
                                click: () => {
                                    getEvent(polygon.eventId)
                                },
                            }}
                        ></Marker>
                    </div>
                ))}

                {setModal()}

                {eventPolygons.map((marker, key) => (
                    <Marker
                        icon={
                            marker.status === 'finished'
                                ? finishedIcon[key % 9]
                                : processingIcon
                        }
                        position={[marker.lat, marker.lng]}
                        key={key}
                        eventHandlers={{
                            click: () => {
                                getEvent(marker.eventId)
                            },
                        }}
                    ></Marker>
                ))}
            </MapContainer>
        </div>
    )
}

export default ProductMap
