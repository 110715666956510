import React, { FC } from 'react'
import { Button } from 'reactstrap'
import { Link } from 'react-router-dom'
import { LazyLoadImage } from 'react-lazy-load-image-component'
import './ChooseUs.scss'
import ChooseListItem from './ChooseListItem'

const items = [
    {
        text: 'The environment is everything that keeps us alive, from the food we eat, the water we drink, the air we breathe, and more; it is our home. Therefore, being concerned for the environment should come naturally to us since it is the reason for our survival. With us, you can organize exciting and adventurous team buildings, where you can gather with fellow colleagues alongside other change agents to make the whole idea worthwile. ',
    },
    {
        text: 'We provide a reliable, secure, high-quality network to connect our community members with each others, as well as supporters. It is very easy to log into our website since You won’t need to provide any confidential information. By just registering, you will become a member of our community and build networks with teams, individuals, and not only, to enjoy and experience the most sustainable, engaging, and helpful environmental projects that solely work to create a greener universe and a better place to live for us all. ',
    },
    {
        text: 'A future where people live in harmony with nature is something you can contribute to. Nature has provided us with numerous gifts, such as air, water, land, animals, minerals, and sunlight, making our earth a place worth living. Why not get on the journey of preserving those natural resources alongside individuals that believe in the importance of unlocking a stable environment and future for all living things through their fun and effective contributions? ',
    },
]

const ChooseUs: FC = (): JSX.Element => {
    return (
        <div className="choose-us">
            <div className="container">
                <div className="row choose-us-content justify-content-between">
                    <div className="choose-us-content col-lg-5">
                        <div className="choose-us-header">
                            <h2 className="choose-us-title text-uppercase">
                                Why choose us!
                            </h2>
                            <p className="choose-us-subtitle text-capitalize">
                                Why we need you?
                            </p>
                            <p className="choose-us-desc">
                                Let’s preserve our green world With Be Green!
                            </p>
                        </div>
                        <div className="choose-us-list mb-4">
                            <div className="row">
                                {items.map((item, index) => (
                                    <ChooseListItem
                                        key={index}
                                        text={item.text}
                                    />
                                ))}
                            </div>
                        </div>
                    </div>
                    <div className="col-lg-7 d-flex justify-content-center align-items-center">
                        <div className="choose-us-photos">
                            <div className="choose-us-photo choose-us-photo-1">
                                <LazyLoadImage
                                    src={
                                        process.env.PUBLIC_URL +
                                        '/assets/image/chooseus/Landing-Participate.jpg'
                                    }
                                    alt="landing participate"
                                />
                            </div>
                            <div className="choose-us-photo choose-us-photo-2">
                                <LazyLoadImage
                                    src={
                                        process.env.PUBLIC_URL +
                                        '/assets/image/chooseus/plant_a_tree.jpg'
                                    }
                                    alt="plant a tree"
                                />
                            </div>
                        </div>
                    </div>
                </div>
                <div className="row choose-us-btn-container">
                    <Button className="choose-us-btn custom-btn text-uppercase btn-sm">
                        <Link
                            to="/about"
                            className="choose-us-link link-light d-flex justify-content-center align-items-center"
                        >
                            More info
                        </Link>
                    </Button>
                </div>
            </div>
        </div>
    )
}

export default ChooseUs
