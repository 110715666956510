import React, { FC } from 'react'
import Slider from 'react-slick'
import CarouselItem from './CarouselItem'
import 'slick-carousel/slick/slick.css'
import 'slick-carousel/slick/slick-theme.css'
import './Carousel.scss'

const settings = {
    dots: true,
    infinite: true,
    speed: 1000,
    slidesToShow: 1,
    slidesToScroll: 1,
    autoplay: true,
    centerMode: true,
    centerPadding: '52px',
    arrows: false,
}

const slides = [
    {
        src:
            process.env.PUBLIC_URL +
            '/assets/image/home-carousel/home-carousel-3.jpg',

        alt: 'plant tree',
    },
    {
        src:
            process.env.PUBLIC_URL +
            '/assets/image/home-carousel/home-carousel-2.jpg',

        alt: 'plant tree',
    },
    {
        src:
            process.env.PUBLIC_URL +
            '/assets/image/home-carousel/home-carousel-1.jpg',

        alt: 'leaves',
    },
]

const HomeCarousel: FC = (): JSX.Element => {
    return (
        <div className="home-carousel text-light">
            <Slider {...settings}>
                {slides.map((slide, index) => (
                    <CarouselItem key={index} src={slide.src} alt={slide.alt} />
                ))}
            </Slider>
        </div>
    )
}

export default HomeCarousel
