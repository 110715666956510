import React, { FC, useContext, useEffect, useState } from 'react'
import { useParams } from 'react-router-dom'
import { api } from 'utils/axiosService'
import { SingleArticle } from './singleArticle'
import { LoadingContext } from 'context/LoadingContext'
import './singleArticle.scss'

export const SingleArticleData: FC = (): JSX.Element => {
    const [single, setSingle] = useState<any>()
    const { setIsLoading } = useContext(LoadingContext)
    const [authorImage, setAuthorImage] = useState(
        process.env.PUBLIC_URL + '/assets/image/user-profile-page/unnamed.jpg'
    )

    const { id } = useParams()

    useEffect(() => {
        setIsLoading(true)
        api.get(`public/get-one-article/${id}`)
            .then(result => {
                if (result?.data.authorId) {
                    api.get(`user/${result.data.authorId}`).then(data => {
                        setAuthorImage(data.data)
                    })
                }

                setSingle({ ...result.data, authorImage })
                setIsLoading(false)
            })
            .catch(e => {
                const error = e.response.data.message.errors[0]
                console.log(error.msg)
                setIsLoading(false)
            })
    }, [])

    useEffect(() => {
        if (single) {
            const singleArticle = { ...single, authorImage }
            setSingle(singleArticle)
        }
    }, [authorImage])

    return (
        <div className="container">
            <div className="single-card-container">
                {single && <SingleArticle data={single} />}
            </div>
        </div>
    )
}
