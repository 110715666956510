import React, { FC } from 'react'
import { Table } from 'react-bootstrap'
import { EventCardType } from 'utils/types'
import { Subscriebers } from 'Components/Subscriebers/subscriebers'
import './forAllEvents.scss'

export const SubscribersTable: FC<EventCardType> = (props): JSX.Element => {
    const { subscribers } = props.event

    return (
        <div>
            <Table className="subscribe-table" hover bordered>
                <thead>
                    <tr>
                        <th>Name</th>
                        <th>Email</th>
                        <th>Description</th>
                        <th>Status</th>
                    </tr>
                </thead>
                <tbody>
                    {subscribers?.map((subscriber, index) => (
                        <Subscriebers
                            name={subscriber.name}
                            email={subscriber.email}
                            description={subscriber.description}
                            status={
                                subscriber.status
                                    ? subscriber.status
                                    : 'pending'
                            }
                            key={index}
                        />
                    ))}
                </tbody>
            </Table>
        </div>
    )
}
