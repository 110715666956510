import React, { FC, useEffect, useState } from 'react'
import { TileLayer, MapContainer, Polygon } from 'react-leaflet'
import { mapSettings } from '../../utils/helpers'
import 'leaflet/dist/leaflet.css'
import 'leaflet-draw/dist/leaflet.draw.css'
import './eventCardMap.scss'
import {
    CenterCoordinatesType,
    eventCardMapProp,
    EventPolygonType,
} from '../../utils/types'
import { api } from '../../utils/axiosService'

const EventCardMap: FC<eventCardMapProp> = (props): JSX.Element => {
    const [eventPolygon, setEventPolygon] = useState<EventPolygonType>()
    const [centerCoordinates, setCenterCoordinates] =
        useState<CenterCoordinatesType>()

    useEffect(() => {
        api.get(props.url)
            .then(result => {
                if (result.data.coordinates) {
                    setEventPolygon(result.data)
                    setCenterCoordinates({
                        lat: result.data.lat,
                        lng: result.data.lng,
                    })
                }
            })
            .catch(e => {
                console.log(e.response.data.message)
            })
    }, [])

    return (
        <div>
            {eventPolygon ? (
                <div className="map-container">
                    <MapContainer
                        center={centerCoordinates}
                        zoom={15}
                        className="event-card-map"
                    >
                        <TileLayer
                            attribution={mapSettings.attribution}
                            url={mapSettings.mapUrl}
                        />

                        <Polygon
                            className="event-in-progress"
                            positions={eventPolygon.coordinates}
                        />
                    </MapContainer>
                </div>
            ) : (
                ''
            )}
        </div>
    )
}

export default EventCardMap
