import React, { FC, useContext, useState } from 'react'
import jwtDecode from 'jwt-decode'
import { toast } from 'react-toastify'
import { ErrorMessage, Formik, Form, Field } from 'formik'
import { Input, Label, FormGroup, Button } from 'reactstrap'
import { api } from 'utils/axiosService'
import { AuthContext } from 'context/AuthContext'
import { ChangePassword } from './changePassword'
import { EditValidationSchema } from 'utils/validation'
import './userProfileCardEdit.scss'

const UserProfileEdit: FC = (): JSX.Element => {
    const { currentUser, setCurrentUser } = useContext(AuthContext)
    const [isDisable, setIsDisable] = useState(false)
    const [user, setUser] = useState({
        name: currentUser.name,
        age: currentUser.age,
    })

    const [show, setShow] = useState(false)

    const onHide = () => setShow(!show)

    const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        setUser({ ...user, [e.target.name]: e.target.value })
    }

    const handleEdit = () => {
        setIsDisable(true)
        if (currentUser.name === user.name && currentUser.age === user.age) {
            toast.error('You have not changed anything')
            setIsDisable(false)
        } else {
            api.post('user/edit-user-profile', user)
                .then(res => {
                    if (res.data.token) {
                        localStorage.setItem(
                            'user',
                            JSON.stringify(res.data.token)
                        )
                        const userData: {
                            id: string
                            age: string
                            role: number
                            name: string
                            email: string
                            image: string
                            password: string
                        } = jwtDecode(res.data.token)
                        setCurrentUser(prevState => ({
                            ...prevState,
                            ...userData,
                        }))
                    }
                    toast.success('Changes applied')
                    setIsDisable(false)
                })
                .catch(error => {
                    setIsDisable(false)
                    toast.error(error.response.data.message)
                })
        }
    }

    return (
        <div className="optional-profile-info">
            <div className="user-profile-card-edit-block">
                <h3>Profile settings</h3>
                <Formik
                    initialValues={user}
                    onSubmit={handleEdit}
                    validationSchema={EditValidationSchema}
                    enableReinitialize
                >
                    {formik => (
                        <Form>
                            <FormGroup>
                                <Label>Name</Label>
                                <Input
                                    tag={Field}
                                    className={`form-control ${
                                        formik.errors.name &&
                                        formik.touched.name
                                            ? 'is-invalid'
                                            : ''
                                    }`}
                                    component="input"
                                    type="text"
                                    name="name"
                                    onChange={handleChange}
                                />
                                <ErrorMessage
                                    name="name"
                                    component="div"
                                    className="invalid-feedback"
                                />
                            </FormGroup>
                            <FormGroup>
                                <Label>Email</Label>
                                <Input
                                    value={currentUser.email}
                                    tag={Field}
                                    className="form-control"
                                    component="input"
                                    type="text"
                                    name="email"
                                    disabled
                                />
                            </FormGroup>
                            <FormGroup>
                                <Label>Age</Label>
                                <Input
                                    tag={Field}
                                    className={`form-control ${
                                        formik.errors.age && formik.touched.age
                                            ? 'is-invalid'
                                            : ''
                                    }`}
                                    component="input"
                                    type="number"
                                    name="age"
                                    onChange={handleChange}
                                />
                                <ErrorMessage
                                    name="age"
                                    component="div"
                                    className="invalid-feedback"
                                />
                            </FormGroup>
                            <Button
                                disabled={isDisable}
                                type="submit"
                                className="custom-btn auth-form-btn btn btn-lg"
                            >
                                Save changes
                            </Button>
                        </Form>
                    )}
                </Formik>
                <Button
                    type="submit"
                    className="custom-btn auth-form-btn btn btn-lg"
                    onClick={onHide}
                >
                    Change password
                </Button>
                <ChangePassword show={show} onHide={onHide} />
            </div>
        </div>
    )
}

export default UserProfileEdit
