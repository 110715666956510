import React, { FC } from 'react'
import AboutCeo from 'Components/AboutCEO/AboutCeo'
import AboutMembers from 'Components/AboutMembers/AboutMembers'
import AboutSection from 'Components/AboutSection/AboutSection'
import './AboutPage.scss'

const AboutPage: FC = (): JSX.Element => {
    return (
        <div className="about">
            <div className="about-banner"></div>
            <div className="container">
                <AboutCeo />
                <AboutSection />
                <AboutMembers />
            </div>
        </div>
    )
}

export default AboutPage
