import React, {
    SetStateAction,
    useEffect,
    Dispatch,
    useState,
    useRef,
} from 'react'
import moment from 'moment'
import { toast } from 'react-toastify'
import { api } from 'utils/axiosService'
import { Button, Modal } from 'react-bootstrap'
import { FormGroup, Input, Label } from 'reactstrap'
import { Formik, Form, Field, ErrorMessage } from 'formik'
import {
    MapContainer,
    FeatureGroup,
    TileLayer,
    Polygon,
    Marker,
} from 'react-leaflet'
import { EditControl } from 'react-leaflet-draw'
import { LazyLoadImage } from 'react-lazy-load-image-component'
import 'react-toastify/dist/ReactToastify.css'
import { ProductsEventModalProp, AxiosErrMsgProp } from 'utils/types'
import { EventCreateValidationSchema } from 'utils/validation'
import {
    ObjectURLCreater,
    countIncrement,
    mapSettings,
    isOverlap,
    finishedIcon,
    processingIcon,
} from 'utils/helpers'
import { IMapPoint } from 'utils/Interfaces'
import { LatLngTuple } from 'leaflet'
import './createEventModal.scss'

const EventModal = (props: ProductsEventModalProp): JSX.Element => {
    const [isDisable, setIsDisable] = useState(true)
    const [nextIsDisable, setNextIsDisable] = useState(true)
    const { show, onHide, eventPolygons } = props
    const [attachedFile, setAttachedFile] = useState<File[]>([])
    const [step, setStep] = useState(0)

    const [mapLayersToShow, setMapLayersToShow]: [
        IMapPoint[],
        Dispatch<SetStateAction<IMapPoint[]>>
    ] = useState<IMapPoint[]>([])

    const [mapLayers, setMapLayers]: [
        IMapPoint[],
        Dispatch<SetStateAction<IMapPoint[]>>
    ] = useState<IMapPoint[]>([])

    const [imageUrl, setImageUrl] = useState([
        {
            url: '',
            name: '',
        },
    ])

    const [eventData, setEventData] = useState({
        title: '',
        deadline: '',
        description: '',
    })

    const mapRef = useRef(null)

    const inputRef = useRef(null)
    const input = inputRef.current as unknown as HTMLInputElement

    const combinedData = {
        ...eventData,
        attachedFile,
    }

    useEffect(() => {
        if (mapLayers.length) {
            setNextIsDisable(false)
        } else {
            setNextIsDisable(true)
        }
    }, [mapLayers])

    useEffect(() => {
        setAttachedFile([])
        setEventData({ title: '', description: '', deadline: '' })
        setMapLayers([])
        setMapLayersToShow([])
        setStep(0)
        setIsDisable(true)
        setImageUrl([])
    }, [show])

    const handleInputValue = (e: React.ChangeEvent<HTMLInputElement>): void => {
        setEventData(prevState => {
            const key = e.target.name
            return { ...prevState, [key]: e.target.value }
        })
    }

    const clearImage = (e: React.MouseEvent<HTMLImageElement, MouseEvent>) => {
        const Image = e.target as HTMLInputElement & HTMLDivElement
        const src = Image.src
        const name = Image.dataset.name

        setImageUrl(prevState => prevState.filter(item => item.url !== src))
        setAttachedFile(prevState =>
            prevState.filter(item => item.name !== name)
        )

        input.value = ''
    }

    const eventFormData = new FormData()
    const keys = Object.keys(eventData) as Array<keyof typeof eventData>
    keys.forEach(key => {
        const value = eventData[key].trim()
        eventFormData.append(key, value)
    })

    attachedFile.forEach(file => {
        eventFormData.append('file', file)
    })

    const handleAttachedFile = (
        e: React.ChangeEvent<HTMLInputElement>
    ): void => {
        if (e.target.files !== null && e.target.files.length > 0) {
            toast.dismiss()
            const files = e.target.files
            for (const file of files) {
                if (attachedFile.some(item => file.name === item.name)) {
                    toast.error(`File ${file.name} is already exists`)
                } else {
                    setImageUrl(prev => [
                        ...prev,
                        { name: file.name, url: ObjectURLCreater(file) },
                    ])
                    setAttachedFile(prev => [...prev, file])
                }
            }
            input.value = ''
        }
    }

    const clearMap = () => {
        const existMapLayers: any = mapRef.current

        mapLayers.map(layer => {
            existMapLayers.eachLayer((item: any) => {
                if (item._leaflet_id === layer.id) {
                    item.remove()
                }
            })
        })
    }

    const nextStep = () => {
        setStep(1)
        setIsDisable(false)
        setMapLayersToShow(mapLayers)
    }

    const previousStep = () => {
        setStep(0)
        setIsDisable(true)
    }

    const skipStep = () => {
        setStep(1)
        setIsDisable(false)
        setMapLayers([])
        setMapLayersToShow([])
        clearMap()
    }

    const onCreate = (e: any) => {
        const layer = e.layer

        const coordinates = layer.getLatLngs()[0].map(Object.values)

        setMapLayers((layers: IMapPoint[]): IMapPoint[] => [
            ...layers,
            { id: layer._leaflet_id, coordinates },
        ])
    }

    const onEdited = (e: any) => {
        const layers = e.layers._layers

        setMapLayers((old: IMapPoint[]): IMapPoint[] =>
            old.map(el => {
                if (layers[el.id]) {
                    const coordinates: LatLngTuple[][] = []

                    layers[el.id]
                        .getLatLngs()[0]
                        .map(Object.values)
                        .map((item: any) => {
                            if (item.length > 2) {
                                item = item.slice(0, 2)
                            }
                            coordinates.push(item)
                        })
                    return { id: el.id, coordinates }
                } else {
                    for (const i in layers) {
                        const id = parseInt(layers[i].options.attribution)

                        if (el.id === id) {
                            const coordinates: LatLngTuple[][] = []

                            layers[i]
                                .getLatLngs()[0]
                                .map(Object.values)
                                .map((item: any) => {
                                    if (item.length > 2) {
                                        item = item.slice(0, 2)
                                    }
                                    coordinates.push(item)
                                })
                            return { id: el.id, coordinates }
                        }
                    }
                    return el
                }
            })
        )
    }

    const onDeleted = (e: any) => {
        const layers = e.layers._layers

        mapLayers.map(layer => {
            for (const i in layers) {
                const id = parseInt(layers[i].options.attribution)
                if (layer.id === id) {
                    setMapLayers((old: IMapPoint[]): IMapPoint[] =>
                        old.filter(el => el.id !== id)
                    )
                }
            }
        })

        setMapLayers((old: IMapPoint[]): IMapPoint[] =>
            old.filter(el => !layers[el.id])
        )
    }

    const sendEventData = (): void => {
        setIsDisable(true)

        const coordinates: any[] = []

        mapLayers.map(layer => {
            coordinates.push(layer.coordinates)
        })
        eventFormData.append('coordinates', JSON.stringify(coordinates))

        toast.dismiss()
        const hideModal = onHide

        if (coordinates.length > 1) {
            toast.error('please mark one specific area to turn green')
            setIsDisable(false)
        } else if (isOverlap(eventPolygons, coordinates)) {
            toast.error(
                'this area is already scheduled to turn green by another organiser'
            )
            setIsDisable(false)
        } else {
            api.post(`user/create-event`, eventFormData)
                .then(res => {
                    hideModal()
                    setEventData({ title: '', description: '', deadline: '' })
                    setAttachedFile([])
                    setImageUrl([])
                    toast.success(res.data.message)
                    setIsDisable(false)
                    countIncrement('create')
                })
                .catch(e => {
                    setIsDisable(false)
                    const errorsArray = e.response.data.message.errors
                    if (errorsArray) {
                        if (!eventFormData.get('file')) {
                            toast.error('Image is required')
                        }
                        errorsArray.forEach((error: AxiosErrMsgProp) => {
                            toast.error(error.msg)
                        })
                    } else {
                        toast.error(e.response.data.message)
                    }
                })
        }
    }
    return (
        <Formik
            enableReinitialize
            initialValues={combinedData}
            validationSchema={EventCreateValidationSchema}
            onSubmit={sendEventData}
        >
            {formik => (
                <Modal className="modal-create" {...props}>
                    <Modal.Header closeButton>
                        <Modal.Title>Create new event</Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                        <Form method="post" encType="multipart/form-data">
                            {step < 1 ? (
                                <FormGroup className="mb-3">
                                    <MapContainer
                                        center={mapSettings.defaultCoords}
                                        zoom={mapSettings.zoomLevel}
                                        ref={mapRef}
                                    >
                                        <FeatureGroup>
                                            <EditControl
                                                position="topright"
                                                onCreated={onCreate}
                                                onEdited={onEdited}
                                                onDeleted={onDeleted}
                                                draw={mapSettings.drawSettings}
                                            />

                                            {mapLayersToShow.map(
                                                (layer, key) => (
                                                    <Polygon
                                                        className="event-in-progress"
                                                        positions={
                                                            layer.coordinates
                                                        }
                                                        key={key}
                                                        attribution={layer.id.toString()}
                                                    />
                                                )
                                            )}
                                        </FeatureGroup>

                                        {eventPolygons.map(
                                            (eventPolygon, key) => (
                                                <Polygon
                                                    positions={
                                                        eventPolygon.coordinates
                                                    }
                                                    key={key}
                                                    className={
                                                        eventPolygon.status ===
                                                        'finished'
                                                            ? 'event-in-progress'
                                                            : 'event-created'
                                                    }
                                                />
                                            )
                                        )}
                                        {eventPolygons.map((marker, key) => (
                                            <Marker
                                                icon={
                                                    marker.status === 'finished'
                                                        ? finishedIcon[key % 9]
                                                        : processingIcon
                                                }
                                                position={[
                                                    marker.lat,
                                                    marker.lng,
                                                ]}
                                                key={key}
                                            ></Marker>
                                        ))}
                                        <TileLayer
                                            attribution={
                                                mapSettings.attribution
                                            }
                                            url={mapSettings.mapUrl}
                                        />
                                    </MapContainer>
                                </FormGroup>
                            ) : (
                                <>
                                    <FormGroup className="mb-3">
                                        <Label>Title</Label>
                                        <Input
                                            tag={Field}
                                            className={`form-control ${
                                                formik.errors.title &&
                                                formik.touched.title
                                                    ? 'is-invalid'
                                                    : ''
                                            }`}
                                            component="input"
                                            type="text"
                                            name="title"
                                            onChange={handleInputValue}
                                        />
                                        <ErrorMessage
                                            name="title"
                                            component="div"
                                            className="invalid-feedback"
                                        />
                                    </FormGroup>
                                    <FormGroup className="mb-3">
                                        <Label>Description</Label>
                                        <Input
                                            tag={Field}
                                            className={`form-control ${
                                                formik.errors.description &&
                                                formik.touched.description
                                                    ? 'is-invalid'
                                                    : ''
                                            }`}
                                            component="textarea"
                                            rows={5}
                                            name="description"
                                            onChange={handleInputValue}
                                        />
                                        <ErrorMessage
                                            name="description"
                                            component="div"
                                            className="invalid-feedback"
                                        />
                                    </FormGroup>
                                    <FormGroup className="mb-3">
                                        <Label>Attach file</Label>
                                        <Input
                                            multiple
                                            type="file"
                                            name="file"
                                            className={`choose-file ${
                                                formik.errors.attachedFile &&
                                                formik.touched.attachedFile
                                                    ? 'is-invalid'
                                                    : 'choose-file'
                                            }`}
                                            accept="image/*"
                                            innerRef={inputRef}
                                            onChange={handleAttachedFile}
                                        />
                                        {imageUrl.map(item => (
                                            <LazyLoadImage
                                                src={item.url}
                                                key={item.url}
                                                data-name={item.name}
                                                onClick={clearImage}
                                                className="attached-file"
                                            />
                                        ))}
                                        <ErrorMessage
                                            name="attachedFile"
                                            component="div"
                                            className="invalid-feedback"
                                        />
                                    </FormGroup>
                                    <FormGroup className="mb-3">
                                        <Label>Deadline</Label>
                                        <Input
                                            tag={Field}
                                            className={`form-control ${
                                                formik.errors.deadline &&
                                                formik.touched.deadline
                                                    ? 'is-invalid'
                                                    : ''
                                            }`}
                                            component="input"
                                            type="date"
                                            name="deadline"
                                            min={moment(new Date()).format(
                                                'YYYY-MM-DD'
                                            )}
                                            max={`${
                                                new Date().getFullYear() + 2
                                            }-01-01`}
                                            onChange={handleInputValue}
                                        />
                                        <ErrorMessage
                                            name="deadline"
                                            component="div"
                                            className="invalid-feedback"
                                        />
                                    </FormGroup>
                                </>
                            )}

                            <FormGroup className="d-flex justify-content-end">
                                {step > 0 ? (
                                    <>
                                        <a
                                            href="/our-partners"
                                            target="_blank"
                                            rel="noopener noreferrer"
                                            className="partner-link"
                                        >
                                            Who can help you?
                                        </a>

                                        <div className="">
                                            <Button
                                                onClick={previousStep}
                                                type="button"
                                                className="custom-btn form-action-button"
                                            >
                                                Back
                                            </Button>
                                            <Button
                                                disabled={isDisable}
                                                type="submit"
                                                className="event-create-btn custom-btn"
                                            >
                                                Create
                                            </Button>
                                        </div>
                                    </>
                                ) : (
                                    <>
                                        <Button
                                            onClick={skipStep}
                                            type="button"
                                            className="custom-btn form-action-button"
                                        >
                                            Skip
                                        </Button>
                                        <Button
                                            onClick={nextStep}
                                            type="button"
                                            className="custom-btn"
                                            disabled={nextIsDisable}
                                        >
                                            Next
                                        </Button>
                                    </>
                                )}
                            </FormGroup>
                        </Form>
                    </Modal.Body>
                </Modal>
            )}
        </Formik>
    )
}
export default EventModal
