import {
    ActionEventTypes,
    ActiveAction,
    ActivePublicState,
} from 'utils/reduxTypes'

const initialEventState: ActivePublicState = {
    activePublic: '',
}

export const ActivetabPublicReducer = (
    state = initialEventState,
    action: ActiveAction
): ActivePublicState => {
    switch (action.type) {
        case ActionEventTypes.EVENTS:
            return {
                activePublic: action.payload,
            }
        case ActionEventTypes.FINISHED_EVENTS:
            return {
                activePublic: action.payload,
            }
        case ActionEventTypes.IN_PROGRESS_EVENTS:
            return {
                activePublic: action.payload,
            }
        case ActionEventTypes.CLEAR:
            return {
                activePublic: action.payload,
            }
        default:
            return state
    }
}
