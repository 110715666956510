import React, { FC } from 'react'
import type { CarouselItemProps } from 'utils/types'

const CarouselItem: FC<CarouselItemProps> = ({ src, alt }): JSX.Element => {
    return (
        <div className="home-carousel-item">
            <div className="home-carousel-img">
                <img src={src} alt={alt} />
            </div>
        </div>
    )
}

export default CarouselItem
