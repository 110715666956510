import { EventPolygonType } from './types'
import L, { LatLngTuple } from 'leaflet'

export const PathData = {
    approveEvent: {
        approve: {
            path: 'admin/approve-event',
            message: 'Event successfully approved',
        },
        reject: { path: 'admin/reject-event', message: 'Event Rejected' },
    },
    applyEvent: {
        approve: {
            path: 'admin/apply-event',
            message: 'Event successfully added In Progress',
        },
        reject: {
            path: 'admin/reject-event-to-start',
            message: 'Event Rejected',
        },
    },
    approveEventDone: {
        approve: {
            path: 'admin/approve-event-done',
            message: 'Event successfully finished',
        },
        reject: {
            path: 'admin/move-to-back-event',
            message: 'Event Rejected',
        },
    },
}

export const mapSettings = {
    defaultCoords: {
        lat: 40.1772,
        lng: 44.50349,
    },
    zoomLevel: 13,
    mapUrl: 'https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png',
    attribution: '',
    drawSettings: {
        rectangle: false,
        polyline: false,
        circle: false,
        circlemarker: false,
        marker: false,
        polygon: {
            allowIntersection: false,
        },
    },
}

const icons: string[] = [
    'assets/markers/1087446.png',
    'assets/markers/4264512.png',
    'assets/markers/sappling.png',
    'assets/markers/green-tea.png',
    'assets/markers/imageedit_4_3937341393.png',
    'assets/markers/imageedit_90_7486434650.png',
    'assets/markers/imageedit_33_7649268076.png',
    'assets/markers/imageedit_45_4514786463.png',
    'assets/markers/imageedit_47_2892544458.png',
]

export const finishedIcon: any = icons.map(
    (el: string) =>
        new L.Icon({
            iconUrl: el,
            iconSize: [42, 42],
            iconAnchor: [21, 42],
            popupAnchor: [0, -38],
        })
)

export const processingIcon = new L.Icon({
    iconUrl: 'assets/markers/planting-tree.png',
    iconSize: [53, 53],
    iconAnchor: [5, 42],
    popupAnchor: [0, -38],
})

export const officeLocationMarker = new L.Icon({
    iconUrl: 'assets/markers/marker-red.png',
    iconSize: [32, 32],
    iconAnchor: [16, 32],
    popupAnchor: [0, -38],
})

export const EmptySpaceChecker = (
    value: string | undefined,
    min = 0,
    max: number
): boolean => {
    const characterArr = value?.split('')
    const filtredArr = characterArr?.filter(item => item !== ' ')
        ? characterArr?.filter(item => item !== ' ')
        : []

    if (min < filtredArr.length && filtredArr.length <= max) {
        return true
    }

    return false
}

export const EmailChecker = (value: string | undefined): boolean => {
    const splitVal = value?.split('.')
    return splitVal ? splitVal[splitVal.length - 1].length > 1 : false
}

export const ObjectURLCreater = (file: File): string => {
    return URL.createObjectURL(file)
}

export const countIncrement = (join: string): number => {
    if (join === 'join') {
        const count = Number(localStorage.getItem('joinsCount'))
        const newCount = count + 1
        localStorage.setItem('joinsCount', JSON.stringify(newCount))
        return newCount
    } else {
        const count = Number(localStorage.getItem('eventsCount'))
        const newCount = count + 1
        localStorage.setItem('eventsCount', JSON.stringify(newCount))
        return newCount
    }
}

export const countDecrement = (join: string): number => {
    if (join === 'join') {
        const count = Number(localStorage.getItem('joinsCount'))
        const newCount = count - 1
        localStorage.setItem('joinsCount', JSON.stringify(newCount))
        return newCount
    } else {
        const count = Number(localStorage.getItem('eventsCount'))
        const newCount = count - 1
        localStorage.setItem('eventsCount', JSON.stringify(newCount))
        return newCount
    }
}

export const partnersData = [
    {
        id: 1,
        image: 'https://hrashkaygi.am/images/logo_arm.png',
        name: 'Hrashq Aygi',
        partnerLink: 'https://hrashkaygi.am/arm',
    },
    {
        id: 2,
        image: 'https://gorciqner.am/wp-content/uploads/2020/06/new_logo.png',
        name: 'Gorciqner',
        partnerLink:
            'https://gorciqner.am/product-category/%d5%a1%d5%b5%d5%a3%d5%a5%d5%a3%d5%b8%d6%80%d5%ae%d5%a1%d5%af%d5%a1%d5%b6-%d5%bd%d5%a1%d6%80%d6%84%d5%a1%d5%be%d5%b8%d6%80%d5%b8%d6%82%d5%b4%d5%b6%d5%a5%d6%80/',
    },
    {
        id: 3,
        image: 'https://www.spyur.am/images/logo/4975.png',
        name: 'Im Aygin',
        partnerLink:
            'https://www.construction.am/arm/companies/my-garden-shop-unitools-llc/',
    },
    {
        id: 4,
        image: 'https://gardenworld.am/wp-content/uploads/2019/05/logo.png',
        name: 'Aygu Ashxarh',
        partnerLink: 'http://gardenworld.am/',
    },
]

export const isOverlap = (
    eventPolygons: EventPolygonType[],
    newPolygons: LatLngTuple[][]
): boolean => {
    const x = 0
    const y = 1

    function onSegment(tip: number[], tip1: number[], point: number[]) {
        if (
            tip1[x] <= Math.max(tip[x], point[x]) &&
            tip1[x] >= Math.min(tip[x], point[x]) &&
            tip1[y] <= Math.max(tip[y], point[y]) &&
            tip1[y] >= Math.min(tip[y], point[y])
        ) {
            return true
        }
        return false
    }

    function orientation(tip: number[], tip1: number[], point: number[]) {
        const val =
            (tip1[y] - tip[y]) * (point[x] - tip1[x]) -
            (tip1[x] - tip[x]) * (point[y] - tip1[y])
        if (val == 0) {
            return 0
        }
        return val > 0 ? 1 : 2
    }

    function doIntersect(
        side11: number[],
        side12: number[],
        side21: number[],
        side22: number[]
    ) {
        const o1 = orientation(side11, side12, side21)
        const o2 = orientation(side11, side12, side22)
        const o3 = orientation(side21, side22, side11)
        const o4 = orientation(side21, side22, side12)

        if (o1 != o2 && o3 != o4) {
            return true
        }
        if (o1 == 0 && onSegment(side11, side21, side12)) {
            return true
        }
        if (o2 == 0 && onSegment(side11, side22, side12)) {
            return true
        }
        if (o3 == 0 && onSegment(side21, side11, side22)) {
            return true
        }
        if (o4 == 0 && onSegment(side21, side12, side22)) {
            return true
        }
    }

    function isInside(polygon: number[][], n: number, point: number[]) {
        if (n < 3) {
            return false
        }
        const border = [1000, point[y]]

        let count = 0
        let tip = 0
        do {
            const next = (tip + 1) % n
            if (doIntersect(polygon[tip], polygon[next], point, border)) {
                if (orientation(polygon[tip], point, polygon[next]) === 0) {
                    return onSegment(polygon[tip], point, polygon[next])
                }
                ++count
            }
            tip = next
        } while (tip !== 0)
        return count % 2 === 1
    }

    return eventPolygons.some(eventPolygon => {
        return eventPolygon.coordinates.some(polygon => {
            const sides = polygon.length - 1
            return newPolygons.some(nPolygon => {
                const sides1 = nPolygon.length - 1
                if (isInside(polygon, polygon.length, nPolygon[0])) return true
                if (isInside(nPolygon, nPolygon.length, polygon[0])) return true
                if (
                    doIntersect(
                        polygon[0],
                        polygon[sides],
                        nPolygon[0],
                        nPolygon[sides1]
                    )
                ) {
                    return true
                }
                for (let point = 0; point < sides; point++) {
                    for (let point1 = 0; point1 < sides1; point1++) {
                        if (
                            doIntersect(
                                polygon[point],
                                polygon[point + 1],
                                nPolygon[point1],
                                nPolygon[point1 + 1]
                            )
                        ) {
                            return true
                        }
                    }
                }
            })
        })
    })
}
