import React, { FC } from 'react'
import AboutMember from 'Components/AboutMember/AboutMember'
import './AboutMembers.scss'

const members = [
    {
        src: process.env.PUBLIC_URL + '/assets/image/about/members/anna.png',
        alt: 'member 1',
        name: 'Anna Tadevosyan',
        job: `Senior Product Owner`,
        desc: 'Advisor in Ecotourism and Healthy Lifestyle 8+ International Eco Programs Designed and Delivered',
    },
    {
        src: process.env.PUBLIC_URL + '/assets/image/about/members/edik.png',
        alt: 'member 2',
        name: 'Edik Baghdasaryan',
        job: 'Co-Founder & CTO',
        desc: '5+ years of background in Software Engineering',
    },
    {
        src: process.env.PUBLIC_URL + '/assets/image/about/members/artur.png',
        alt: 'member 3',
        name: 'Artur Aloyan',
        job: 'President and CBDO',
        desc: 'Start-up track record in the industry',
    },
]

const AboutMembers: FC = (): JSX.Element => {
    return (
        <div className="about-members">
            <div className="about-members-header text-center w-50 mx-auto">
                <h2 className="about-members-title text-uppercase mb-2">
                    team members
                </h2>
                <h3 className="about-members-subtitle text-capitalize">
                    professional staff ready to help your business
                </h3>
            </div>
            <div className="about-members-content">
                {members.map((member, index) => (
                    <AboutMember
                        key={index}
                        src={member.src}
                        alt={member.alt}
                        job={member.job}
                        desc={member.desc}
                        name={member.name}
                    />
                ))}
            </div>
        </div>
    )
}

export default AboutMembers
