export const MODAL_TYPE = {
    PUBLIC: 'public',
    PROFILE: 'profile',
    ADMIN: 'admin',
}

export const FETCHED_EVENT = {
    CUSTOM: 'custom',
    APPROVE: 'approvedEvents',
    PENDING: 'pending',
    CREATE: 'create',
}
