import { RootState } from 'context/ReduxStore/Store'
import { TypedUseSelectorHook, useSelector } from 'react-redux'

export enum ActionTypes {
    CREATED_EVENTS = 'user/CREATED EVENTS',
    APPROVED_EVENTS = 'user/APPROVED EVENTS',
    EVENTS_PENDING_TO_START = 'user/EVENTS PENDING TO START',
    IN_PROGRESS_EVENTS = 'user/IN PROGRESS EVENTS',
    EVENTS_PENDING_TO_FINISH = 'user/EVENTS PENDING TO FINISH',
    FINISHED_EVENTS = 'user/FINISHED EVENTS',
    CLEAR_TAB = '',
}

export enum ActionEventTypes {
    EVENTS = 'EVENTS',
    IN_PROGRESS_EVENTS = 'IN PROGRESS EVENTS',
    FINISHED_EVENTS = 'FINISHED EVENTS',
    CLEAR = '',
}

export enum ProfileActionTypes {
    MY_EVENTS = 'My events',
    EDIT_PROFILE = 'Edit profile',
    LOGOUT = 'Logout',
    CLEAR_SECTION = '',
}

export type ActiveState = {
    activeTabState: string
}

export type ProfileActiveSection = {
    activeSection: string
}

export type ActivePublicState = {
    activePublic: string
}

export type ActiveAction = {
    type: string | null
    payload?: any
}

export const useTypedSelector: TypedUseSelectorHook<RootState> = useSelector
