import React, { FC, useContext } from 'react'
import { EventListType } from 'utils/types'
import { MODAL_TYPE } from 'utils/modalTypes'
import { LoadingContext } from 'context/LoadingContext'
import { EventCard } from 'Components/EventCard/eventCards'
import './forAllEvents.scss'

export const UserEvent: FC<EventListType> = ({ data, info }): JSX.Element => {
    const { isLoading } = useContext(LoadingContext)

    return (
        <div className="optional-profile-info">
            {isLoading ? (
                <div className="spinner-loading">
                    <div className="spinner-border text-success" role="status">
                        <span className="sr-only">Loading...</span>
                    </div>
                </div>
            ) : data?.length ? (
                <div className="optional">
                    {data?.map((item, index) => (
                        <EventCard
                            info={info}
                            key={index}
                            event={item}
                            category={MODAL_TYPE.PROFILE}
                        />
                    ))}
                </div>
            ) : (
                <div className="spinner-loading">
                    <p>You have no events here</p>
                </div>
            )}
        </div>
    )
}
