import React, { FC } from 'react'
import Stats from 'Components/Stats/Stats'
import Steps from 'Components/Steps/Steps'
import ChooseUs from 'Components/ChooseUs/ChooseUs'
import Carousel from 'Components/Carousel/Carousel'
// import Feedbacks from 'Components/Feedbacks/Feedbacks'
import './HomePage.scss'

const Home: FC = (): JSX.Element => {
    return (
        <>
            <div className="carousel-section">
                <Carousel />
            </div>
            <div className="home">
                <Stats />
                <ChooseUs />
                <Steps />
                {/* <Feedbacks /> */}
            </div>
        </>
    )
}

export default Home
