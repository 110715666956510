import React, { FC, useEffect, useState, useContext } from 'react'
import { UserEvent } from './userEvent'
import { api } from 'utils/axiosService'
import { LoadingContext } from 'context/LoadingContext'
import './forAllEvents.scss'

export const DoneEvents: FC = (): JSX.Element => {
    const [eventData, setEventData] = useState()
    const { setIsLoading } = useContext(LoadingContext)

    useEffect(() => {
        setIsLoading(true)
        api.get(`user/users-done-events`)
            .then(result => {
                setEventData(result.data)
                setIsLoading(false)
            })
            .catch(e => {
                const error = e.response.data.message.errors[0]
                console.log(error.msg)
                setIsLoading(false)
            })
    }, [])

    return <UserEvent data={eventData} />
}
