import React, { FC, useContext, useEffect, useState } from 'react'
import { UserEvent } from './userEvent'
import { FETCHED_EVENT } from 'utils/modalTypes'
import { api } from 'utils/axiosService'
import { EventContext } from 'context/EventContext'
import { LoadingContext } from 'context/LoadingContext'
import './forAllEvents.scss'

export const ApprovedEvents: FC = (): JSX.Element => {
    const [eventData, setEventData] = useState()
    const { setIsLoading } = useContext(LoadingContext)
    const { stateIsChange } = useContext(EventContext)

    useEffect(() => {
        setIsLoading(true)
        api.get(`user/users-approved-events`)
            .then(result => {
                setEventData(result.data)
                setIsLoading(false)
            })
            .catch(e => {
                const error = e.response.data.message.errors[0]
                console.log(error.msg)
                setIsLoading(false)
            })
    }, [stateIsChange])

    return <UserEvent data={eventData} info={FETCHED_EVENT.APPROVE} />
}
