import React, { FC, useContext, useEffect, useState } from 'react'
import { api } from 'utils/axiosService'
import { EventContext } from 'context/EventContext'
import { LoadingContext } from 'context/LoadingContext'
import { EventList } from 'Components/EventList/eventList'

export const FetchedDataNew: FC = (): JSX.Element => {
    const [approvedEventData, setApprovedEventData] = useState()
    const { stateIsChange } = useContext(EventContext)
    const { setIsLoading } = useContext(LoadingContext)

    useEffect(() => {
        setIsLoading(true)
        api.get(`public/public-approved-events`)
            .then(result => {
                setApprovedEventData(result.data)
                setIsLoading(false)
            })
            .catch(e => {
                const error = e.response.data.message.errors[0]
                console.log(error.msg)
                setIsLoading(false)
            })
    }, [stateIsChange])

    return <EventList data={approvedEventData} subject="Approved Events" />
}
