import React, { FC, useState, useEffect, useContext } from 'react'
import { api } from 'utils/axiosService'
import { LoadingContext } from 'context/LoadingContext'
import { EventList } from 'Components/EventList/eventList'

export const FetchedDataProgress: FC = (): JSX.Element => {
    const [inProgressEventData, setInProgressEventData] = useState()
    const { setIsLoading } = useContext(LoadingContext)

    useEffect(() => {
        setIsLoading(true)
        api.get(`public/public-in-progress-events`)
            .then(result => {
                setInProgressEventData(result.data)
                setIsLoading(false)
            })
            .catch(e => {
                const error = e.response.data.message.errors[0]
                console.log(error.msg)
                setIsLoading(false)
            })
    }, [])

    return <EventList data={inProgressEventData} subject="Events In Progress" />
}
