import React, {
    FC,
    ChangeEvent,
    useContext,
    useState,
    useEffect,
    useRef,
} from 'react'
import { toast } from 'react-toastify'
import { api } from 'utils/axiosService'
import { AuthContext } from 'context/AuthContext'
import { SignUpValidationSchema } from 'utils/validation'
import { Formik, Form, Field, ErrorMessage } from 'formik'
import { Button, FormGroup, Label, Input } from 'reactstrap'
import { LazyLoadImage } from 'react-lazy-load-image-component'
import '../../globalStyles/chooseFile.scss'

const SignUpModal: FC = (): JSX.Element => {
    const [isDisable, setIsDisable] = useState(false)
    const [user, setUser] = useState({
        name: '',
        email: '',
        password: '',
        age: '',
    })

    const [file, setFile] = useState<File[]>([])

    const [imageUrl, setImageUrl] = useState({
        url: '',
        name: '',
    })

    const inputRef = useRef(null)
    const input = inputRef.current as unknown as HTMLInputElement

    const { isLoginActive, setIsLoginActive } = useContext(AuthContext)

    const combinedData = {
        ...user,
        file,
    }

    useEffect(() => {
        setFile([])
        setImageUrl({
            url: '',
            name: '',
        })

        setUser({
            name: '',
            email: '',
            password: '',
            age: '',
        })
    }, [])

    const handleChangeFile = (e: ChangeEvent<HTMLInputElement>) => {
        if (e.target.files !== null && e.target.files.length === 1) {
            const currentFile = e.target.files[0]
            const newImageUrls = URL.createObjectURL(currentFile)
            setImageUrl({ name: currentFile.name, url: newImageUrls })
            setFile(prevState => [...prevState, currentFile])
        }
    }

    const clearImage = () => {
        setFile([])
        setImageUrl({
            url: '',
            name: '',
        })

        input.value = ''
    }

    const handleChange = (e: ChangeEvent<HTMLInputElement>) => {
        setUser({ ...user, [e.target.name]: e.target.value })
    }

    const handleRegister = async () => {
        setIsDisable(true)
        const formData = new FormData()
        const keys = Object.keys(user) as Array<keyof typeof user>
        keys.map(key => formData.append(key, user[key]))
        formData.append('file', file[file.length - 1])

        try {
            const res = await api.post(`user/registration`, formData)

            toast.success(res.data.message)
            setIsDisable(false)
            setIsLoginActive(!isLoginActive)
        } catch (error: any) {
            setIsDisable(false)
            if (error.response.data.message.errors) {
                const errors = error.response.data.message.errors
                errors.map((err: any) => {
                    toast.error(err.msg)
                })
            } else if (error.response.data) {
                toast.error(error.response.data.message)
            } else {
                toast.error(error.message)
            }

            return error
        }
    }

    return (
        <>
            <div className="auth-section-bg auth-section-bg--signup col-lg-6"></div>
            <div className="auth-section auth-section--signup col-lg-6">
                <div className="auth-section-inner d-flex flex-column">
                    <div className="auth-text">
                        <p>Have an account?</p>
                        <button
                            onClick={e => {
                                e.preventDefault()
                                setIsLoginActive(!isLoginActive)
                            }}
                            className="auth-page-change-link"
                        >
                            Sign in
                        </button>
                    </div>
                    <div className="auth-section-content">
                        <div className="auth-section-header text-center">
                            <h2 className="auth-section-title text-uppercase">
                                Welcome to be green
                            </h2>
                        </div>
                    </div>
                    <div className="auth-section-form">
                        <Formik
                            initialValues={combinedData}
                            onSubmit={handleRegister}
                            validationSchema={SignUpValidationSchema}
                            enableReinitialize
                        >
                            {formik => (
                                <Form>
                                    <FormGroup>
                                        <Label>Name</Label>
                                        <Input
                                            tag={Field}
                                            className={`form-control ${
                                                formik.errors.name &&
                                                formik.touched.name
                                                    ? 'is-invalid'
                                                    : ''
                                            }`}
                                            component="input"
                                            type="text"
                                            name="name"
                                            onChange={handleChange}
                                        />
                                        <ErrorMessage
                                            name="name"
                                            component="div"
                                            className="invalid-feedback"
                                        />
                                    </FormGroup>
                                    <FormGroup>
                                        <Label>Email</Label>
                                        <Input
                                            tag={Field}
                                            className={`form-control ${
                                                formik.errors.email &&
                                                formik.touched.email
                                                    ? 'is-invalid'
                                                    : ''
                                            }`}
                                            component="input"
                                            type="text"
                                            name="email"
                                            onChange={handleChange}
                                        />
                                        <ErrorMessage
                                            name="email"
                                            component="div"
                                            className="invalid-feedback"
                                        />
                                    </FormGroup>
                                    <FormGroup>
                                        <Label>Password</Label>
                                        <Input
                                            tag={Field}
                                            className={`form-control ${
                                                formik.errors.password &&
                                                formik.touched.password
                                                    ? 'is-invalid'
                                                    : ''
                                            }`}
                                            component="input"
                                            type="password"
                                            name="password"
                                            onChange={handleChange}
                                        />
                                        <ErrorMessage
                                            name="password"
                                            component="div"
                                            className="invalid-feedback"
                                        />
                                    </FormGroup>
                                    <FormGroup>
                                        <Label>Age</Label>
                                        <Input
                                            tag={Field}
                                            className={`form-control ${
                                                formik.errors.age &&
                                                formik.touched.age
                                                    ? 'is-invalid'
                                                    : ''
                                            }`}
                                            component="input"
                                            type="number"
                                            name="age"
                                            onChange={handleChange}
                                        />
                                        <ErrorMessage
                                            name="age"
                                            component="div"
                                            className="invalid-feedback"
                                        />
                                    </FormGroup>
                                    <FormGroup>
                                        <Label>Image</Label>
                                        <Input
                                            multiple
                                            type="file"
                                            name="file"
                                            className={`choose-file ${
                                                formik.errors.file &&
                                                formik.touched.file
                                                    ? 'is-invalid'
                                                    : 'choose-file'
                                            }`}
                                            accept="image/*"
                                            innerRef={inputRef}
                                            onChange={handleChangeFile}
                                        />
                                        {imageUrl.name && (
                                            <LazyLoadImage
                                                src={imageUrl.url}
                                                key={imageUrl.url}
                                                data-name={imageUrl.name}
                                                onClick={clearImage}
                                                className="attached-file"
                                            />
                                        )}
                                        <ErrorMessage
                                            name="file"
                                            component="div"
                                            className="invalid-feedback"
                                        />
                                    </FormGroup>
                                    <Button
                                        disabled={isDisable}
                                        type="submit"
                                        className="custom-btn auth-form-btn btn btn-lg"
                                    >
                                        Sign up
                                    </Button>
                                </Form>
                            )}
                        </Formik>
                    </div>
                </div>
            </div>
        </>
    )
}

export default SignUpModal
