import React, { FC, useEffect, useState } from 'react'
import moment from 'moment'
import { EventCardType } from 'utils/types'
import { MODAL_TYPE } from 'utils/modalTypes'
import ProductModal from 'Components/ProductModal/productModal'
import AdminApproveModal from 'Components/AdminApproveModal/AdminApproveModal'
import { UserEventModal } from '../UserProfileCard/MyEvents/MyFetchedEvents/userEventModal'
import '../EventCard/eventCard.scss'

export const EventCard: FC<EventCardType> = (props): JSX.Element => {
    const {
        connect,
        info,
        category,
        appear,
        event,
        path,
        subject,
        newpolygons,
        eventpolygons,
    } = props
    const { description, title, deadline, status, image } = event
    const [modalShow, setModalShow] = useState(false)
    const [palette, setPallete] = useState('warning')

    const backGroundImage = image[0]
        ? image[0]
        : 'https://images.unsplash.com/photo-1578944032637-f09897c5233d?ixlib=rb-1.2.1&q=80&fm=jpg&crop=entropy&cs=tinysrgb&w=400&fit=max&ixid=eyJhcHBfaWQiOjE0NTg5fQ'

    useEffect(() => {
        switch (status) {
            case 'done':
                setPallete('success')
                break
            case 'rejected':
                setPallete('danger')
                break
            case 'finished':
                setPallete('success')
                break
            default:
                setPallete('warning')
        }
    }, [])

    const setModal = () => {
        switch (category) {
            case MODAL_TYPE.PROFILE:
                return (
                    <UserEventModal
                        info={info}
                        show={modalShow}
                        palette={palette}
                        event={event}
                        onHide={(): void => setModalShow(false)}
                    />
                )
            case MODAL_TYPE.ADMIN:
                return (
                    <AdminApproveModal
                        subject={subject}
                        appear={appear}
                        show={modalShow}
                        path={path}
                        palette={palette}
                        event={event}
                        category={MODAL_TYPE.ADMIN}
                        newpolygons={newpolygons}
                        eventpolygons={eventpolygons}
                        onHide={(): void => setModalShow(false)}
                    />
                )
            case MODAL_TYPE.PUBLIC:
                return (
                    <ProductModal
                        show={modalShow}
                        palette={palette}
                        connect={connect}
                        event={event}
                        toggle={modalShow ? 1 : 0}
                        onHide={(): void => setModalShow(false)}
                    />
                )

            default:
                break
        }
    }

    return (
        <ul>
            <li className="booking-card">
                <img src={backGroundImage} alt="" className="card-img" />

                <div className="book-container">
                    <div className="content">
                        <button
                            className="btn"
                            onClick={(): void => setModalShow(true)}
                        >
                            Show more
                        </button>
                    </div>
                </div>
                {setModal()}
                <div
                    className={`informations-container ${
                        status === 'rejected'
                            ? 'informations-container-reject'
                            : ''
                    }`}
                >
                    <h2 className="title text-break">{title}</h2>
                    <p className="sub-title">{moment(deadline).format('ll')}</p>
                    <div className="more-information">
                        <p className="disclaimer text-break">{description}</p>
                    </div>
                </div>
            </li>
        </ul>
    )
}
