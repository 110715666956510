import React, { createContext, useState } from 'react'
import type { EventContextProps, EventContextProviderProps } from 'utils/types'

export const EventContext = createContext<EventContextProps>(
    {} as EventContextProps
)

export const EventContextProvider = ({
    children,
}: EventContextProviderProps): JSX.Element => {
    const [stateIsChange, setStateIsChange] = useState<boolean>(true)

    return (
        <EventContext.Provider
            value={{
                stateIsChange,
                setStateIsChange,
            }}
        >
            <> {children}</>
        </EventContext.Provider>
    )
}
