import React, { useContext, useEffect, useState } from 'react'
import moment from 'moment'
import { toast } from 'react-toastify'
import { Badge, Modal, Table } from 'react-bootstrap'
import { api } from 'utils/axiosService'
import { EventContext } from 'context/EventContext'
import { EventCardType, ModalProp } from 'utils/types'
import { CreatedBy } from 'Components/CreatedBy/CreatedBy'
import { faMessage } from '@fortawesome/free-solid-svg-icons'
import { LazyLoadImage } from 'react-lazy-load-image-component'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { ImageSlider } from 'Components/ImageSlider/imageSlider'
import { Subscriebers } from 'Components/Subscriebers/subscriebers'
import { DescriptionTooltip } from 'Components/DescriptionTooltip/tooltip'
import './AdminApproveModal.scss'
import EventCardMap from '../EventMap/eventCardMap'
import { isOverlap } from 'utils/helpers'

const ApproveModal = (props: EventCardType & ModalProp): JSX.Element => {
    const {
        event,
        path,
        appear,
        palette,
        subject,
        eventpolygons,
        newpolygons,
        onHide,
    } = props
    const {
        _id,
        name,
        email,
        image,
        title,
        status,
        deadline,
        subscribers,
        authorImage,
        description,
        authorStatus,
        commentByAdmin,
    } = event

    const [url, setUrl] = useState('')

    const [currentImageSrc, setCurrentImageSrc] = useState(image[0])
    const { stateIsChange, setStateIsChange } = useContext(EventContext)
    const [comment, setComment] = useState('')
    const [blur, setBlur] = useState(false)

    useEffect(() => {
        setBlur(false)
        setCurrentImageSrc(image[0])
    }, [onHide])

    useEffect(() => {
        if (subject === 'Created Events') {
            setUrl(`map/created-event-location/${_id}`)
        } else {
            setUrl(`map/approved-location/${_id}`)
        }
    }, [])

    useEffect(() => {
        newpolygons?.map(polygon => {
            if (polygon.eventId === _id) {
                if (
                    eventpolygons &&
                    isOverlap(eventpolygons, polygon.coordinates)
                ) {
                    setComment(
                        'Another person applyed to turn this area green earlyer'
                    )
                } else {
                    setComment('')
                }
            }
        })
    }, [eventpolygons, newpolygons])

    const handleComment = (e: React.ChangeEvent<HTMLTextAreaElement>) => {
        setComment(e.target.value)
    }

    const changeCurrentImage = (e: React.MouseEvent) => {
        const selectedImage = e.target as HTMLInputElement
        setCurrentImageSrc(selectedImage.src)
    }

    const handleApproveFunction = (
        e: React.MouseEvent<HTMLButtonElement>
    ): void => {
        e.preventDefault()
        api.post(`${path?.approve.path}`, { eventId: _id })
            .then(() => {
                toast.success(`${path?.approve.message}`)
                setStateIsChange(!stateIsChange)
            })
            .catch(err => {
                const error = err.response.data.message.errors[0]
                console.log(error.msg)
            })
    }

    const handleRejectFunction = (
        e: React.MouseEvent<HTMLButtonElement>
    ): void => {
        e.preventDefault()
        api.put(`${path?.reject.path}`, {
            eventId: _id,
            commentByAdmin: comment,
        })
            .then(() => {
                toast.success(`${path?.reject.message}`)
                setStateIsChange(!stateIsChange)
            })
            .catch(err => {
                const error = err.response.data.message.errors[0]
                console.log(error.msg)
            })
    }

    return (
        <Modal
            {...props}
            size="lg"
            aria-labelledby="contained-modal-title-vcenter"
            centered
            className={'event-modal' + (blur ? ' modal-blur' : '')}
        >
            <Modal.Header
                closeButton
                closeVariant="white"
                className="event-modal-header"
            >
                <Modal.Title
                    id="contained-modal-title-vcenter"
                    className="event-modal-title text-center text-break"
                >
                    {title}
                </Modal.Title>
            </Modal.Header>
            <Modal.Body className="event-modal-body text-center">
                <div className="event-modal-info">
                    <CreatedBy
                        name={name}
                        email={email}
                        authorImage={authorImage}
                        authorStatus={authorStatus}
                    />
                    <div className="event-modal-desc">
                        <h4 className="mb-4">Description</h4>
                        <DescriptionTooltip
                            description={description}
                            makeBlur={() => setBlur(!blur)}
                            blurState={blur}
                        />
                    </div>

                    {!!image.length && (
                        <ImageSlider
                            imgArray={image}
                            changeCurrentImage={changeCurrentImage}
                            currentImageSrc={currentImageSrc}
                        ></ImageSlider>
                    )}

                    <EventCardMap url={url} />

                    <div className="event-modal-subscr">
                        <h4 className="mb-4">Subscribers</h4>

                        {subscribers?.length ? (
                            <Table className="subscribe-table" hover bordered>
                                <thead>
                                    <tr>
                                        <th>Name</th>
                                        <th>Email</th>
                                        <th>Description</th>
                                        <th>Status</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {subscribers?.map((subscriber, index) => (
                                        <Subscriebers
                                            key={index}
                                            name={subscriber.name}
                                            email={subscriber.email}
                                            status={subscriber.status}
                                            description={subscriber.description}
                                        />
                                    ))}
                                </tbody>
                            </Table>
                        ) : (
                            <p>No subscribers yet</p>
                        )}
                    </div>
                    {commentByAdmin && (
                        <div className="comment-container">
                            <span className="comment-icon">
                                <FontAwesomeIcon icon={faMessage} />
                            </span>
                            <span className="text-break" id="text-justify">
                                {commentByAdmin}
                            </span>
                        </div>
                    )}
                </div>

                {!!appear && (
                    <div>
                        {status !== 'rejected' && (
                            <div className="admin-btns d-flex justify-content-end">
                                <textarea
                                    rows={1}
                                    cols={50}
                                    name="comment"
                                    value={comment}
                                    onChange={handleComment}
                                />
                                {subject === 'Events Pending To Finish' ? (
                                    <button
                                        className="btn btn-sm admin-btn admin-btn-reject"
                                        onClick={handleRejectFunction}
                                        disabled={
                                            comment.trim().length > 0
                                                ? false
                                                : true
                                        }
                                    >
                                        Discard
                                    </button>
                                ) : (
                                    <button
                                        className="btn btn-sm admin-btn admin-btn-reject"
                                        onClick={handleRejectFunction}
                                        disabled={
                                            comment.trim().length > 0
                                                ? false
                                                : true
                                        }
                                    >
                                        Reject
                                    </button>
                                )}
                                <button
                                    className="btn btn-sm admin-btn custom-btn"
                                    onClick={handleApproveFunction}
                                    disabled={comment.trim().length > 0}
                                >
                                    Approve
                                </button>
                            </div>
                        )}
                    </div>
                )}
            </Modal.Body>
            <Modal.Footer className="d-flex justify-content-between event-modal-footer">
                {' '}
                <div className="event-modal-footer-content">
                    <div className="d-flex align-items-center event-modal-footer-info">
                        <strong>Deadline:</strong>
                        <span className="event-modal-deadline">
                            {moment(deadline).format('ll')}
                        </span>
                    </div>
                    {status && (
                        <div className="d-flex align-items-center event-modal-footer-info">
                            {' '}
                            <strong>Status:</strong>
                            <Badge
                                className="text-capitalize"
                                bg={`${palette}`}
                            >
                                {status}
                            </Badge>
                        </div>
                    )}
                </div>
                <LazyLoadImage src="assets/logo.png" alt="be green logo" />
            </Modal.Footer>
        </Modal>
    )
}

export default ApproveModal
