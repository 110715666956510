import {
    faCheckSquare,
    faSpinner,
    faUsers,
    faLeaf,
} from '@fortawesome/free-solid-svg-icons'
import React, { FC, useEffect, useState } from 'react'
import { api } from 'utils/axiosService'
import StatsItem from './StatsItem'
import './Stats.scss'

const Stats: FC = (): JSX.Element => {
    const [statistics, setStatistics] = useState<{ [key: string]: number }>({})
    useEffect(() => {
        api.get(`public/public-statistics`)
            .then(result => {
                setStatistics(result.data)
            })
            .catch(e => {
                const error = e.response.data.message.errors[0]
                console.log(error.msg)
            })
    }, [])

    const stats = [
        {
            icon: faLeaf,
            title: 'Events',
            start: 0,
            end: statistics.activeEvents,
        },
        {
            icon: faSpinner,
            title: 'progress',
            start: 0,
            end: statistics.worksInProgress,
        },
        {
            icon: faCheckSquare,
            title: 'Done',
            start: 0,
            end: statistics.worksDone,
        },
        {
            icon: faUsers,
            title: 'Users',
            start: 0,
            end: statistics.totalUsers,
        },
    ]

    return (
        <div className="stats-section text-center text-light">
            <div className="container">
                <div className="stats-row row justify-content-center">
                    {stats.map((stat, index) => (
                        <StatsItem
                            key={index}
                            icon={stat.icon}
                            title={stat.title}
                            start={stat.start}
                            end={stat.end}
                        />
                    ))}
                </div>
            </div>
        </div>
    )
}

export default Stats
