import React, { FC, useContext, useEffect, useState } from 'react'
import { Link, useLocation } from 'react-router-dom'
import { api } from 'utils/axiosService'
import { AuthContext } from 'context/AuthContext'
import AuthModal from 'Components/Modals/AuthModal'
import { LoadingContext } from 'context/LoadingContext'
import './emailVerify.scss'

export const EmailVerify: FC = (): JSX.Element => {
    const location = useLocation()
    const [error, setError] = useState('')
    const { toggle } = useContext(AuthContext)
    const { isLoading, setIsLoading } = useContext(LoadingContext)

    useEffect(() => {
        setIsLoading(true)
        api.get(`user/mail-verification/${location.search}`)
            .then(() => {
                setIsLoading(false)
            })
            .catch(err => {
                setError(err.response.data)
                setIsLoading(false)
            })
    }, [])

    return (
        <div className="verify">
            {isLoading ? (
                <div className="spinner-loading">
                    <div
                        className="spinner-border text-success"
                        role="status"
                    />
                </div>
            ) : error === 'This link has expired' ? (
                <div className="expired-container main">
                    <h2>You have already been verificated</h2>
                    <Link className="header-profile-link" to="/">
                        <button className="btn btn-secondary">
                            Go to the main page
                        </button>
                    </Link>
                </div>
            ) : error ? (
                <div className="expired-container main">
                    <h2>Something went wrong</h2>
                    <Link className="header-profile-link" to="/">
                        <button className="btn btn-secondary">Try again</button>
                    </Link>
                </div>
            ) : (
                <div className="mail-verif-container main">
                    <h2>You have successfully registered</h2>
                    <Link className="header-profile-link" to="/home">
                        <button className="btn btn-light" onClick={toggle}>
                            Continue
                        </button>
                    </Link>
                    <AuthModal />
                </div>
            )}
        </div>
    )
}
