import React, { FC, useContext, useEffect, useState } from 'react'
import { EventListType } from 'utils/types'
import { MODAL_TYPE } from 'utils/modalTypes'
import { AuthContext } from 'context/AuthContext'
import { LoadingContext } from 'context/LoadingContext'
import { EventCard } from 'Components/EventCard/eventCards'
import '../EventList/eventList.scss'

export const EventList: FC<EventListType> = ({
    path,
    data,
    subject,
    newpolygons,
    eventpolygons,
}): JSX.Element => {
    const [appear, setAppear] = useState<number>(1)
    const { currentUser } = useContext(AuthContext)
    const { isLoading } = useContext(LoadingContext)

    useEffect(() => {
        if (
            subject === 'Approved Events' ||
            subject === 'Events In Progress' ||
            subject === 'Finished Events'
        ) {
            setAppear(0)
        }
    }, [])

    const isJoinPossible = subject === 'Approved Events' ? 1 : 0

    return (
        <div>
            {isLoading ? (
                <div className="spinner-loading">
                    <div className="spinner-border text-success" role="status">
                        <span className="sr-only">Loading...</span>
                    </div>
                </div>
            ) : data?.length ? (
                <div className="event-cards-container">
                    {data?.map(event => (
                        <EventCard
                            subject={subject}
                            category={
                                currentUser.role === 999
                                    ? MODAL_TYPE.ADMIN
                                    : MODAL_TYPE.PUBLIC
                            }
                            path={path}
                            event={event}
                            key={event._id}
                            appear={appear}
                            connect={isJoinPossible}
                            newpolygons={newpolygons}
                            eventpolygons={eventpolygons}
                        />
                    ))}
                </div>
            ) : (
                <div className="spinner-loading">
                    <p>No available data</p>
                </div>
            )}
        </div>
    )
}
