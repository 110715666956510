import storage from 'redux-persist/lib/storage'
import { combineReducers, createStore } from 'redux'
import { persistStore, persistReducer } from 'redux-persist'
import { ActiveTabReducer } from './Reducers/ActiveTabReducer'
import { ProfileActiveSectionReducer } from './Reducers/EventReducer'
import { ActivetabPublicReducer } from './Reducers/ActiveTabPublicReducer'

const persistConfig = {
    key: 'root',
    storage,
}

const rootReducer = combineReducers({
    activeTab: ActiveTabReducer,
    activePublicTab: ActivetabPublicReducer,
    profileActiveSection: ProfileActiveSectionReducer,
})

export type RootState = ReturnType<typeof rootReducer>

const persistedReducer = persistReducer(persistConfig, rootReducer)

export const store = createStore(persistedReducer)
export const persistor = persistStore(store)
