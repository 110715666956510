import { AuthContext } from 'context/AuthContext'
import React, { FC, useContext } from 'react'

export const UserBadge: FC = (): JSX.Element => {
    const { currentUser } = useContext(AuthContext)
    const point = Number(currentUser.points)

    return (
        <div>
            {point >= 10 && (
                <div className="bagde-icon">
                    {point < 20 ? (
                        <img
                            src={
                                process.env.PUBLIC_URL +
                                '/assets/image/badges/bronze.jpg'
                            }
                        />
                    ) : point < 30 ? (
                        <img
                            src={
                                process.env.PUBLIC_URL +
                                '/assets/image/badges/silver.jpg'
                            }
                        />
                    ) : (
                        <img
                            src={
                                process.env.PUBLIC_URL +
                                '/assets/image/badges/gold.jpg'
                            }
                        />
                    )}
                </div>
            )}
        </div>
    )
}
