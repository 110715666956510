import React, { FC } from 'react'
import { MapContainer, Marker, TileLayer } from 'react-leaflet'
import { mapSettings, officeLocationMarker } from '../../utils/helpers'

const ContactsMap: FC = (): JSX.Element => {
    const mapCoordinates = { lat: 40.17170858744085, lng: 44.502739906311035 }

    return (
        <div className="contacts-map">
            <MapContainer center={mapCoordinates} zoom={mapSettings.zoomLevel}>
                <TileLayer
                    attribution={mapSettings.attribution}
                    url={mapSettings.mapUrl}
                />
                <Marker
                    icon={officeLocationMarker}
                    position={[mapCoordinates.lat, mapCoordinates.lng]}
                ></Marker>
            </MapContainer>
        </div>
    )
}

export default ContactsMap
