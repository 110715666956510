import React, { FC } from 'react'
import type { ChooseUsListItemProps } from 'utils/types'

const ChooseListItem: FC<ChooseUsListItemProps> = ({ text }): JSX.Element => {
    return (
        <div className="choose-us-list-item">
            <div className="choose-us-list-item-info">
                <p className="choose-us-list-item-desc">{text}</p>
            </div>
        </div>
    )
}

export default ChooseListItem
