import React, { FC, useContext } from 'react'
import { Link, useNavigate } from 'react-router-dom'
import { toast } from 'react-toastify'
import parse from 'html-react-parser'
import { AuthContext } from 'context/AuthContext'
import { SingleArticleType } from 'utils/types'
import { api } from 'utils/axiosService'

export const SingleArticle: FC<SingleArticleType> = ({ data }): JSX.Element => {
    const { currentUser } = useContext(AuthContext)
    const navigate = useNavigate()
    const {
        _id,
        name,
        position,
        authorImage,
        articleTitle,
        articleDescription,
        articleImage,
    } = data

    console.log(data)

    const handleDeleteArticle = (
        e: React.MouseEvent<HTMLButtonElement>
    ): void => {
        e.preventDefault()
        api.delete(`admin/delete-article/${_id}`)
            .then(response => {
                toast.success(response.data.message)
                navigate(-1)
            })
            .catch(err => {
                toast.error(err.response.data.message)
            })
    }

    return (
        <>
            {currentUser.role === 999 && (
                <div className="admin-logout-button-div">
                    <Link to="admin">
                        <button
                            className="admin-logout-button"
                            onClick={() => navigate(-1)}
                        >
                            Go back
                        </button>
                    </Link>
                </div>
            )}
            <div className="article-author">
                <div>
                    <img
                        className="author-image"
                        src={
                            authorImage
                                ? authorImage
                                : process.env.PUBLIC_URL +
                                  '/assets/image/user-profile-page/unnamed.jpg'
                        }
                        alt="article author"
                    />
                </div>
                <div className="author-info">
                    <h5>{name}</h5>
                    <span>{position}</span>
                </div>
            </div>
            <h2>{articleTitle}</h2>
            <div className="article-image-div">
                <img src={articleImage} alt="image" className="article-image" />
            </div>

            <p id="text-justify" className="text-break">
                {parse(articleDescription)}
            </p>
            {currentUser.role === 999 && (
                <button
                    className="admin-btn-reject admin-delete-btn-size"
                    onClick={handleDeleteArticle}
                >
                    Delete Article
                </button>
            )}
        </>
    )
}
