import React, { useContext, useEffect, useRef, useState } from 'react'
import { toast } from 'react-toastify'
import { FormGroup, Input, Label } from 'reactstrap'
import { Button, Form, Modal } from 'react-bootstrap'
import { api } from 'utils/axiosService'
import { AxiosErrMsgProp, EventModalProp } from 'utils/types'
import { EventContext } from 'context/EventContext'

export const FeedbackModal = (props: EventModalProp): JSX.Element => {
    const { show, onHide } = props
    const [file, setFile] = useState<File[]>([])
    const [imageUrl, setImageUrl] = useState({
        url: '',
        name: '',
    })
    const [feedbackData, setFeedbackData] = useState({
        name: '',
        position: '',
        feedback: '',
    })
    const { stateIsChange, setStateIsChange } = useContext(EventContext)
    const inputRef = useRef(null)
    const input = inputRef.current as unknown as HTMLInputElement

    const handleInputValue = (e: React.ChangeEvent<HTMLInputElement>): void => {
        setFeedbackData(prevState => {
            const key = e.target.name
            return { ...prevState, [key]: e.target.value }
        })
    }

    useEffect(() => {
        setFile([])
        setFeedbackData({ name: '', position: '', feedback: '' })
        setImageUrl({
            url: '',
            name: '',
        })
    }, [show])

    const handleChangeFile = (e: React.ChangeEvent<HTMLInputElement>) => {
        if (e.target.files !== null && e.target.files.length === 1) {
            const currentFile = e.target.files[0]
            const newImageUrls = URL.createObjectURL(currentFile)
            setImageUrl({ name: currentFile.name, url: newImageUrls })
            setFile(prevState => [...prevState, currentFile])
        }
    }

    const clearImage = () => {
        setFile([])
        setImageUrl({
            url: '',
            name: '',
        })

        input.value = ''
    }

    const feedbackFormData = new FormData()
    const keys = Object.keys(feedbackData) as Array<keyof typeof feedbackData>
    keys.forEach(key => {
        const value = feedbackData[key].trim()
        feedbackFormData.append(key, value)
    })
    feedbackFormData.append('file', file[file.length - 1])

    const addFeedback = () => {
        api.post(`admin/add-feedback`, feedbackFormData)
            .then(res => {
                onHide()
                setStateIsChange(!stateIsChange)
                setFeedbackData({ name: '', position: '', feedback: '' })
                setFile([])
                setImageUrl({
                    url: '',
                    name: '',
                })
                toast.success(res.data.message)
            })
            .catch(e => {
                const errorsArray = e.response.data.message.errors
                if (errorsArray) {
                    errorsArray.forEach((error: AxiosErrMsgProp) => {
                        toast.error(error.msg)
                    })
                } else {
                    toast.error(e.response.data.message)
                }
            })
    }

    return (
        <Modal className="modal-create" {...props}>
            <Modal.Header closeButton>
                <h4>Feedbacks from our clients</h4>
            </Modal.Header>
            <Modal.Body>
                <Form>
                    <FormGroup className="mb-3">
                        <Label>Name</Label>
                        <Input
                            type="text"
                            name="name"
                            onChange={handleInputValue}
                        />
                    </FormGroup>
                    <FormGroup className="mb-3">
                        <Label>Position</Label>
                        <Input
                            type="text"
                            name="position"
                            onChange={handleInputValue}
                        />
                    </FormGroup>
                    <FormGroup className="mb-3">
                        <Label>Feedback</Label>
                        <Input
                            type="textarea"
                            rows={5}
                            name="feedback"
                            onChange={handleInputValue}
                        />
                    </FormGroup>
                    <FormGroup className="mb-3">
                        <Input
                            multiple
                            type="file"
                            name="file"
                            className="choose-file"
                            accept="image/*"
                            innerRef={inputRef}
                            onChange={handleChangeFile}
                        />
                        {imageUrl.name && (
                            <img
                                src={imageUrl.url}
                                key={imageUrl.url}
                                data-name={imageUrl.name}
                                onClick={clearImage}
                                className="attached-file"
                            />
                        )}
                    </FormGroup>
                    <FormGroup className="d-flex justify-content-end">
                        <Button
                            className="event-create-btn custom-btn w-100"
                            onClick={() => addFeedback()}
                        >
                            Add
                        </Button>
                    </FormGroup>
                </Form>
            </Modal.Body>
        </Modal>
    )
}
