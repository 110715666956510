import React, { FC, useContext, useEffect, useState } from 'react'
import { Accordion } from 'react-bootstrap'
import { faMessage } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { api } from 'utils/axiosService'
import { EventCardType } from 'utils/types'
import { AuthContext } from 'context/AuthContext'
import { EventContext } from 'context/EventContext'
import { SubscribersTable } from './subscribersTable'
import './forAllEvents.scss'

export const EventInfoDone: FC<EventCardType> = (props): JSX.Element => {
    const { _id, authorId, subscribers, authorStatus, commentByAdmin } =
        props.event
    const [userStatus, setUserStatus] = useState<string | undefined>()
    const { currentUser } = useContext(AuthContext)
    const { stateIsChange, setStateIsChange } = useContext(EventContext)

    useEffect(() => {
        if (currentUser.id === authorId) {
            setUserStatus(authorStatus)
        } else {
            const currentSubscriber = subscribers?.filter(
                subscriber => subscriber.id === currentUser.id
            )
            setUserStatus(currentSubscriber?.[0].status)
        }
    }, [])

    const onSubmit = async (e: {
        preventDefault: () => void
    }): Promise<void> => {
        e.preventDefault()

        const finishedData = {
            eventId: _id,
        }

        try {
            await api.post(`user/done-event`, finishedData)

            if (props.onHide) {
                props.onHide()
            }

            setStateIsChange(!stateIsChange)
            setUserStatus('done')
        } catch (err) {
            console.log(err)
        }
    }

    return (
        <>
            <SubscribersTable event={props.event} />
            {commentByAdmin && (
                <Accordion>
                    <Accordion.Item eventKey="0">
                        <Accordion.Header>
                            <span>
                                <FontAwesomeIcon icon={faMessage} />
                            </span>
                            Message from admin
                        </Accordion.Header>
                        <Accordion.Body
                            className="text-break"
                            id="text-justify"
                        >
                            {commentByAdmin}
                        </Accordion.Body>
                    </Accordion.Item>
                </Accordion>
            )}
            <button
                type="submit"
                onClick={onSubmit}
                className="btn btn-primary w-100 event-modal-done-btn custom-btn"
                disabled={userStatus === 'done' ? true : false}
            >
                Done
            </button>
        </>
    )
}
