import React, { FC } from 'react'
import Intro from '../../Components/Intro/Intro'
import './MainPage.scss'

const MainPage: FC = (): JSX.Element => {
    return (
        <div className="main-page">
            <Intro />
        </div>
    )
}

export default MainPage
