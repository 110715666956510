import React, { FC } from 'react'
import {
    faPhone,
    faEnvelope,
    faLocationPin,
} from '@fortawesome/free-solid-svg-icons'
import ContactsAddressItem from 'Components/ContactsAddressItem/ContactsAddressItem'
import './ContactsAddressList.scss'

const contacts = [
    {
        icon: faLocationPin,
        title: 'Address',
        address: '11/12 Argishti St, Yerevan',
    },
    {
        icon: faPhone,
        title: 'Call us',
        address: '+ 374 55 64 54 37',
    },
    {
        icon: faEnvelope,
        title: 'Mail us',
        address: 'info@milies.net',
    },
]

const ContactsAddressList: FC = (): JSX.Element => {
    return (
        <div className="contacts-address-list">
            {contacts.map((contact, index) => (
                <ContactsAddressItem
                    key={index}
                    icon={contact.icon}
                    title={contact.title}
                    address={contact.address}
                />
            ))}
        </div>
    )
}

export default ContactsAddressList
