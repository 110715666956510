import React, { FC } from 'react'
import AboutSectionItem from 'Components/AboutSectionItem/AboutSectionItem'
import './AboutSection.scss'

const sections = [
    {
        alt: 'plan',
        src:
            process.env.PUBLIC_URL +
            '/assets/image/about/section/section-item-1.jpg',
        title: 'About us',
        subtitle: 'We appreciate Nature',
        text: `
        During live talks, we have asked our audiences a critical question: Why is nature so important to human beings? 
        Surprisingly, people have found it extremely hard to answer this fundamental question. 
        However, providing time to reflect on the question, allows deep, varied and certainly meaningful responses to flow in.To sum this up, Nature enhances our wellbeing and freely provides the essentials for our survival. `,
        order: '',
    },
    {
        alt: '2',
        src:
            process.env.PUBLIC_URL +
            '/assets/image/about/section/section-item-2.jpg',
        subtitle: 'Teambuilding initiations',
        text: `The importance of team building is that it improves the individual's and the organization's productivity. Because individuals work in teams, they can pick up on new skills and sharpen their existing skills. This improves team performance, makes them efficient and over time, more work gets completed in less time.As the saying goes, a chain is only as strong as its weakest link. Thus, a company is only as strong as the interactions of its members. The way teams work together reflects on the organization as a whole. Institutions that emphasize and prioritize teamwork ensure that the company performs well on every level. 
        When you focus on team building, your colleagues value teamwork. By putting effort into the practice, you give weight to the role of collaboration in the workplace. Respecting team bonding time puts your team in a position to draw the most benefit from the experience`,
        order: 'order-1',
    },
]

const AboutSection: FC = (): JSX.Element => {
    return (
        <div className="about-section">
            <div className="about-section-content">
                {sections.map((section, index) => (
                    <AboutSectionItem
                        key={index}
                        alt={section.alt}
                        src={section.src}
                        text={section.text}
                        title={section.title}
                        order={section.order}
                        subtitle={section.subtitle}
                    />
                ))}
            </div>
        </div>
    )
}

export default AboutSection
