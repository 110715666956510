import React, { FC, useContext } from 'react'
import { useNavigate } from 'react-router-dom'
import { toast } from 'react-toastify'
import { AuthContext } from 'context/AuthContext'
import { ArticleData } from 'Components/Articles/articleData'

const ArticlePage: FC = (): JSX.Element => {
    const { isAuth, setModal, currentUser } = useContext(AuthContext)
    const navigate = useNavigate()
    const points = Number(currentUser.points)
    const showToastify = () => {
        toast.error('Only users with gold status can create an article')
    }

    const checkPrivileges = () =>
        points > 30 ? navigate('add-article') : showToastify()

    return (
        <div className="container article">
            <div className="d-flex justify-content-end">
                <button
                    className="btn custom-btn"
                    onClick={() =>
                        isAuth ? checkPrivileges() : setModal(true)
                    }
                >
                    Add an article
                </button>
            </div>

            <h2 className="text-center fw-bold mb-5">Interesting To Know</h2>
            <ArticleData />
        </div>
    )
}

export default ArticlePage
