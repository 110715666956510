import React, { useContext } from 'react'
import { Button, Nav, Navbar } from 'react-bootstrap'
import { Link } from 'react-router-dom'
import '../burgerMenu/style.scss'
import 'bootstrap/dist/css/bootstrap.min.css'
import { AuthContext } from 'context/AuthContext'
import AuthModal from 'Components/Modals/AuthModal'

const BurgerMenu = (): JSX.Element => {
    const { toggle, isAuth, currentUser } = useContext(AuthContext)
    const links = [
        {
            className: 'link-light nav-link',
            path: 'home',
            name: 'Home',
        },
        {
            className: 'link-light nav-link',
            path: 'about',
            name: 'About',
        },
        {
            className: 'link-light nav-link',
            path: 'events',
            name: 'Events',
        },
        {
            className: 'link-light nav-link',
            path: 'our-partners',
            name: 'Our Partners',
        },
        {
            className: 'link-light nav-link',
            path: 'articles',
            name: 'Articles',
        },
        {
            className: 'link-light nav-link',
            path: 'contacts',
            name: 'Contacts',
        },
    ]

    return (
        <Navbar collapseOnSelect expand="lg">
            <Link className="navbar-brand link-secondary" to="/">
                <img
                    src={process.env.PUBLIC_URL + '/assets/logo.png'}
                    alt="be green logo"
                />
            </Link>
            {isAuth ? (
                <div className="header-profile">
                    <Link className="header-profile-link" to="profile">
                        <img
                            src={
                                currentUser.image
                                    ? currentUser.image
                                    : process.env.PUBLIC_URL +
                                      '/assets/image/user-profile-page/unnamed.jpg'
                            }
                            alt="user profile icon"
                        />
                    </Link>
                </div>
            ) : (
                <>
                    {' '}
                    <Button
                        onClick={toggle}
                        className="custom-btn auth-btn btn"
                    >
                        Sign in
                    </Button>
                    <AuthModal />
                </>
            )}
            <Navbar.Toggle aria-controls="responsive-navbar-nav" />
            <Navbar.Collapse
                id="responsive-navbar-nav"
                className="navbar-colapse"
            >
                {links.map((link, index) => (
                    <Nav.Link
                        key={index}
                        href={link.path}
                        className={link.className}
                    >
                        {link.name}
                    </Nav.Link>
                ))}
            </Navbar.Collapse>
        </Navbar>
    )
}

export default BurgerMenu
