import React, { FC, useEffect, useState, useContext } from 'react'
import { PathData } from 'utils/helpers'
import { api } from 'utils/axiosService'
import { EventContext } from 'context/EventContext'
import { LoadingContext } from 'context/LoadingContext'
import { EventList } from 'Components/EventList/eventList'

export const FetchedDataPendingToStart: FC = (): JSX.Element => {
    const [pendingToStartEventData, setPendingToStartEventData] = useState()
    const { setIsLoading } = useContext(LoadingContext)
    const { stateIsChange } = useContext(EventContext)

    useEffect(() => {
        setIsLoading(true)
        api.get(`admin/events-pending-to-start`)
            .then(result => {
                setPendingToStartEventData(result.data)
                setIsLoading(false)
            })
            .catch(e => {
                const error = e.response.data.message.errors[0]
                console.log(error.msg)
                setIsLoading(false)
            })
    }, [stateIsChange])

    return (
        <EventList
            data={pendingToStartEventData}
            subject="Events Pending To Start"
            path={PathData.applyEvent}
        />
    )
}
