import React, { FC, useContext, useEffect, useState } from 'react'
import { LoadingContext } from 'context/LoadingContext'
import { Articles } from './articles'
import { api } from 'utils/axiosService'

export const ArticleData: FC = (): JSX.Element => {
    const [articleData, setArticleData] = useState()
    const { setIsLoading } = useContext(LoadingContext)

    useEffect(() => {
        setIsLoading(true)
        api.get(`public/get-created-articles`)
            .then(result => {
                setArticleData(result.data)
                setIsLoading(false)
            })
            .catch(e => {
                const error = e.response.data.message.errors[0]
                console.log(error.msg)
                setIsLoading(false)
            })
    }, [])

    return <Articles articleData={articleData} />
}
