import React from 'react'
import { UserFields } from 'utils/types'
import './subscriebers.scss'

export const Subscriebers = (props: UserFields): JSX.Element => {
    const { name, email, description, status } = props

    return (
        <tr>
            <td>{name}</td>
            <td>{email}</td>
            <td className="text-break">{description.substring(0, 400)}</td>
            <td>{status ? status : 'pending'}</td>
        </tr>
    )
}
