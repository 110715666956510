import React, { FC, useState, useRef, useEffect, useContext } from 'react'
import jwtDecode from 'jwt-decode'
import { toast } from 'react-toastify'
import { Modal } from 'react-bootstrap'
import { ErrorMessage, Form, Formik } from 'formik'
import { Button, FormGroup, Input } from 'reactstrap'
import { api } from 'utils/axiosService'
import { EventModalProp } from 'utils/types'
import { ChangeImageValidationSchema } from 'utils/validation'
import { AuthContext } from 'context/AuthContext'

export const ChangeImage: FC<EventModalProp> = (props): JSX.Element => {
    const [file, setFile] = useState<File[]>([])
    const { onHide } = props
    const { setCurrentUser } = useContext(AuthContext)
    const inputRef = useRef(null)
    const input = inputRef.current as unknown as HTMLInputElement

    const [imageUrl, setImageUrl] = useState({
        url: '',
        name: '',
    })

    useEffect(() => {
        setFile([])
        setImageUrl({
            url: '',
            name: '',
        })
    }, [onHide])

    const handleChangeFile = (e: React.ChangeEvent<HTMLInputElement>) => {
        if (e.target.files !== null && e.target.files.length === 1) {
            const currentFile = e.target.files[0]
            const newImageUrls = URL.createObjectURL(currentFile)
            setImageUrl({ name: currentFile.name, url: newImageUrls })
            setFile(prevState => [...prevState, currentFile])
        }
    }

    const clearImage = () => {
        setFile([])
        setImageUrl({
            url: '',
            name: '',
        })

        input.value = ''
    }
    const handleChangeImage = async () => {
        const formData = new FormData()
        formData.append('file', file[file.length - 1])

        api.post(`user/edit-profile-image`, formData)
            .then(res => {
                if (res.data.token) {
                    localStorage.setItem('user', JSON.stringify(res.data.token))
                    const userData: {
                        id: string
                        age: string
                        role: number
                        name: string
                        email: string
                        image: string
                        password: string
                    } = jwtDecode(res.data.token)
                    setCurrentUser(prevState => ({
                        ...prevState,
                        ...userData,
                    }))
                }
                toast.success('Changes applied')
                onHide()
            })
            .catch(err => {
                toast.error(err.response.data.message)
            })
    }

    return (
        <Modal
            {...props}
            aria-labelledby="contained-modal-title-vcenter"
            centered
        >
            <Modal.Header closeButton>
                <Modal.Title>Change profile picture</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <Formik
                    initialValues={{ file }}
                    onSubmit={handleChangeImage}
                    validationSchema={ChangeImageValidationSchema}
                    enableReinitialize
                >
                    {formik => (
                        <Form>
                            <FormGroup>
                                <Input
                                    multiple
                                    type="file"
                                    name="file"
                                    className={`choose-file ${
                                        formik.errors.file &&
                                        formik.touched.file
                                            ? 'is-invalid'
                                            : ''
                                    }`}
                                    accept="image/*"
                                    innerRef={inputRef}
                                    onChange={handleChangeFile}
                                />
                                {imageUrl.name && (
                                    <img
                                        src={imageUrl.url}
                                        key={imageUrl.url}
                                        data-name={imageUrl.name}
                                        onClick={clearImage}
                                        className="attached-file"
                                    />
                                )}
                                <ErrorMessage
                                    name="file"
                                    component="div"
                                    className="invalid-feedback"
                                />
                            </FormGroup>
                            <Button
                                type="submit"
                                className="custom-btn auth-form-btn btn btn-lg"
                            >
                                Save
                            </Button>
                        </Form>
                    )}
                </Formik>
            </Modal.Body>
        </Modal>
    )
}
