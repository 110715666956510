import {
    ActiveAction,
    ProfileActionTypes,
    ProfileActiveSection,
} from 'utils/reduxTypes'

const initialProfileState: ProfileActiveSection = {
    activeSection: '',
}

export const ProfileActiveSectionReducer = (
    state = initialProfileState,
    action: ActiveAction
): ProfileActiveSection => {
    switch (action.type) {
        case ProfileActionTypes.EDIT_PROFILE:
            return {
                activeSection: action.payload,
            }
        case ProfileActionTypes.MY_EVENTS:
            return {
                activeSection: action.payload,
            }
        case ProfileActionTypes.LOGOUT:
            return {
                activeSection: action.payload,
            }
        case ProfileActionTypes.CLEAR_SECTION:
            return {
                activeSection: action.payload,
            }
        default:
            return state
    }
}
