import React, { FC, useContext, useEffect, useState } from 'react'
import { PathData } from 'utils/helpers'
import { EventContext } from 'context/EventContext'
import { LoadingContext } from 'context/LoadingContext'
import { EventList } from 'Components/EventList/eventList'
import { api } from 'utils/axiosService'
import { EventPolygonType } from 'utils/types'

export const FetchedDataCreatedEvents: FC = (): JSX.Element => {
    const [createdEventData, setCreatedEventData] = useState()
    const { stateIsChange } = useContext(EventContext)
    const { setIsLoading } = useContext(LoadingContext)
    const [newPolygons, setNewPolygons] = useState<EventPolygonType[]>([])
    const [eventPolygons, setEventPolygons] = useState<EventPolygonType[]>([])

    useEffect(() => {
        api.get(`map/get-approved-locations`)
            .then(result => {
                setEventPolygons(result.data)
            })
            .catch(e => {
                console.log(e.response.data.message)
            })
    }, [stateIsChange])

    useEffect(() => {
        api.get(`map/get-created-locations`)
            .then(result => {
                setNewPolygons(result.data)
            })
            .catch(e => {
                console.log(e.response.data.message)
            })
    }, [stateIsChange])

    useEffect(() => {
        setIsLoading(true)
        api.get(`admin/created-events`)
            .then(result => {
                setCreatedEventData(result.data)
                setIsLoading(false)
            })
            .catch(e => {
                const error = e.response.data.message.errors[0]
                console.log(error.msg)
                setIsLoading(false)
            })
    }, [stateIsChange])

    return (
        <EventList
            data={createdEventData}
            subject="Created Events"
            newpolygons={newPolygons}
            path={PathData.approveEvent}
            eventpolygons={eventPolygons}
        />
    )
}
