import { ActionTypes, ActiveAction, ActiveState } from 'utils/reduxTypes'

const initialState: ActiveState = {
    activeTabState: '',
}

export const ActiveTabReducer = (
    state = initialState,
    action: ActiveAction
): ActiveState => {
    switch (action.type) {
        case ActionTypes.CREATED_EVENTS:
            return {
                activeTabState: action.payload,
            }
        case ActionTypes.APPROVED_EVENTS:
            return {
                activeTabState: action.payload,
            }
        case ActionTypes.EVENTS_PENDING_TO_START:
            return {
                activeTabState: action.payload,
            }
        case ActionTypes.IN_PROGRESS_EVENTS:
            return {
                activeTabState: action.payload,
            }
        case ActionTypes.EVENTS_PENDING_TO_FINISH:
            return {
                activeTabState: action.payload,
            }
        case ActionTypes.FINISHED_EVENTS:
            return {
                activeTabState: action.payload,
            }
        case ActionTypes.CLEAR_TAB:
            return {
                activeTabState: action.payload,
            }
        default:
            return state
    }
}
