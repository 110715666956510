import React from 'react'
import { OverlayTrigger, Popover } from 'react-bootstrap'
import { TooltipType } from 'utils/types'
import './tooltip.scss'

export const DescriptionTooltip = (props: TooltipType): JSX.Element => {
    return (
        <OverlayTrigger
            data-name="trigger"
            trigger="click"
            overlay={
                <Popover id="popover-positioned-top" data-name="trigger">
                    <Popover.Header as="h3">{props.description}</Popover.Header>
                    <Popover.Body>
                        <img src="assets/logo.png" alt="be green logo" />
                    </Popover.Body>
                </Popover>
            }
        >
            <p className="text-break " onClick={props.makeBlur}>
                {props.description}
            </p>
        </OverlayTrigger>
    )
}
