import React, { FC } from 'react'
import { Link, useNavigate } from 'react-router-dom'
import { ArticleData } from 'Components/Articles/articleData'
import './adminArticlePage.scss'

const AdminArticlePage: FC = (): JSX.Element => {
    const navigate = useNavigate()
    return (
        <div className="admin-articles-container">
            <div className="admin-page-container">
                <div className="admin-logout-button-div">
                    <Link to="admin">
                        <button
                            className="admin-logout-button"
                            onClick={() => navigate(-1)}
                        >
                            Go back
                        </button>
                    </Link>
                </div>
                <h3 className="articles-header-subtitle"> Articles </h3>
                <ArticleData />
            </div>
        </div>
    )
}

export default AdminArticlePage
