import React, { FC } from 'react'
import './AboutCeo.scss'

const AboutCeo: FC = (): JSX.Element => {
    return (
        <div className="about-ceo d-flex align-items-center">
            <div className="about-ceo-photo col-lg-4">
                <img
                    src={
                        process.env.PUBLIC_URL +
                        '/assets/image/about/ceo/vazgen.png'
                    }
                    alt="ceo"
                />
            </div>
            <div className="about-ceo-info col-lg-7">
                <h3 className="about-ceo-title fw-bold">Message From CEO</h3>
                <p className="about-ceo-text">
                    Being surrounded by green, you have inner harmony and desire
                    to live. Aspiration to enjoy the life and recreate. Right
                    now all the prevailing environmental issues affect the
                    quality of our lives. Our mission is to encourage more
                    people to take part in nature-preserving activities. Thus,
                    also integrating team buildings during those activities. So,
                    let&apos;s connect and contribute
                </p>
                <h4 className="about-ceo-name text-uppercase">
                    Vazgen Tadevosyan
                </h4>
                <p className="fw-bold">Co-Founder & CEO </p>
                <p>
                    MS in Computer Science 10 years of extensive background in
                    Software Development
                </p>
            </div>
        </div>
    )
}

export default AboutCeo
