import React, { FC, useState } from 'react'
import CountUp from 'react-countup'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import VisibilitySensor from 'react-visibility-sensor'
import type { StatsItemProps } from 'utils/types'

const StatsItem: FC<StatsItemProps> = ({
    end,
    icon,
    title,
    start,
}): JSX.Element => {
    const [didViewCountUp, setDidViewCountUp] = useState(false)

    const onVisibilityChange = (isVisible: boolean): void => {
        if (isVisible) {
            setDidViewCountUp(true)
        }
    }

    return (
        <div className="stats-item">
            <div className="stats-icon">
                <FontAwesomeIcon icon={icon} size="3x" />
            </div>
            <div className="stats-value">
                <h4 className="stats-value-title">{title}</h4>
                <div className="stats-value-quantity">
                    <CountUp
                        start={start}
                        end={didViewCountUp ? end : 0}
                        redraw
                        delay={1}
                    >
                        {({ countUpRef }): JSX.Element => (
                            <VisibilitySensor
                                onChange={onVisibilityChange}
                                delayedCall
                            >
                                <span ref={countUpRef} />
                            </VisibilitySensor>
                        )}
                    </CountUp>
                </div>
            </div>
        </div>
    )
}

export default StatsItem
