import React, { FC, useEffect, useState } from 'react'
import { Card } from 'react-bootstrap'
import { useNavigate } from 'react-router-dom'
import { ArticleType } from 'utils/types'
import { api } from 'utils/axiosService'
import './articles.scss'

export const ArticleCard: FC<{ item: ArticleType }> = ({
    item,
}): JSX.Element => {
    const navigate = useNavigate()
    const [authorImage, setAuthorImage] = useState(
        process.env.PUBLIC_URL + '/assets/image/user-profile-page/unnamed.jpg'
    )

    useEffect(() => {
        if (item.authorId) {
            api.get(`user/${item.authorId}`).then(data => {
                if (data.data) {
                    setAuthorImage(data.data)
                }
            })
        }
    }, [])

    return (
        <Card onClick={() => navigate(`/articles/${item._id}`)}>
            <Card.Img variant="top" src={item.articleImage} alt="image" />
            <Card.Body>
                <div className="card-text">
                    <h5 className="text-break article-title">
                        {item.articleTitle}
                    </h5>
                </div>
                <div className="card-text">
                    <div className="article-card-info">
                        <img
                            src={authorImage}
                            className="author-image"
                            alt="article author"
                        />
                        <p className="info-date">{item.name}</p>
                    </div>
                    <div className="article-card-info">
                        <p className="info-date">
                            {item.created
                                .replace('-', '/')
                                .split('T')[0]
                                .replace('-', '/')}
                        </p>
                    </div>
                </div>
            </Card.Body>
        </Card>
    )
}
