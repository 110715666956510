import React, { FC } from 'react'
import { Routes, Route } from 'react-router-dom'
import { Roles } from './roles'
import PrivateRoute from './PrivateRoute'
import HomePage from 'Pages/Home/HomePage'
import AboutPage from 'Pages/About/AboutPage'
import MainPage from '../../Pages/MainPage/MainPage'
import ProductsPage from 'Pages/Products/ProductsPage'
import ContactsPage from 'Pages/Contacts/ContactsPage'
import ArticlePage from 'Pages/ArticlePage/articlePage'
import NotFoundPage from 'Pages/NotFoundPage/NotFoundPage'
import { AddArticle } from 'Components/AddArticle/addArticle'
import OurPartnersPage from 'Pages/OurPartnersPage/OurPartnersPage'
import UserProfileCard from 'Components/UserProfileCard/userProfileCard'
import { ForgotPassword } from 'Components/ForgotPassword/forgotPassword'
import { SingleArticleData } from 'Components/SingleArticle/singleArticleData'

const MainRoutes: FC = (): JSX.Element => {
    return (
        <Routes>
            <Route path="/" element={<MainPage />} />
            <Route path="home" element={<HomePage />} />
            <Route path="about" element={<AboutPage />} />
            <Route path="articles" element={<ArticlePage />} />
            <Route path="our-partners" element={<OurPartnersPage />} />
            <Route path="contacts" element={<ContactsPage />} />
            <Route path="events" element={<ProductsPage />} />
            <Route path="articles/add-article" element={<AddArticle />} />
            <Route path="articles/:id" element={<SingleArticleData />} />
            <Route path="forgot-password" element={<ForgotPassword />} />
            <Route
                path="profile"
                element={
                    <PrivateRoute roles={[Roles.user]}>
                        <UserProfileCard />
                    </PrivateRoute>
                }
            />
            <Route path="*" element={<NotFoundPage />} />
        </Routes>
    )
}

export default MainRoutes
