import React, { FC } from 'react'
import { Routes, Route } from 'react-router-dom'
import { Roles } from './roles'
import PrivateRoute from './PrivateRoute'
import AdminPage from 'Pages/AdminPage/AdminPage'
import NotFoundPage from 'Pages/NotFoundPage/NotFoundPage'
import FeedbackPage from 'Pages/FeedbackPage/feedbackPage'
import AdminArticlePage from 'Pages/AdminArticlePage/adminArticlePage'
import { SingleArticleData } from 'Components/SingleArticle/singleArticleData'

const AdminRoutes: FC = (): JSX.Element => {
    return (
        <Routes>
            <Route
                path="admin"
                element={
                    <PrivateRoute roles={[Roles.admin]}>
                        <AdminPage />
                    </PrivateRoute>
                }
            />
            <Route
                path="admin/feedback"
                element={
                    <PrivateRoute roles={[Roles.admin]}>
                        <FeedbackPage />
                    </PrivateRoute>
                }
            />
            <Route
                path="admin/articles"
                element={
                    <PrivateRoute roles={[Roles.admin]}>
                        <AdminArticlePage />
                    </PrivateRoute>
                }
            />
            <Route path="articles/:id" element={<SingleArticleData />} />
            <Route path="*" element={<NotFoundPage />} />
        </Routes>
    )
}

export default AdminRoutes
