import React, { FC } from 'react'
import {
    faGear,
    faAddressCard,
    faCheck,
} from '@fortawesome/free-solid-svg-icons'
import './Steps.scss'
import StepsItem from './StepsItem'

const steps = [
    {
        icon: faGear,
        title: 'Create',
        text: 'Become a member of BeGreen. Register on our website by providing your information. No worries, there are no complex stages. Enjoy, and start to explore our green environment.',
    },
    {
        icon: faCheck,
        title: 'Upload ',
        text: 'Upload your creative project. By sharing your ideas with our community, you will create the chance to engage many teams and individuals with your projects and vice-versa, making the whole experience more enjoyable.',
    },
    {
        icon: faAddressCard,
        title: 'Share',
        text: 'Share your or other members’ projects with your friends, family, and not only. By spreading awareness in your surrounding community,  you will be an integral part of our movement’s success, ensuring a more sustainable world.',
    },
]

const Steps: FC = (): JSX.Element => {
    return (
        <div className="steps">
            <div className="container">
                <div className="steps-content d-flex flex-column justify-content-center align-items-center">
                    <div className="steps-header text-center text-capitalize">
                        <h2 className="steps-header-title">how it works</h2>
                        <h3 className="steps-header-subtitle fw-bold">
                            3 easy steps
                        </h3>
                    </div>
                    <div className="steps-items">
                        <div className="steps-items-content d-flex justify-content-between">
                            {steps.map((step, index) => (
                                <StepsItem
                                    key={index}
                                    icon={step.icon}
                                    title={step.title}
                                    text={step.text}
                                />
                            ))}
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default Steps
