import React, { FC, useContext } from 'react'
import { Button, Modal } from 'react-bootstrap'
import { LogoutModalType } from 'utils/types'
import { AuthContext } from 'context/AuthContext'

const LogoutModal: FC<LogoutModalType> = ({
    show,
    onHide,
    toggle,
}): JSX.Element => {
    const { logout } = useContext(AuthContext)

    return (
        <Modal className="logout-modal" show={show} onHide={onHide}>
            <Modal.Body>
                <p className="text-center">
                    Do you really want to leave the page?
                </p>
                <div className="logout-modal-btns d-flex justify-content-center">
                    <Button
                        className="btn-sm admin-btn-reject"
                        onClick={toggle}
                    >
                        No
                    </Button>
                    <Button className=" btn-sm custom-btn" onClick={logout}>
                        Yes
                    </Button>
                </div>
            </Modal.Body>
        </Modal>
    )
}

export default LogoutModal
