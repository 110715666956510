import React, { ChangeEvent, FC, useState } from 'react'
import { Formik, Form, Field, ErrorMessage } from 'formik'
import { Button, FormGroup, Input, Label } from 'reactstrap'
import { toast } from 'react-toastify'
import { api } from 'utils/axiosService'
import { VerifyCodeValidationSchema } from 'utils/validation'
import { StepType } from 'utils/types'
import './forgotPassword.scss'

export const StepTwo: FC<StepType> = ({ setStep }): JSX.Element => {
    const [verifyCode, setVerifyCode] = useState('')
    const [isDisable, setIsDisable] = useState(false)
    const handleChange = (e: ChangeEvent<HTMLInputElement>) => {
        setVerifyCode(e.target.value)
    }
    const handleSendCode = () => {
        setIsDisable(true)
        api.post('user/check-verify-code', {
            code: verifyCode,
            email: localStorage.getItem('email'),
        })
            .then(response => {
                toast.success(response.data)
                setIsDisable(false)
                setStep(3)
            })
            .catch(e => {
                setIsDisable(false)
                toast.error(e.response.data)
            })
    }

    return (
        <Formik
            initialValues={{ verifyCode }}
            onSubmit={handleSendCode}
            validationSchema={VerifyCodeValidationSchema}
            enableReinitialize
        >
            {formik => (
                <Form>
                    <Label> Enter verify code</Label>
                    <FormGroup>
                        <Input
                            component="input"
                            tag={Field}
                            type="text"
                            maxLength={6}
                            minLength={6}
                            className={`form-control ${
                                formik.errors.verifyCode &&
                                formik.touched.verifyCode
                                    ? 'is-invalid'
                                    : ''
                            }`}
                            onChange={handleChange}
                            value={verifyCode}
                            name="verifyCode"
                        />
                        <ErrorMessage
                            name="verifyCode"
                            component="div"
                            className="invalid-feedback"
                        />
                    </FormGroup>
                    <Button
                        type="submit"
                        className="custom-btn auth-form-btn btn btn-lg align-self-stretch btn btn-secondary"
                        onClick={handleSendCode}
                        disabled={isDisable}
                    >
                        Send
                    </Button>
                </Form>
            )}
        </Formik>
    )
}
