import React, { FC, useState } from 'react'
import { Link, useNavigate } from 'react-router-dom'
import Feedbacks from 'Components/Feedbacks/Feedbacks'
import { FeedbackModal } from 'Components/FeedbackModalAdmin/feedbackModal'
import './feedbackPage.scss'

const FeedbackPage: FC = (): JSX.Element => {
    const navigate = useNavigate()
    const [show, setShow] = useState(false)

    return (
        <div className="feedback-container">
            <div className="admin-page-container">
                <div className="admin-logout-button-div">
                    <Link to="admin">
                        <button
                            className="admin-logout-button"
                            onClick={() => navigate(-1)}
                        >
                            Go back
                        </button>
                    </Link>
                    <button
                        className="admin-logout-button"
                        onClick={() => setShow(true)}
                    >
                        Add feedback
                    </button>
                </div>
            </div>
            <FeedbackModal show={show} onHide={() => setShow(!show)} />
            <Feedbacks />
        </div>
    )
}

export default FeedbackPage
