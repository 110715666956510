import React, { ChangeEvent, FC, useState } from 'react'
import { ErrorMessage, Field, Form, Formik } from 'formik'
import { Button, FormGroup, Input, Label } from 'reactstrap'
import { StepTwo } from './stepTwo'
import { toast } from 'react-toastify'
import { StepThree } from './stepThree'
import { api } from 'utils/axiosService'
import { EmailValidation } from 'utils/validation'
import './forgotPassword.scss'

export const ForgotPassword: FC = (): JSX.Element => {
    const [isDisable, setIsDisable] = useState(false)
    const [email, setEmail] = useState({ address: '' })
    const [step, setStep] = useState<number>(1)
    const handleChange = (e: ChangeEvent<HTMLInputElement>) => {
        setEmail({ address: e.target.value })
    }

    const handleSendButton = () => {
        setIsDisable(true)
        api.post('user/forgot-password', email)
            .then(response => {
                toast.success(response.data)
                localStorage.setItem('email', email.address)
                setIsDisable(false)
                setStep(2)
                setEmail({ address: '' })
            })
            .catch(e => {
                setIsDisable(false)
                toast.error(e.response.data)
            })
    }

    return (
        <div className="forgot-main">
            <div className="forgot-password-container">
                <div className="forgot-email-blank">
                    {step === 1 ? (
                        <Formik
                            initialValues={email}
                            onSubmit={handleSendButton}
                            validationSchema={EmailValidation}
                            enableReinitialize
                        >
                            {formik => (
                                <Form>
                                    <FormGroup>
                                        <Label> Enter your email</Label>
                                        <Input
                                            component="input"
                                            tag={Field}
                                            type="email"
                                            className={`form-control ${
                                                formik.errors.address &&
                                                formik.touched.address
                                                    ? 'is-invalid'
                                                    : ''
                                            }`}
                                            onChange={handleChange}
                                            value={email.address}
                                            name="address"
                                        />
                                        <ErrorMessage
                                            name="address"
                                            component="div"
                                            className="invalid-feedback"
                                        />
                                    </FormGroup>
                                    <Button
                                        type="submit"
                                        className="custom-btn auth-form-btn btn btn-lg align-self-stretch btn btn-secondary"
                                        onClick={handleSendButton}
                                        disabled={isDisable}
                                    >
                                        Send
                                    </Button>
                                </Form>
                            )}
                        </Formik>
                    ) : step === 2 ? (
                        <StepTwo setStep={setStep} />
                    ) : (
                        <StepThree />
                    )}
                </div>
            </div>
        </div>
    )
}
