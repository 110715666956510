import React, { useState, useEffect, createContext } from 'react'
import { ResizeContextProviderProps } from 'utils/types'

export const ResizeContext = createContext<any>({} as any)

export const ResizeContextProvider = ({
    children,
}: ResizeContextProviderProps): JSX.Element => {
    const [screenSize, getDimension] = useState(window.innerWidth)
    const setDimension = () => {
        getDimension(window.innerWidth)
    }

    useEffect(() => {
        window.addEventListener('resize', setDimension)

        return () => {
            window.removeEventListener('resize', setDimension)
        }
    }, [screenSize])

    return (
        <ResizeContext.Provider
            value={{
                screenSize,
            }}
        >
            <> {children}</>
        </ResizeContext.Provider>
    )
}
