import React, { useState, useContext, useEffect } from 'react'
import moment from 'moment'
import { toast } from 'react-toastify'
import { faMessage } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { Accordion, Badge, Button, Modal } from 'react-bootstrap'
import { ModalProp } from 'utils/types'
import { EventInfo } from './eventInfo'
import { api } from 'utils/axiosService'
import { countDecrement } from 'utils/helpers'
import { EventInfoDone } from './eventInfoDone'
import { FETCHED_EVENT } from 'utils/modalTypes'
import { AuthContext } from 'context/AuthContext'
import { EventContext } from 'context/EventContext'
import { SubscribersTable } from './subscribersTable'
import { CreatedBy } from 'Components/CreatedBy/CreatedBy'
import { LazyLoadImage } from 'react-lazy-load-image-component'
import { ImageSlider } from 'Components/ImageSlider/imageSlider'
import { DescriptionTooltip } from 'Components/DescriptionTooltip/tooltip'
import EventCardMap from '../../../EventMap/eventCardMap'

export const UserEventModal = (props: ModalProp): JSX.Element => {
    const { info, palette, event, onHide } = props
    const {
        _id,
        name,
        email,
        title,
        image,
        status,
        deadline,
        authorId,
        subscribers,
        description,
        authorImage,
        authorStatus,
        commentByAdmin,
    } = event

    const [url, setUrl] = useState('')

    const [blur, setBlur] = useState(false)
    const [currentImageSrc, setCurrentImageSrc] = useState(image[0])

    const { currentUser } = useContext(AuthContext)
    const { stateIsChange, setStateIsChange } = useContext(EventContext)

    const isUserAuthor = currentUser.id === authorId ? true : false

    useEffect(() => {
        setCurrentImageSrc(image[0])
        setBlur(false)
    }, [onHide])

    useEffect(() => {
        if (info === 'create') {
            setUrl(`map/created-event-location/${_id}`)
        } else {
            setUrl(`map/approved-location/${_id}`)
        }
    }, [])

    const changeCurrentImage = (e: React.MouseEvent) => {
        const clicedImage = e.target as HTMLInputElement
        setCurrentImageSrc(clicedImage.src)
    }

    const deleteEvent = async (e: React.MouseEvent): Promise<void> => {
        e.preventDefault()
        const eventId = _id
        const path =
            info === 'create'
                ? '/user/delete-created-event'
                : '/user/delete-approved-event'

        try {
            await api.post(`${path}`, { eventId })

            countDecrement('create')
            onHide()
            toast.success('Event deleted')
            setStateIsChange(!stateIsChange)
        } catch (err) {
            onHide()
            console.log(err)
        }
    }

    const setButtons = () => {
        switch (info) {
            case FETCHED_EVENT.APPROVE:
                return (
                    <div>
                        {subscribers?.length ? (
                            <EventInfo
                                info={info}
                                onHide={onHide}
                                event={event}
                            />
                        ) : (
                            <p>No subscribers yet</p>
                        )}
                        {info === 'approvedEvents' && isUserAuthor && (
                            <Button
                                className="admin-btn-reject delete-btn-size"
                                onClick={deleteEvent}
                            >
                                Delete Event
                            </Button>
                        )}
                    </div>
                )
            case FETCHED_EVENT.PENDING:
                return <EventInfoDone event={event} onHide={onHide} />

            default:
                return (
                    <div>
                        {subscribers?.length ? (
                            <SubscribersTable event={event} />
                        ) : (
                            <p className="text-center">No subscribers yet</p>
                        )}
                        {commentByAdmin && (
                            <Accordion>
                                <Accordion.Item eventKey="0">
                                    <Accordion.Header>
                                        <span>
                                            <FontAwesomeIcon icon={faMessage} />
                                        </span>
                                        Message from admin
                                    </Accordion.Header>
                                    <Accordion.Body
                                        className="text-break"
                                        id="text-justify"
                                    >
                                        {commentByAdmin}
                                    </Accordion.Body>
                                </Accordion.Item>
                            </Accordion>
                        )}
                        {info === 'create' && isUserAuthor && (
                            <Button
                                className="admin-btn-reject delete-btn-size"
                                onClick={deleteEvent}
                            >
                                Delete Event
                            </Button>
                        )}
                    </div>
                )
        }
    }

    return (
        <Modal
            {...props}
            size="lg"
            aria-labelledby="contained-modal-title-vcenter"
            centered
            className={'event-modal' + (blur ? ' modal-blur' : '')}
        >
            <Modal.Header
                closeButton
                closeVariant="white"
                className="event-modal-header"
            >
                <Modal.Title
                    id="contained-modal-title-vcenter"
                    className="event-modal-title text-break"
                >
                    {title}
                </Modal.Title>
            </Modal.Header>
            <Modal.Body className="text-center event-modal-body">
                <div className="event-modal-info">
                    <CreatedBy
                        name={name}
                        email={email}
                        authorImage={authorImage}
                        authorStatus={authorStatus}
                    />
                    <div className="event-modal-desc">
                        <h4>Description</h4>
                        <DescriptionTooltip
                            blurState={blur}
                            description={description}
                            makeBlur={() => setBlur(!blur)}
                        />
                    </div>
                    {!!image.length && (
                        <ImageSlider
                            imgArray={image}
                            changeCurrentImage={changeCurrentImage}
                            currentImageSrc={currentImageSrc}
                        ></ImageSlider>
                    )}

                    <EventCardMap url={url} />

                    <div className="event-modal-subscr">
                        <h4>Subscribers</h4>
                        {setButtons()}
                    </div>
                </div>
            </Modal.Body>
            <Modal.Footer className="d-flex justify-content-between event-modal-footer">
                {' '}
                <div className="event-modal-footer-content">
                    <div className="d-flex align-items-center event-modal-footer-info">
                        <strong>Deadline:</strong>
                        <span className="event-modal-deadline">
                            {moment(deadline).format('ll')}
                        </span>
                    </div>
                    {status && (
                        <div className="d-flex align-items-center event-modal-footer-info">
                            {' '}
                            <strong>Status:</strong>
                            <Badge
                                className="text-capitalize"
                                bg={`${palette}`}
                            >
                                {status}
                            </Badge>
                        </div>
                    )}
                </div>
                <LazyLoadImage src="assets/logo.png" alt="be green logo" />
            </Modal.Footer>
        </Modal>
    )
}
