import React, { FC, useContext, useEffect, useState } from 'react'
import { Table } from 'reactstrap'
import { toast } from 'react-toastify'
import { api } from 'utils/axiosService'
import { AuthContext } from 'context/AuthContext'
import { EventContext } from 'context/EventContext'
import { Checkbox, EventCardType } from 'utils/types'
import { SubscribersTable } from './subscribersTable'
import './forAllEvents.scss'

export const EventInfo: FC<EventCardType> = (props): JSX.Element => {
    const { subscribers, _id, authorId } = props.event
    const [checkboxValue, setCheckboxValue] = useState<Checkbox>({})
    const { currentUser } = useContext(AuthContext)
    const { stateIsChange, setStateIsChange } = useContext(EventContext)

    useEffect(() => {
        subscribers?.map(subscriber =>
            setCheckboxValue(prevState => {
                return { ...prevState, [subscriber.name]: false }
            })
        )
    }, [])

    const handleCheck = (e: React.ChangeEvent<HTMLInputElement>): void => {
        setCheckboxValue(prevState => {
            return { ...prevState, [e.target.name]: !prevState[e.target.name] }
        })
    }

    const handleSubmit = async (e: {
        preventDefault: () => void
    }): Promise<void> => {
        e.preventDefault()

        const checkboxArrays = Object.entries(checkboxValue)
        const selectedSubscribers = checkboxArrays
            .filter(item => item[1])
            .map(item => item[0])
        if (!selectedSubscribers.length) {
            toast.error('Choose at least one subscriber')
            return
        }
        const submitSubscriberData = subscribers?.filter(subscriber =>
            selectedSubscribers.includes(subscriber.name)
        )
        const acceptedSubscribers = {
            eventId: _id,
            userList: submitSubscriberData,
        }

        try {
            await api.post(`user/submit-event`, acceptedSubscribers)

            if (props.onHide) {
                props.onHide()
            }

            toast.success('Accepted')
            setStateIsChange(!stateIsChange)
        } catch (err) {
            console.log(err)
        }
    }

    return (
        <div>
            {currentUser.id === authorId ? (
                <>
                    <Table className="subscribe-table" hover bordered>
                        <thead>
                            <tr>
                                <th>Name</th>
                                <th>Email</th>
                                <th>Description</th>
                                <th>Status</th>
                                <th>Select</th>
                            </tr>
                        </thead>

                        <tbody>
                            {subscribers?.map(item => (
                                <tr key={item.id}>
                                    <td>{item.name}</td>
                                    <td>{item.email}</td>
                                    <td className="text-break">
                                        {item.description}
                                    </td>
                                    <td>{item.status}</td>
                                    <td className="text-break">
                                        <input
                                            type="checkbox"
                                            className="custom-checkbox"
                                            onChange={handleCheck}
                                            name={item.name}
                                            value={item.name}
                                        />
                                    </td>
                                </tr>
                            ))}
                        </tbody>
                    </Table>
                    <div>
                        {!!subscribers?.length && (
                            <button
                                type="submit"
                                className="btn btn-primary w-100 custom-btn"
                                onClick={handleSubmit}
                            >
                                Accept
                            </button>
                        )}
                    </div>
                </>
            ) : (
                <SubscribersTable event={props.event} />
            )}
        </div>
    )
}
