import React, { FC, useEffect, useState } from 'react'
import { useDispatch } from 'react-redux'
import { Tab, Tabs } from 'react-bootstrap'
import { useTypedSelector } from 'utils/reduxTypes'
import { FetchedDataNew } from 'Components/EventData/fetchedDataNew'
import { FetchedDataFinished } from 'Components/EventData/fetchedDataFinished'
import { FetchedDataProgress } from 'Components/EventData/fetchedDataProgress'
import './eventTab.scss'

export const EventTab: FC = (): JSX.Element => {
    const activePublicState = useTypedSelector(state => state.activePublicTab)
    const [activeTab, setActiveTab] = useState<JSX.Element>()
    const [key, setKey] = useState('')
    const dispatch = useDispatch()

    useEffect(() => {
        if (activePublicState.activePublic === 'IN PROGRESS EVENTS') {
            setActiveTab(<FetchedDataProgress />)
            setKey(activePublicState.activePublic)
            return
        } else if (activePublicState.activePublic === 'FINISHED EVENTS') {
            setActiveTab(<FetchedDataFinished />)
            setKey(activePublicState.activePublic)
            return
        }
        setActiveTab(<FetchedDataNew />)
        setKey('EVENTS')
        return
    }, [])

    const eventTabs = [
        {
            eventKey: 'EVENTS',
            title: 'EVENTS',
        },
        {
            eventKey: 'IN PROGRESS EVENTS',
            title: 'IN PROGRESS EVENTS',
        },
        {
            eventKey: 'FINISHED EVENTS',
            title: 'FINISHED EVENTS',
        },
    ]

    const checkActiveTab = (e: string | null): void => {
        switch (e) {
            case 'IN PROGRESS EVENTS':
                setActiveTab(<FetchedDataProgress />)
                break
            case 'FINISHED EVENTS':
                setActiveTab(<FetchedDataFinished />)
                break
            default:
                setActiveTab(<FetchedDataNew />)
                break
        }
        dispatch({
            type: e,
            payload: e,
        })
        if (e !== null) {
            setKey(e)
        }
    }

    return (
        <div className="tab-blocks">
            <Tabs
                id="controlled-tab-example"
                activeKey={key}
                onSelect={(e): void => checkActiveTab(e)}
                className="mb-3"
            >
                {eventTabs.map((eventTab, index) => (
                    <Tab
                        key={index}
                        title={eventTab.title}
                        eventKey={eventTab.eventKey}
                    />
                ))}
            </Tabs>
            {activeTab}
        </div>
    )
}
