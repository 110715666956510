import React, { FC, useContext, useEffect, useState } from 'react'
import moment from 'moment'
import { Badge, Modal, Table } from 'react-bootstrap'
import { LazyLoadImage } from 'react-lazy-load-image-component'
import { toast } from 'react-toastify'
import { JoinFrom } from './joinForm'
import { ModalProp } from 'utils/types'
import { api } from 'utils/axiosService'
import { AuthContext } from 'context/AuthContext'
import { EventContext } from 'context/EventContext'
import { CreatedBy } from 'Components/CreatedBy/CreatedBy'
import { ImageSlider } from 'Components/ImageSlider/imageSlider'
import { Subscriebers } from 'Components/Subscriebers/subscriebers'
import { DescriptionTooltip } from 'Components/DescriptionTooltip/tooltip'
import { countDecrement } from 'utils/helpers'
import './productModal.scss'
import EventCardMap from '../EventMap/eventCardMap'

const ProductModal: FC<ModalProp> = (props): JSX.Element => {
    const { event, toggle, connect, palette, onHide, hideMap } = props
    const {
        _id,
        name,
        email,
        title,
        image,
        status,
        deadline,
        authorId,
        subscribers,
        description,
        authorImage,
        authorStatus,
    } = event

    const [url, setUrl] = useState('')

    const [join, setJoin] = useState(false)
    const [blur, setBlur] = useState(false)
    const [isDisable, setDisable] = useState(false)
    const [currentImageSrc, setCurrentImageSrc] = useState(image[0])
    const { isAuth, setModal, currentUser } = useContext(AuthContext)
    const { stateIsChange, setStateIsChange } = useContext(EventContext)

    const joinsCount = Number(localStorage.getItem('joinsCount'))
    const point = Number(currentUser.points)

    const userJoin = [
        Boolean(point < 10 && joinsCount > 4),
        Boolean(point < 20 && joinsCount > 6),
        Boolean(point < 30 && joinsCount > 9),
        Boolean(point > 29 && joinsCount > 14),
    ]

    useEffect(() => {
        if (userJoin.some(item => item)) {
            setDisable(true)
        }
    }, [joinsCount])

    useEffect(() => {
        setBlur(false)
        setCurrentImageSrc(image[0])
    }, [onHide])

    useEffect(() => {
        setJoin(!toggle)
    }, [toggle])

    useEffect(() => {
        setUrl(`map/approved-location/${_id}`)
    }, [])

    const joinPossibiltyChecker = () => {
        const isCurrentUserAuthor = currentUser.id === authorId
        return !isCurrentUserAuthor
    }

    const isSubscried = () => {
        const checkResult = subscribers?.map(
            subscriber => subscriber.id === currentUser.id
        )

        if (checkResult?.includes(true)) {
            return true
        }
        return false
    }

    const changeCurrentImage = (e: React.MouseEvent) => {
        const clicedImage = e.target as HTMLInputElement
        setCurrentImageSrc(clicedImage.src)
    }

    const ModalToggler = () => {
        if (!isAuth) {
            onHide()
            setTimeout(() => {
                setModal(true)
            }, 200)
        } else {
            setJoin(prevState => !prevState)
        }
    }

    const showToastify = () => {
        toast.error(
            'You have subscribed to too many open events, please finish them first'
        )
    }

    const handleUnsubscribeBtn = async (e: React.MouseEvent): Promise<void> => {
        e.preventDefault()
        const eventId = _id
        try {
            await api.post(`user/unsubscribe-event`, { eventId })
            onHide()
            countDecrement('join')
            toast.success('You are successfully unsubscribed')
            setStateIsChange(!stateIsChange)
        } catch (err) {
            onHide()
            console.log(err)
        }
    }

    return (
        <Modal
            {...props}
            aria-labelledby="contained-modal-title-vcenter"
            centered
            className={'event-modal' + (blur ? ' modal-blur' : '')}
        >
            <Modal.Header
                closeButton
                closeVariant="white"
                className="event-modal-header"
            >
                <Modal.Title
                    id="contained-modal-title-vcenter"
                    className="text-center event-modal-title text-break"
                >
                    {title}
                </Modal.Title>
            </Modal.Header>
            <Modal.Body className="text-center event-modal-body">
                <div className="event-modal-info">
                    <CreatedBy
                        name={name}
                        email={email}
                        authorImage={authorImage}
                        authorStatus={authorStatus}
                    />
                    <div className="event-modal-desc">
                        <h4 className="mb-4">Description</h4>
                        <DescriptionTooltip
                            blurState={blur}
                            description={description}
                            makeBlur={() => setBlur(!blur)}
                        />
                    </div>
                    {!!image.length && (
                        <ImageSlider
                            imgArray={image}
                            changeCurrentImage={changeCurrentImage}
                            currentImageSrc={currentImageSrc}
                        ></ImageSlider>
                    )}

                    {!hideMap && <EventCardMap url={url} />}

                    <div className="event-modal-subscr">
                        <h4 className="mb-4">Subscribers</h4>

                        {subscribers?.length ? (
                            <Table className="subscribe-table" hover bordered>
                                <thead className="no-border">
                                    <tr>
                                        <th>Name</th>
                                        <th>Email</th>
                                        <th>Description</th>
                                        <th>Status</th>
                                    </tr>
                                </thead>
                                <tbody className="no-border">
                                    {subscribers?.map((subscriber, index) => (
                                        <Subscriebers
                                            name={subscriber.name}
                                            email={subscriber.email}
                                            description={subscriber.description}
                                            status={
                                                subscriber.status
                                                    ? subscriber.status
                                                    : 'pending'
                                            }
                                            key={index}
                                        />
                                    ))}
                                </tbody>
                            </Table>
                        ) : (
                            <p className="text-center">no subscribers yet</p>
                        )}
                    </div>
                </div>

                {!!connect &&
                    joinPossibiltyChecker() &&
                    (isSubscried() ? (
                        <button
                            className="btn btn-custom  admin-btn-reject"
                            onClick={handleUnsubscribeBtn}
                        >
                            Unsbscribe
                        </button>
                    ) : (
                        <button
                            onClick={() =>
                                isDisable ? showToastify() : ModalToggler()
                            }
                            className="btn btn-primary join custom-btn"
                        >
                            Join the event
                        </button>
                    ))}

                {join && <JoinFrom id={_id} onHide={onHide} />}
            </Modal.Body>
            <Modal.Footer className="d-flex justify-content-between">
                {' '}
                <div className="event-modal-footer-content">
                    <div className="d-flex align-items-center event-modal-footer-info">
                        <strong>Deadline:</strong>
                        <span className="event-modal-deadline">
                            {moment(deadline).format('ll')}
                        </span>
                    </div>
                    {status && (
                        <div className="d-flex align-items-center event-modal-footer-info">
                            {' '}
                            <strong>Status:</strong>
                            <Badge
                                className="text-capitalize"
                                bg={`${palette}`}
                            >
                                {status}
                            </Badge>
                        </div>
                    )}
                </div>
                <LazyLoadImage src="assets/logo.png" alt="be green logo" />
            </Modal.Footer>
        </Modal>
    )
}

export default ProductModal
