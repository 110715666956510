import React, { FC } from 'react'
import { ArticleDataType } from 'utils/types'
import { ArticleCard } from './articleCard'
import './articles.scss'

export const Articles: FC<ArticleDataType> = (props): JSX.Element => {
    return (
        <div className="article-container">
            {props.articleData?.map(item => {
                return <ArticleCard key={item._id} item={item} />
            })}
        </div>
    )
}
