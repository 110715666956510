import React, { useEffect, FC } from 'react'
import { useLocation } from 'react-router'
import type { ScrollToTopProps } from './utils/types'

const ScrollToTop: FC<ScrollToTopProps> = ({ children }): JSX.Element => {
    const location = useLocation()
    useEffect(() => {
        window.scrollTo(0, 0)
    }, [location])

    return <div>{children}</div>
}

export default ScrollToTop
