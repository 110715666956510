import React, { FC } from 'react'
import Layout from 'Layout/Layout'
import ScrollToTop from 'ScrollToTop'
import { Provider } from 'react-redux'
import { ToastContainer } from 'react-toastify'
import { PersistGate } from 'redux-persist/integration/react'
import { persistor, store } from './context/ReduxStore/Store'
import { AuthContextProvider } from 'context/AuthContext'
import { EventContextProvider } from 'context/EventContext'
import { LoadingContextProvider } from 'context/LoadingContext'
import 'react-toastify/dist/ReactToastify.css'
import { ResizeContextProvider } from 'context/ResizeContext'

const App: FC = (): JSX.Element => {
    return (
        <Provider store={store}>
            <PersistGate loading={null} persistor={persistor}>
                <AuthContextProvider>
                    <EventContextProvider>
                        <LoadingContextProvider>
                            <ResizeContextProvider>
                                <div className="App">
                                    <ToastContainer autoClose={2000} />
                                    <ScrollToTop>
                                        <Layout />
                                    </ScrollToTop>
                                </div>
                            </ResizeContextProvider>
                        </LoadingContextProvider>
                    </EventContextProvider>
                </AuthContextProvider>
            </PersistGate>
        </Provider>
    )
}

export default App
