import React, { FC, useContext, useEffect, useState } from 'react'
import { UserEvent } from './userEvent'
import { api } from 'utils/axiosService'
import { LoadingContext } from 'context/LoadingContext'
import './forAllEvents.scss'

export const PendingToFinishEvents: FC = (): JSX.Element => {
    const [eventData, setEventData] = useState([])
    const { setIsLoading } = useContext(LoadingContext)

    useEffect(() => {
        setIsLoading(true)

        api.get(`user/users-pending-to-finish-events`)
            .then(result => {
                setEventData(result.data)
                setIsLoading(false)
            })
            .catch(e => {
                const error = e.response.data.message.errors[0]
                console.log(error.msg)
                setIsLoading(false)
            })
    }, [])

    return <UserEvent data={eventData} />
}
