import React, { FC } from 'react'
import Slider from 'react-slick'
import FeedbacksItem from './FeedbacksItem'
import type { FeedbackData } from 'utils/types'

const settings = {
    dots: false,
    infinite: true,
    speed: 1000,
    slidesToScroll: 1,
    autoplay: true,
}

export const FeedbackSlider: FC<FeedbackData> = (props): JSX.Element => {
    return (
        <div className="feedbacks-content">
            <Slider {...settings}>
                {props.data?.map((item, ind) => (
                    <FeedbacksItem key={ind} commentary={item} />
                ))}
            </Slider>
        </div>
    )
}
