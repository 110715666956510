import React, { FC, useEffect, useState } from 'react'
import { useDispatch } from 'react-redux'
import { Tab, Tabs } from 'react-bootstrap'
import { FetchedDataNew } from 'Components/EventData/fetchedDataNew'
import { FetchedDataProgress } from 'Components/EventData/fetchedDataProgress'
import { FetchedDataFinished } from 'Components/EventData/fetchedDataFinished'
import { FetchedDataCreatedEvents } from 'Components/EventData/fetchedDataCreatedEvents'
import { FetchedDataPendingToStart } from 'Components/EventData/fetchedDataPendingToStart'
import { FetchedDataPendingToFinish } from 'Components/EventData/fetchedDataPendingToFinish'
import { useTypedSelector } from 'utils/reduxTypes'

export const AdminTab: FC = (): JSX.Element => {
    const [activeTab, setActiveTab] = useState(<FetchedDataCreatedEvents />)
    const activeState = useTypedSelector(state => state.activeTab)
    const dispatch = useDispatch()
    const [key, setKey] = useState('')

    useEffect(() => {
        switch (activeState.activeTabState) {
            case 'FINISHED EVENTS':
                setActiveTab(<FetchedDataFinished />)
                break
            case 'APPROVED EVENTS':
                setActiveTab(<FetchedDataNew />)
                break
            case 'EVENTS PENDING TO START':
                setActiveTab(<FetchedDataPendingToStart />)
                break
            case 'IN PROGRESS EVENTS':
                setActiveTab(<FetchedDataProgress />)
                break
            case 'EVENTS PENDING TO FINISH':
                setActiveTab(<FetchedDataPendingToFinish />)
                break
            default:
                setActiveTab(<FetchedDataCreatedEvents />)
                break
        }
        setKey(
            activeState.activeTabState
                ? activeState.activeTabState
                : 'CREATED EVENTS'
        )
    }, [])

    const adminEventTabs = [
        {
            eventKey: 'CREATED EVENTS',
        },
        {
            eventKey: 'APPROVED EVENTS',
        },
        {
            eventKey: 'EVENTS PENDING TO START',
        },
        {
            eventKey: 'IN PROGRESS EVENTS',
        },
        {
            eventKey: 'EVENTS PENDING TO FINISH',
        },
        {
            eventKey: 'FINISHED EVENTS',
        },
    ]

    const checkActiveTab = (e: string | null): void => {
        switch (e) {
            case 'APPROVED EVENTS':
                setActiveTab(<FetchedDataNew />)
                break
            case 'EVENTS PENDING TO START':
                setActiveTab(<FetchedDataPendingToStart />)
                break
            case 'IN PROGRESS EVENTS':
                setActiveTab(<FetchedDataProgress />)
                break
            case 'EVENTS PENDING TO FINISH':
                setActiveTab(<FetchedDataPendingToFinish />)
                break
            case 'FINISHED EVENTS':
                setActiveTab(<FetchedDataFinished />)
                break
            default:
                setActiveTab(<FetchedDataCreatedEvents />)
                break
        }
        dispatch({
            type: `user/${e}`,
            payload: e,
        })
        if (e !== null) {
            setKey(e)
        }
    }

    return (
        <div className="tab-blocks">
            <Tabs
                id="controlled-tab-example"
                activeKey={key}
                onSelect={(e: string | null): void => checkActiveTab(e)}
                className="mb-3"
            >
                {adminEventTabs.map((eventTab, index) => (
                    <Tab
                        key={index}
                        title={eventTab.eventKey}
                        eventKey={eventTab.eventKey}
                    />
                ))}
            </Tabs>
            {activeTab}
        </div>
    )
}
