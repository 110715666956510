import React, { FC, useEffect, useState } from 'react'
import { toast } from 'react-toastify'
import { Modal } from 'react-bootstrap'
import { ErrorMessage, Formik, Form, Field } from 'formik'
import { Input, Label, FormGroup, Button } from 'reactstrap'
import { api } from 'utils/axiosService'
import { EventModalProp } from 'utils/types'
import { ChangePasswordValidationSchema } from 'utils/validation'
import './userProfileCardEdit.scss'

export const ChangePassword: FC<EventModalProp> = (props): JSX.Element => {
    const [isDisable, setIsDisable] = useState(false)
    const [pass, setPas] = useState({
        currentPassword: '',
        newPassword: '',
        confirmPassword: '',
    })
    const { onHide } = props

    useEffect(() => {
        setPas({
            currentPassword: '',
            newPassword: '',
            confirmPassword: '',
        })
        setIsDisable(false)
    }, [onHide])

    const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        setPas({ ...pass, [e.target.name]: e.target.value })
    }

    const handlePassword = () => {
        setIsDisable(true)
        if (pass.confirmPassword === pass.newPassword) {
            api.post('user/edit-user-profile', {
                password: pass.currentPassword,
                newPassword: pass.newPassword,
            })
                .then(res => {
                    toast.success(res.data)
                    setIsDisable(false)
                    onHide()
                })
                .catch(error => {
                    setIsDisable(false)
                    toast.error(error.response.data.message)
                })
        } else {
            toast.error('Passwords do not match')
            setIsDisable(false)
        }
    }

    return (
        <Modal
            {...props}
            aria-labelledby="contained-modal-title-vcenter"
            centered
        >
            <Modal.Header closeButton>
                <Modal.Title>Change password</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <Formik
                    initialValues={pass}
                    validationSchema={ChangePasswordValidationSchema}
                    enableReinitialize
                    onSubmit={handlePassword}
                >
                    {formik => (
                        <Form>
                            <FormGroup>
                                <Label>Current password</Label>
                                <Input
                                    tag={Field}
                                    className={`form-control ${
                                        formik.errors.currentPassword &&
                                        formik.touched.currentPassword
                                            ? 'is-invalid'
                                            : ''
                                    }`}
                                    component="input"
                                    type="password"
                                    name="currentPassword"
                                    onChange={handleChange}
                                />
                                <ErrorMessage
                                    name="currentPassword"
                                    component="div"
                                    className="invalid-feedback"
                                />
                            </FormGroup>
                            <FormGroup>
                                <Label>New password</Label>
                                <Input
                                    tag={Field}
                                    className={`form-control ${
                                        formik.errors.newPassword &&
                                        formik.touched.newPassword
                                            ? 'is-invalid'
                                            : ''
                                    }`}
                                    component="input"
                                    type="password"
                                    name="newPassword"
                                    onChange={handleChange}
                                />
                                <ErrorMessage
                                    name="newPassword"
                                    component="div"
                                    className="invalid-feedback"
                                />
                            </FormGroup>
                            <FormGroup>
                                <Label>Confirm password</Label>
                                <Input
                                    tag={Field}
                                    className={`form-control ${
                                        formik.errors.confirmPassword &&
                                        formik.touched.confirmPassword
                                            ? 'is-invalid'
                                            : ''
                                    }`}
                                    component="input"
                                    type="password"
                                    name="confirmPassword"
                                    onChange={handleChange}
                                />
                                <ErrorMessage
                                    name="confirmPassword"
                                    component="div"
                                    className="invalid-feedback"
                                />
                            </FormGroup>

                            <Button
                                disabled={isDisable}
                                type="submit"
                                className="custom-btn auth-form-btn btn btn-lg"
                            >
                                Save changes
                            </Button>
                        </Form>
                    )}
                </Formik>
            </Modal.Body>
        </Modal>
    )
}
